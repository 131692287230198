import React, { Component } from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import Settings from "../../lprfolders/GlobalConstant/Settings";
import LoaderComponent from "../../lprfolders/GlobalConstant/LoaderComponent";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from "@material-ui/icons/Info";
import axios from "axios";
import Table from "Grids/Reports";
import { HOST } from "../../lprfolders/config";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import 'date-fns';
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
class Searchplates extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.state = {
      open: false,
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      vehicle: "",
      vehicles: [],
      flagError: false,
      netWorkError: false,
      inputname: "",
      erroertext: "",
      showtable: false,
      mobile: "",
      emptystate: false,
    };
  }

  handleFromDateChange = (date) => {
    let startDate = date;
    let todate = this.state.selectedToDate;
    if (startDate.getTime() <= todate.getTime()) {
      this.setState({
        erroertext: "",
        flagError: false,
        selectedFromDate: date,
      });
    } else {
      this.setState({
        erroertext: "To date should be after the start date ",
        flagError: true,
        inputname: "Date",
        selectedFromDate: date,
      });
      setTimeout(() => {
        this.setState({ flagError: false });
      }, 10000);
    }
  };
  handleToDateChange = (date) => {
    let startDate = this.state.selectedFromDate;
    let todate = date;
    if (startDate.getTime() <= todate.getTime()) {
      this.setState({ erroertext: "", flagError: false, selectedToDate: date });
    } else {
      this.setState({
        erroertext: "To date should be after the start date ",
        flagError: true,
        inputname: "Date",
        selectedToDate: date,
      });
      setTimeout(() => {
        this.setState({ flagError: false });
      }, 10000);
    }
  };
  trackVehicle = () => {
    this.setState({ showtable: false });
    let api = HOST + "/report/lpr";
    let body = {
      vehicle: this.state.vehicle,
      mobile: this.state.mobile,
      datefrom: moment(this.state.selectedFromDate).format("YYYY-MM-DD"),
      dateto: moment(this.state.selectedToDate).format("YYYY-MM-DD"),
    };
    let startDate = this.state.selectedFromDate;
    let todate = this.state.selectedToDate;
    let count = 0;
    if (this.state.vehicle === "") {
      count = 0;
    }
    if (this.state.vehicle) {
      if (Settings.TrackVehicle(this.state.vehicle).status) {
        count = 0;
      } else {
        count++;
        this.setState({
          flagError: true,
          erroertext: Settings.TrackVehicle(this.state.vehicle).errmessgae,
          inputname: Settings.TrackVehicle(this.state.vehicle).inputname,
        });
        setTimeout(() => {
          this.setState({ flagError: false });
        }, 3000);
      }
    }
    if (startDate.getTime() <= todate.getTime()) {
    } else {
      count++;
      this.setState({
        erroertext: "To date should be after the start date ",
        flagError: true,
        inputname: "Date",
      });
      setTimeout(() => {
        this.setState({ flagError: false });
      }, 3000);
    }
    if (count === 0) {
      this.setState({ loader: true });
      axios
        .post(api, body, {
          headers: {
            authToken: this.token,
            Accept: "application/json",
            // 'Content-Type': 'application/json'
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            this.setState({ emptystate: true, loader: false });
            this.setState({ showtable: false });
            setTimeout(() => {
              this.setState({ emptystate: false });
            }, 3000);
          } else {
            this.setState({
              vehicles: res.data,
              loader: false,
              emptystate: false,
            });
            this.setState({ showtable: true });
          }
        })
        .catch((err) => {
          this.setState({ showtable: true });
          if (err.message === "Network Error") {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.netWorkError().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
          if (err.response) {
            if (err.response.data.statusid === 409) {
              this.setState({
                flagError: true,
                loader: false,
                erroertext: Settings.TrackVehicle(409).errmessgae,
                inputname: Settings.TrackVehicle(409).inputname,
              });
              setTimeout(() => {
                this.setState({ flagError: false });
              }, 3000);
            }
            if (err.response.status === 502) {
              this.setState({
                netWorkError: true,
                loader: false,
                erroertext: Settings.Server().errmessgae,
              });
              setTimeout(() => {
                this.setState({ netWorkError: false });
              }, 3000);
            }
          }
        });
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    {Settings.titleCase("Vehicle Number")}
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    {Settings.capitalizeFLetter("Enter search critria below")}
                  </p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="* Vehicle Number(max. 10 characters)"
                        id="Vehicle Number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ vehicle: event.target.value });
                            if (event.target.value.length >= 1) {
                              if (
                                Settings.TrackVehicle(event.target.value)
                                  .status ||
                                !event.target.value
                              ) {
                                this.setState({
                                  flagError: false,
                                  erroertext: "",
                                });
                              } else {
                                this.setState({
                                  flagError: true,
                                  erroertext: Settings.TrackVehicle(
                                    event.target.value
                                  ).errmessgae,
                                  inputname: Settings.TrackVehicle(
                                    event.target.value
                                  ).inputname,
                                });
                              }
                            } else {
                              this.setState({
                                flagError: false,
                                erroertext: "",
                              });
                            }
                          },
                          onInput: (e) =>
                            (e.target.value = ("" + e.target.value)
                              .toUpperCase()
                              .toString()
                              .slice(0, 10)),
                          type: "text",
                          // error: this.state.flagError && this.state.inputname === "Vehicle" ? true : false
                          error:
                            (this.state.flagError &&
                              this.state.inputname === "Vehicle") ||
                            (this.state.requirederror &&
                              this.state.vehicle === "")
                              ? true
                              : false,
                        }}
                      />
                      {this.state.requirederror && !this.state.vehicle ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                      {this.state.flagError &&
                      this.state.inputname === "Vehicle" ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    {Settings.titleCase("Select period")}
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    {Settings.capitalizeFLetter(
                      "Results between the selected dates will be displayed"
                    )}
                  </p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            margin="normal"
                            color="primary"
                            id="date-picker-dialog"
                            label="Select from Date"
                            format="dd-MM-yyyy"
                            value={this.state.selectedFromDate}
                            onChange={this.handleFromDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            maxDate={new Date()}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            margin="normal"
                            color="primary"
                            id="date-picker-dialog"
                            label="Select to Date"
                            format="dd-MM-yyyy"
                            value={this.state.selectedToDate}
                            maxDate={new Date()}
                            onChange={this.handleToDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </GridItem>
                  </GridContainer>
                  {this.state.flagError && this.state.inputname === "Date" ? (
                    <SnackbarContent
                      icon={InfoIcon}
                      message={this.state.erroertext}
                      // close
                      color="danger"
                    />
                  ) : (
                    ""
                  )}
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={2}>
              {this.state.vehicle !== "" ? (
                <Button
                  color="primary"
                  style={{ merginTop: 30, marginBottom: 30 }}
                  onClick={this.trackVehicle}
                >
                  {" "}
                  <FindReplaceIcon />
                  Track
                </Button>
              ) : (
                <Button
                  style={{ marginBottom: 30 }}
                  onClick={() => {
                    if (
                      Settings.requireerrors([this.state.vehicle]).status ===
                      true
                    ) {
                      this.setState({
                        requirederror: true,
                        erroertext: Settings.requireerrors([this.state.vehicle])
                          .errmessgae,
                      });
                      setTimeout(() => {
                        this.setState({ requirederror: false });
                      }, 3000);
                    }
                  }}
                >
                  {" "}
                  <FindReplaceIcon />
                  Track
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </div>
        {this.state.emptystate ? (
          <SnackbarContent
            icon={InfoIcon}
            message={"No records found"}
            // close
            color="danger"
          />
        ) : (
          ""
        )}
        {this.state.loader ? <LoaderComponent /> : ""}

        {this.state.showtable ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    {Settings.titleCase("Vehicle Activity")}
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    {Settings.capitalizeFLetter("Vehicle movement from")}{" "}
                    {moment(this.state.selectedFromDate).format("DD-MM-YYYY")}{" "}
                    to {moment(this.state.selectedToDate).format("DD-MM-YYYY")}
                  </p>
                </CardHeader>
                <CardBody>
                  {this.state.netWorkError ? (
                    <SnackbarContent
                      icon={InfoIcon}
                      message={this.state.erroertext}
                      // close
                      color="danger"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.loader ? <LoaderComponent /> : ""}
                  <Table
                    tableHeaderColor="success"
                    tableHead={[
                      {
                        name: "Match",
                        prop: "db_match",
                      },
                      {
                        name: "Vehicle Number",
                        prop: "reading",
                      },
                      {
                        name: "Date",
                        prop: "eventdate",
                      },
                      {
                        name: "Time",
                        prop: "eventtime",
                      },
                      {
                        name: "Speed",
                        prop: "speed",
                      },
                      {
                        name: "Direction",
                        prop: "camera",
                      },
                      {
                        name: "Vehicle Image",
                        prop: "fullimagepath",
                      },
                    ]}
                    tableData={this.state.vehicles}
                    routename="lpr"
                    trackVehicle={this.trackVehicle}
                    vehicle={this.state.vehicle}
                    mobile={this.state.mobile}
                    datefro={moment(this.state.selectedFromDate).format(
                      "DD-MM-YYYY"
                    )}
                    dateto={moment(this.state.selectedToDate).format(
                      "DD-MM-YYYY"
                    )}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}></GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Searchplates));

import React from 'react';
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
class Footer extends React.Component {
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <div style={{ marginLeft: 30, marginTop: -33 }}>
            © Copyright <strong> Gaido Technologies</strong>.2023 All Rights Reserved
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={1}>
        </GridItem>
      </GridContainer>
    );
  }
}

export default Footer;



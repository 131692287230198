
import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Switch, withRouter } from "react-router-dom";
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import Table from "Grids/Reports";
import { HOST } from '../../lprfolders/config';
import moment from 'moment';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { BrowserRouter, Route, } from 'react-router-dom';
import './report.css';
import Tenant from './TenantReports';
import Camera from './camera';
import { NavLink } from 'react-router-dom';


import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
// import 'date-fns';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Dashboard from 'lprfolders/home/dashboard';
import ReportNavBar from './ReportNavBar';
import Reports from 'lprfolders/reports/Reports';
import LprReports from './LprReports';
import TenantReports from './TenantReports';
const piedata = {
    labels: ['Red', 'Blue', 'Yellow'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',

            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',

            ],
            borderWidth: 1,
        },
    ],
};
const pieOptions = {

    plugins: {
        legend: {
            boxWidth: '0.5',
        },
        indexAxis: 0,
    },
}
const options = {
    responsive: true,
    // plugins: {
    //     legend: {
    //         position: 'top',
    //     },
    //     title: {
    //         display: true,
    //         text: 'Chart.js Bar Chart',
    //     },
    // },
    // scales: {
    //     x: {
    //         stacked: true,
    //     },
    //     y: {
    //         stacked: true,
    //     },
    // },

};
ChartJS.register(...registerables);

const menuItem = [
    {
        path: "/reports",
        name: "Default",
    },
    {
        path: "/tenantReports",
        name: "Tenant Report",
    },

]
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class Searchplates extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            open: false,
            selectedFromDate: new Date(),
            selectedToDate: new Date(),
            vehicle: "",
            vehicles: [],
            flagError: false,
            netWorkError: false,
            inputname: "",
            erroertext: "",
            showtable: false,
            mobile: "",
            emptystate: false,
            tenantData: [],
            databaseVehicles: [],
            vehiclecount: [],
            labels: ["a"],
            component: <LprReports />
        }
    }

    handleFromDateChange = (date) => {
        this.setState({ selectedFromDate: date });
    };
    handleToDateChange = (date) => {
        this.setState({ selectedToDate: date });
    };

    componentDidMount() {
        document.getElementById('tenantReports').style.backgroundColor = '#cccacb';
        document.getElementById('reports').style.backgroundColor = 'white';
        document.getElementById('reports').style.border = '1px solid black'


        let apis = HOST + "/tenant/getall"
        axios.get(apis, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            this.setState({ tenantData: response.data })
        });
        let api1 = HOST + "/vehicle/getall"
        axios.get(api1, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            this.setState({ databaseVehicles: response.data })
        })


    }

    getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
        }
        return arr;
    };

    getEntryCount = function (start, end) {
        var entryCount = [];
        var count = 0;
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
        }
        arr.map((date) => {
            count = 0;
            this.state.vehicles.map((vehicle) => {
                if (vehicle.eventdate === date.split('-').reverse().join('-'))
                    if (vehicle.direction === "ENTRY") {
                        count++;
                    }
            })
            entryCount.push(count)
        })
        return entryCount;
    };

    getExitCount = function (start, end) {
        var exitCount = [];
        var count = 0;
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
        }
        arr.map((date) => {
            count = 0;
            this.state.vehicles.map((vehicle) => {
                if (vehicle.eventdate === date.split('-').reverse().join('-'))
                    if (vehicle.direction === "EXIT") {
                        count++;
                    }
            })
            exitCount.push(count)
        })
        return exitCount;
    };

    trackVehicle = () => {


        this.setState({ showtable: false })
        let api = HOST + "/report/lpr"
        let body = {
            vehicle: this.state.vehicle,
            mobile: this.state.mobile,
            datefrom: moment(this.state.selectedFromDate).format("YYYY-MM-DD"),
            dateto: moment(this.state.selectedToDate).format("YYYY-MM-DD")
        }
        let startDate = this.state.selectedFromDate
        let todate = this.state.selectedToDate
        let count = 0
        if (this.state.vehicle === "") {
            count = 0
        }
        if (this.state.vehicle) {
            if (Settings.Vehicle(this.state.vehicle).status) {
                count = 0
            }
            else {
                count++
                this.setState({ flagError: true, erroertext: Settings.Vehicle(this.state.vehicle).errmessgae, inputname: Settings.Vehicle(this.state.vehicle).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (startDate.getTime() <= todate.getTime()) {
        } else {
            count++
            this.setState({ erroertext: "To date should be after the start date ", flagError: true })
            setTimeout(() => { this.setState({ flagError: false }) }, 3000)
        }
        if (count === 0) {
            // this.setState({ showtable: true })
            this.setState({ loader: true })
            axios.post(api, body, {
                headers: {
                    "authToken": this.token,
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json'
                }
            }).then(res => {

                if (res.data.length === 0) {
                    this.setState({ emptystate: true, loader: false, })
                    this.setState({ showtable: false })
                    setTimeout(() => { this.setState({ emptystate: false }) }, 3000)
                } else {
                    this.setState({ vehicles: res.data, loader: false, emptystate: false })
                    this.setState({ showtable: true })
                    let data = []
                    let RegisteredVehicle = [];
                    let vehicleDetails = [];
                    let count;
                    this.state.vehicles.forEach(item => {
                        if (item.db_match === true) {
                            RegisteredVehicle.push(item)
                        }
                    })
                    this.state.databaseVehicles.map((tenant) => {
                        RegisteredVehicle.map((vehicle) => {
                            if (vehicle.reading.toLowerCase() === tenant.vehicle.toLowerCase()) {
                                Object.assign(vehicle, { "tenant": tenant.firstname })
                                vehicleDetails.push(vehicle)
                            }
                        })
                    })

                    this.state.tenantData.map((tenant) => {
                        count = 0;
                        vehicleDetails.map((vehicle) => {
                            if (tenant.firstname.toLowerCase() === vehicle.tenant.toLowerCase()) {
                                count++
                            }
                        })
                        data.push({ "tenant": tenant.tenant, "count": count })
                    })

                    this.setState({ vehiclecount: data })
                }
            }).catch(err => {
                this.setState({ showtable: true })
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.data.statusid === 409) {
                        this.setState({ flagError: true, loader: false, erroertext: Settings.Vehicle(409).errmessgae, inputname: Settings.Vehicle(409).inputname })
                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                    }
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })
        }

    }

    // tenantCount = () => {

    //     let data = []
    //     let RegisteredVehicle = [];
    //     let vehicleDetails = [];
    //     let count;
    //     console.log(this.state.vehicles)

    //     this.state.vehicles.forEach(item => {
    //         if (item.db_match === true) {
    //             RegisteredVehicle.push(item)
    //         }
    //     })
    //     this.state.databaseVehicles.map((tenant) => {
    //         RegisteredVehicle.map((vehicle) => {
    //             if (vehicle.reading.toLowerCase() === tenant.vehicle.toLowerCase()) {
    //                 Object.assign(vehicle, { "tenant": tenant.firstname })
    //                 vehicleDetails.push(vehicle)
    //             }
    //         })
    //     })

    //     this.state.tenantData.map((tenant) => {
    //         count = 0;
    //         vehicleDetails.map((vehicle) => {
    //             if (tenant.firstname.toLowerCase() === vehicle.tenant.toLowerCase()) {
    //                 count++
    //             }
    //         })
    //         console.log(count)
    //         data.push({ "tenant": tenant.tenant, "count": count })
    //     })

    //     this.setState({ vehiclecount: data })

    // }

    checkComponent = (path) => {
        switch (path) {
            case '/reports':
                this.setState({
                    component: <LprReports />
                })
                break;
            case '/tenantReports':
                this.setState({
                    component: <Tenant />
                })
                break;

            default:
                break;
        }

    }



    render() {
        const labels = this.getDaysArray(moment(this.state.selectedFromDate).format("YYYY-MM-DD"), moment(this.state.selectedToDate).format("YYYY-MM-DD"));
        const data = {
            labels,
            datasets: [
                {
                    label: 'Entry',
                    data: this.getEntryCount(moment(this.state.selectedFromDate).format("YYYY-MM-DD"), moment(this.state.selectedToDate).format("YYYY-MM-DD")),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: 'Exit',
                    data: this.getExitCount(moment(this.state.selectedFromDate).format("YYYY-MM-DD"), moment(this.state.selectedToDate).format("YYYY-MM-DD")),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    label: 'Dataset 2',
                    data: [10, 10, 10],
                    backgroundColor: 'rgb(255,0,0)',
                    borderColor: 'rgb(255,0,0)',
                    type: 'line',
                    borderDash: [10, 5],
                    order: 0
                },

            ],

        }

        const { classes } = this.props
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="container">
                            <div className="ReportNavBar">

                                {
                                    // menuItem.map((item, index) => (
                                    //     <Button key={index} color=""
                                    //         className="active" onClick={() => this.checkComponent(item.path)} activeclassName="active">
                                    //         <div className="link_textReport">{item.name}</div>
                                    //     </Button>
                                    // ))
                                }
                                <Button id="reports" color='transparent' onClick={() => {
                                    document.getElementById('tenantReports').style.backgroundColor = '#cccacb';
                                    document.getElementById('reports').style.backgroundColor = 'white'
                                    document.getElementById('reports').style.border = '1px solid black'
                                    document.getElementById('tenantReports').style.border = 'none';


                                    this.checkComponent('/reports')
                                }}>


                                    Tenant Reports
                                </Button>
                                <Button id="tenantReports" color='transparent' onClick={() => {
                                    document.getElementById('tenantReports').style.backgroundColor = 'white'
                                    document.getElementById('reports').style.backgroundColor = '#cccacb';
                                    document.getElementById('tenantReports').style.border = '1px solid black';
                                    document.getElementById('reports').style.border = 'none'

                                    


                                    this.checkComponent('/tenantReports')
                                }}>
                                    TENANT Analytics 
                                </Button>
                            </div>
                            <div>

                            </div>
                        </div>

                    </GridItem>

                </GridContainer>
                <div>
                    {this.state.component}

                </div>
            </div >
        );
    }
}

export default withRouter((withStyles(styles)(Searchplates)));

import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import Table from "Grids/Reports";
import { HOST } from '../../lprfolders/config';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
// import 'date-fns';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CustomInput from 'components/CustomInput/CustomInput';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class Searchplates extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            open: false,
            selectedFromDate: new Date(),
            selectedToDate: new Date(),
            vehicle: "",
            vehicles: [],
            flagError: false,
            netWorkError: false,
            inputname: "",
            erroertext: "",
            erroertext1: "",
            flagError1: false,
            showtable: false,
            mobile: "",
            emptystate: false
        }
    }

    handleFromDateChange = (date) => {
        this.setState({ selectedFromDate: date });
    };
    handleToDateChange = (date) => {
        this.setState({ selectedToDate: date });
    };
    trackVehicle = () => {

        this.setState({ showtable: false })
        let api = HOST + "/report/visitors"
        let body = {
            // vehicle: this.state.vehicle,
            // mobile: this.state.mobile,
            datefrom: moment(this.state.selectedFromDate).format("YYYY-MM-DD"),
            dateto: moment(this.state.selectedToDate).format("YYYY-MM-DD")
        }
        let startDate = this.state.selectedFromDate
        let todate = this.state.selectedToDate
        let count = 0
        if (this.state.vehicle === "") {
            count = 0
        }
        if (this.state.mobile === "") {
            count = 0
        }
        if (this.state.mobile) {
            if (Settings.mobilenumber(this.state.mobile).status) {
                count = 0
            }
            else {
                count++
                this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.mobile).errmessgae, inputname: Settings.mobilenumber(this.state.mobile).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (this.state.vehicle) {
            if (Settings.Vehicle(this.state.vehicle).status) {
                count = 0
            }
            else {
                count++
                this.setState({ flagError: true, erroertext: Settings.Vehicle(this.state.vehicle).errmessgae, inputname: Settings.Vehicle(this.state.vehicle).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (startDate.getTime() <= todate.getTime()) {

        } else {
            count++
            this.setState({ erroertext1: "To date should be after the start date ", flagError1: true })
            setTimeout(() => { this.setState({ flagError1: false }) }, 3000)
        }
        if (count === 0) {
            // this.setState({ showtable: true })
            this.setState({ loader: true })
            axios.post(api, body, {
                headers: {
                    "authToken": this.token,
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.data.length === 0) {
                    this.setState({ emptystate: true, loader: false, })
                    this.setState({ showtable: false })
                    setTimeout(() => { this.setState({ emptystate: false }) }, 3000)
                } else {

                    this.setState({ vehicles: res.data, loader: false, emptystate: false })
                    this.setState({ showtable: true })
                }
            }).catch(err => {
                this.setState({ showtable: true })
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.data.statusid === 409) {
                        this.setState({ flagError: true, loader: false, erroertext: Settings.Vehicle(409).errmessgae, inputname: Settings.Vehicle(409).inputname })
                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                    }
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })
        }
    }
    render() {

        const { classes } = this.props
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Select Period</h4>
                                <p className={classes.cardCategoryWhite}>Results between the selected dates will be displayed</p>
                            </CardHeader>
                            <CardBody>
                                {this.state.flagError1 ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext1}
                                    // close
                                    color="danger"
                                /> : ""}

                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    color="primary"
                                                    id="date-picker-dialog"
                                                    label="Select from Date"
                                                    format="dd/MM/yyyy"
                                                    value={this.state.selectedFromDate}
                                                    onChange={this.handleFromDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    maxDate={new Date()}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    color="primary"
                                                    id="date-picker-dialog"
                                                    label="Select to Date"
                                                    format="dd/MM/yyyy"
                                                    value={this.state.selectedToDate}
                                                    maxDate={new Date()}
                                                    onChange={this.handleToDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <Button color="primary" onClick={this.trackVehicle}><AssessmentIcon />Generate Report</Button>
                    </GridItem>
                </GridContainer>
                {this.state.loader ? <LoaderComponent /> : ""}
                {this.state.emptystate ? <SnackbarContent
                    icon={InfoIcon}
                    message={"No records found"}
                    // close
                    color="danger"
                /> : ""}
                {this.state.showtable ? <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Vehicle Activity</h4>
                                <p className={classes.cardCategoryWhite}>
                                    Vehicle movement from {moment(this.state.selectedFromDate).format("DD-MM-YYYY")} to {moment(this.state.selectedToDate).format("DD-MM-YYYY")}
                                </p>
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}

                                <Table
                                    tableHeaderColor="success"
                                    tableHead={[
                                        {
                                            name: "Date",
                                            prop: "date"
                                        },
                                       
                                        {
                                            name: "Vehicle Number",
                                            prop: "vehicle"
                                        },
                                        {
                                            name: "Visitor Name",
                                            prop: "visitor"
                                        },
                                        {
                                            name: "Mobile Number",
                                            prop: "mobile"
                                        },
                                        {
                                            name: "Status",
                                            prop: "status"
                                        },
                                        {
                                            name: "To Meet",
                                            prop: "tomeet"
                                        },
                                        {
                                            name: "Tenant",
                                            prop: "tenant"
                                        },
                                        // {
                                        //   name: "Host Name",
                                        //   prop: "hostname"
                                        // },
                                        // {
                                        //   name: "Company",
                                        //   prop: "company"
                                        // },
                                        // {
                                        //   name: "Purpose",
                                        //   prop: "purpose"
                                        // },

                                        {
                                            name: "In Time",
                                            prop: "intime"
                                        },
                                        {
                                            name: "Out Time",
                                            prop: "outtime"
                                        },
                                    ]}
                                    tableData={this.state.vehicles}
                                    routename="visitor"

                                    trackVehicle={this.trackVehicle}
                                    vehicle={this.state.vehicle}
                                    mobile={this.state.mobile}
                                    datefro={moment(this.state.selectedFromDate).format("DD-MM-YYYY")}
                                    dateto={moment(this.state.selectedToDate).format("DD-MM-YYYY")}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    </GridItem>
                </GridContainer>
                    : ""}
            </div>
        );
    }
}

export default withRouter((withStyles(styles)(Searchplates)));
import React, { Component } from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { withRouter } from "react-router-dom";
import Settings from "../../lprfolders/GlobalConstant/Settings";
import LoaderComponent from "../../lprfolders/GlobalConstant/LoaderComponent";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from "@material-ui/icons/Info";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Alert from "../../lprfolders/GlobalConstant/Alert";
import axios from "axios";
import { HOST } from "../../lprfolders/config";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import QrReader from "react-qr-reader";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import Primary from "components/Typography/Primary";
import { object } from "prop-types";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  root: {
    // flexGrow: 1,
    // display: "grid",
    // height: "90vh"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const previewStyle = {
  height: 300,
  width: 300,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class Add extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.companyid = localStorage.getItem("companyid");
    this.cameraAccess = sessionStorage.getItem("camera");

    this.state = {
      flagError: false,
      netWorkError: false,
      inputname: "",
      erroertext: "",
      vehicleid: "",
      vehicle: "",
      flatno: "",
      fullname: "",
      mobile: "",
      comments: "",
      tc: false,
      bc: false,
      modal: false,
      online: window.navigator.onLine,
      tenantTypeArray: [],
      tenantType: [],
      tenantid: "",
      cameraTypes: [],
      cameraTypeArray: [],
      camreaType: [],
      cameraTypeIds: [],
      cameraids: [],
      cameraSelect: false,
      emails: [],
      excludeStatus:false,
      primaryEmail: "",
      secondaryEmail: "",
    };
    this.r1Ref = React.createRef();
  }
  add = () => {
    this.state.cameraTypeIds.map((id) => {
      this.state.cameraids.push({ cameraid: id });
    });
    this.setState({ cameraids: this.state.cameraids });

    if (window.sessionStorage.getItem("speed") === "true") {
      this.state.emails.push(this.state.primaryEmail);
      this.state.emails.push(this.state.secondaryEmail);
    } else {
      this.state.emails.splice(0, this.state.emails.length);
      this.setState({ emails: [] });
    }
    let api = HOST + "/vehicle/add";
    let body = {
      vehicle: this.state.vehicle,
      flatno: this.state.flatno,
      fullname: this.state.fullname,
      mobile: this.state.mobile,
      comments: this.state.comments,
      tenantid: this.state.tenantid,
      email: this.state.emails.length > 0 ? this.state.emails.toString() : "",
      cameraids: this.state.cameraids,
      isexclude: this.state.excludeStatus
    };
    let count = 1;
    if (this.state.comments === "") {
      count--;
    }
    if (this.state.comments) {
      if (Settings.Comments(this.state.comments).status) {
        count--;
      } else {
        count++;
        this.setState({
          flagError: true,
          erroertext: Settings.Comments(this.state.comments).errmessgae,
          inputname: Settings.Comments(this.state.comments).inputname,
        });
        setTimeout(() => {
          this.setState({ flagError: false });
        }, 3000);
      }
    }
    if (Settings.FlatNo(this.state.flatno).status) {
      if (Settings.Vehicle(this.state.vehicle).status) {
        if (Settings.PersonName(this.state.fullname).status) {
          if (Settings.mobilenumber(this.state.mobile).status) {
          } else {
            count++;
            this.setState({
              flagError: true,
              erroertext: Settings.mobilenumber(this.state.mobile).errmessgae,
              inputname: Settings.mobilenumber(this.state.mobile).inputname,
            });
            setTimeout(() => {
              this.setState({ flagError: false });
            }, 3000);
          }
        } else {
          count++;
          this.setState({
            flagError: true,
            erroertext: Settings.PersonName(this.state.fullname).errmessgae,
            inputname: Settings.PersonName(this.state.fullname).inputname,
          });
          setTimeout(() => {
            this.setState({ flagError: false });
          }, 3000);
        }
      } else {
        count++;
        this.setState({
          flagError: true,
          erroertext: Settings.Vehicle(this.state.vehicle).errmessgae,
          inputname: Settings.Vehicle(this.state.vehicle).inputname,
        });
        setTimeout(() => {
          this.setState({ flagError: false });
        }, 3000);
      }
    } else {
      count++;
      this.setState({
        flagError: true,
        erroertext: Settings.FlatNo(this.state.flatno).errmessgae,
        inputname: Settings.FlatNo(this.state.flatno).inputname,
      });
      setTimeout(() => {
        this.setState({ flagError: false });
      }, 3000);
    }
    if (count === 0) {
      if (this.state.online) {
        this.setState({ loader: true });
        axios
          .post(api, body, {
            headers: {
              authToken: this.token,
              Accept: "application/json",
              // 'Content-Type': 'application/json'
            },
          })
          .then((res) => {
            if (res.status === 201) {
              this.setState({ loader: false });
              this.showNotification("tc");
              setTimeout(() => {
                this.props.history.push("/vehicle");
              

              }, 2000);
            }
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              this.setState({
                netWorkError: true,
                loader: false,
                erroertext: Settings.netWorkError().errmessgae,
              });
              setTimeout(() => {
                this.setState({ netWorkError: false });
              }, 3000);
            }
            if (err.response) {
              if (err.response.data.statusid === 409) {
                this.setState({
                  flagError: true,
                  loader: false,
                  erroertext: Settings.Vehicle(409).errmessgae,
                  inputname: Settings.Vehicle(409).inputname,
                });
                setTimeout(() => {
                  this.setState({ flagError: false });
                }, 3000);
              }
              if (err.response.status === 502) {
                this.setState({
                  netWorkError: true,
                  loader: false,
                  erroertext: Settings.Server().errmessgae,
                });
                setTimeout(() => {
                  this.setState({ netWorkError: false });
                }, 3000);
              }
            }
          });
      } else {
        this.setState({
          netWorkError: true,
          loader: false,
          erroertext: Settings.netWorkError().errmessgae,
        });
        setTimeout(() => {
          this.setState({ netWorkError: false });
        }, 3000);
      }
    }
  };
  componentDidMount() {
    window.addEventListener("offline", this.handleNetworkChange);
    window.addEventListener("online", this.handleNetworkChange);

    let api = HOST + "/tenant/getall";
    axios
      .get(api, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({ tenantTypeArray: response.data });
        // this.setState({ floorTypes: response.data });
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          this.setState({
            netWorkError: true,
            loader: false,
            erroertext: Settings.netWorkError().errmessgae,
          });
          setTimeout(() => {
            this.setState({ netWorkError: false });
          }, 3000);
        }
        if (err.response) {
          if (err.response.status === 502) {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.Server().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
        }
      });

    let api1 = HOST + "/camera/getall";
    axios
      .get(api1, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({ cameraTypes: [] });
        this.setState({ cameraTypeArray: response.data });

        this.state.cameraTypeArray.map((camera) => {
          this.state.cameraTypeIds.push(camera.cameraid);
          this.setState({ cameraTypeIds: this.state.cameraTypeIds });
        });
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          this.setState({
            netWorkError: true,
            loader: false,
            erroertext: Settings.netWorkError().errmessgae,
          });
          setTimeout(() => {
            this.setState({ netWorkError: false });
          }, 3000);
        }
        if (err.response) {
          if (err.response.status === 502) {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.Server().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
        }
      });
  }

  componentWillUnmount() {
    window.removeEventListener("offline", this.handleNetworkChange);
    window.removeEventListener("online", this.handleNetworkChange);
  }
  handleNetworkChange = () => {
    this.setState({ online: window.navigator.onLine });
  };
  showNotification = (place) => {
    switch (place) {
      case "tc":
        if (!this.state.tc) {
          this.setState({ tc: true, erroertext: Alert.Successadd });
          setTimeout(() => {
            this.setState({ tc: false });
          }, 4000);
        }
        break;
      case "bc":
        if (!this.state.bc) {
          this.setState({ bc: true });
          setTimeout(() => {
            this.setState({ bc: false });
          }, 4000);
        }
        break;
      default:
        break;
    }
  };
  handleScan = (data) => {
    if (data !== null) {
      var jsonStrig = "[{";
      var items = data.split("\n");
      for (var i = 0; i < items.length; i++) {
        var current = items[i].split(":");
        jsonStrig += '"' + current[0] + '":"' + current[1] + '",';
      }
      jsonStrig = jsonStrig.substr(0, jsonStrig.length - 1);
      jsonStrig += "}]";
      var obj = JSON.parse(jsonStrig);
      var finalObj = Object.values(obj[0]);
      this.setState({
        fullname: finalObj[0],
        vehicle: finalObj[1],
        mobile: finalObj[2],
        modal: false,
      });
      this.r1Ref.current.focus();
    }
  };
  handleError = (err) => {
    console.error(err);
  };

  handleChange = (event, values) => {
    if (values) {
      this.setState({
        tenantid: values.props.value,
      });
    }
  };

  selectCameras = (event, values) => {
    const {
      target: { value },
    } = event;
    this.setState({
      camreaType: typeof value === "string" ? value.split(",") : value,
    });
    if (values.props.children[0].props.checked === false) {
      if (
        this.state.cameraTypeIds.find((id) => id === values.props.Key) ===
        undefined
      ) {
        this.state.cameraTypeIds.push(values.props.Key);
      }
    } else {
      this.state.cameraTypeIds.find((value, index) => {
        if (value === values.props.Key) {
          this.state.cameraTypeIds.splice(index, 1);
        }
      });
    }
  };

  // excludeStatusChange=(event,value) => {
  //   this.setState({excludeStatus:!this.state.excludeStatus});
  // }

  CheckboxhandleChange = (event, value) => {
    // console.log(value)
    // if (value === false) {
    //     this.state.cameraTypeIds.map((camera) => {
    //         if (event.target.id === camera.cameraid) {

    //             camera.isassign = "false"
    //         }

    //     })

    // } else {
    //     this.state.cameraTypeIds.map((camera) => {
    //         if (event.target.id === camera.cameraid) {

    //             camera.isassign = "true"
    //         }

    //     })
    // }

    if (value === true) {
      this.state.cameraTypeIds.push(event.target.id);
    } else {
      this.state.cameraTypeIds.find((cameraid, index) => {
        if (cameraid === event.target.id) {
          this.state.cameraTypeIds.splice(index, 1);
        }
      });
    }

    this.setState({ cameraTypeIds: this.state.cameraTypeIds });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          // fullScreen={fullScreen}
          open={this.state.modal}
          // disableBackdropClick={true}
          onClose={() => this.setState({ modal: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Scan QR to Share Activity"}
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={() => {
                this.setState({ modal: false });
              }}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div style={{ overflow: "hidden" }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={previewStyle}
                    legacyMode={false}
                    facingMode="environment"
                  />
                </GridItem>
              </GridContainer>
            </div>
          </DialogContent>
        </Dialog>
        {this.state.tc ? (
          <Snackbar
            place="tc"
            color="success"
            icon={ThumbUpIcon}
            message={" Vehicle " + this.state.erroertext}
            open={this.state.tc}
            closeNotification={() => this.setState({ tc: false })}
            close
          />
        ) : (
          ""
        )}
        <div className={classes.root}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}></GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    {Settings.titleCase("Add vehicle")}
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    {Settings.capitalizeFLetter(
                      "Please fill in all required feilds"
                    )}
                  </p>
                </CardHeader>
                <CardBody>
                  {this.state.netWorkError ? (
                    <SnackbarContent
                      icon={InfoIcon}
                      message={this.state.erroertext}
                      // close
                      color="danger"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.loader ? <LoaderComponent /> : ""}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="* EMP ID(max. 10 characters)"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        id="FlatNo"
                        inputRef={this.r1Ref}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ flatno: event.target.value });
                            if (Settings.FlatNo(event.target.value).status) {
                              this.setState({
                                flagError: false,
                                erroertext: "",
                              });
                            } else {
                              this.setState({
                                flagError: true,
                                erroertext: Settings.FlatNo(event.target.value)
                                  .errmessgae,
                                inputname: Settings.FlatNo(event.target.value)
                                  .inputname,
                              });
                            }
                          },
                          type: "text",
                          value: this.state.flatno,
                          error:
                            (this.state.flagError &&
                              this.state.inputname === "FlatNo") ||
                            (this.state.requirederror &&
                              this.state.flatno === "")
                              ? true
                              : false,
                        }}
                      />
                      {this.state.requirederror && !this.state.flatno ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                      {this.state.flagError &&
                      this.state.inputname === "FlatNo" ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                    </GridItem>
                    {sessionStorage.getItem("role") === "IsManager" &&
                    sessionStorage.getItem("tenant") === "true" ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          color="primary"
                          style={{
                            width: "100%",
                            height: "60%",
                            marginTop: 28,
                          }}
                        >
                          <InputLabel id="demo-simple-select-helper-label">
                            * Tenant
                          </InputLabel>

                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={this.state.tenantid}
                            onChange={this.handleChange}
                            color="green"
                            style={{ fontSize: 15 }}
                          >
                            {this.state.tenantTypeArray.map(
                              (entryCriteria, index) => (
                                <MenuItem value={entryCriteria.tenantid}>
                                  {entryCriteria.tenant}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          {this.state.requirederror && !this.state.tenantid ? (
                            <SnackbarContent
                              icon={InfoIcon}
                              message={this.state.erroertext}
                              // close
                              color="danger"
                            />
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </GridItem>
                    ) : (
                      ""
                    )}

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="* Vehicle Number(max. 10 characters)"
                        id="Vehicle Number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ vehicle: event.target.value });
                            if (
                              Settings.Vehicle(event.target.value).status ||
                              event.target.value === ""
                            ) {
                              this.setState({
                                flagError: false,
                                erroertext: "",
                              });
                            } else {
                              this.setState({
                                flagError: true,
                                erroertext: Settings.Vehicle(event.target.value)
                                  .errmessgae,
                                inputname: Settings.Vehicle(event.target.value)
                                  .inputname,
                              });
                            }
                          },
                          onInput: (e) =>
                            (e.target.value = ("" + e.target.value)
                              .toUpperCase()
                              .toString()
                              .slice(0, 10)),
                          type: "text",
                          value: this.state.vehicle,
                          error:
                            (this.state.flagError &&
                              this.state.inputname === "Vehicle") ||
                            (this.state.requirederror &&
                              this.state.vehicle === "")
                              ? true
                              : false,
                        }}
                      />
                      {this.state.requirederror && !this.state.vehicle ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                      {this.state.flagError &&
                      this.state.inputname === "Vehicle" ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="* Mobile Number(10 digits)"
                        id="Mobile"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ mobile: event.target.value });
                            if (
                              Settings.mobilenumber(event.target.value)
                                .status ||
                              !event.target.value
                            ) {
                              this.setState({
                                flagError: false,
                                erroertext: "",
                              });
                            } else {
                              this.setState({
                                flagError: true,
                                erroertext: Settings.mobilenumber(
                                  event.target.value
                                ).errmessgae,
                                inputname: Settings.mobilenumber(
                                  event.target.value
                                ).inputname,
                              });
                            }
                          },
                          onInput: (e) =>
                            (e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10)),
                          type: "number",
                          value: this.state.mobile,
                          error:
                            (this.state.flagError &&
                              this.state.inputname === "Mobile") ||
                            (this.state.requirederror &&
                              this.state.mobile === "")
                              ? true
                              : false,
                        }}
                      />
                      {this.state.requirederror && !this.state.mobile ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                      {this.state.flagError &&
                      this.state.inputname === "Mobile" ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="* Person Name(max. 20 characters)"
                        id="Person Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ fullname: event.target.value });
                            if (
                              Settings.PersonName(event.target.value).status ||
                              event.target.value === ""
                            ) {
                              this.setState({
                                flagError: false,
                                erroertext: "",
                              });
                            } else {
                              this.setState({
                                flagError: true,
                                erroertext: Settings.PersonName(
                                  event.target.value
                                ).errmessgae,
                                inputname: Settings.PersonName(
                                  event.target.value
                                ).inputname,
                              });
                            }
                          },

                          type: "text",
                          value: this.state.fullname,
                          error:
                            (this.state.flagError &&
                              this.state.inputname === "PersonName") ||
                            (this.state.requirederror &&
                              this.state.fullname === "")
                              ? true
                              : false,
                        }}
                      />
                      {this.state.requirederror && !this.state.fullname ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                      {this.state.flagError &&
                      this.state.inputname === "PersonName" ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                    </GridItem>
                    {window.sessionStorage.getItem("speed") === "true" ? (
                      <>
                        <GridItem xs={12} sm={12} md={6} id="email">
                          <CustomInput
                            labelText="Primary Email(Ex. admin@gmail.com)"
                            id="PrimaryEmail"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: (event) => {
                                this.setState({
                                  primaryEmail: event.target.value,
                                });
                                if (
                                  Settings.Email(event.target.value).status ||
                                  event.target.value === ""
                                ) {
                                  this.setState({
                                    flagError: false,
                                    erroertext: "",
                                  });
                                } else {
                                  this.setState({
                                    flagError: true,
                                    erroertext: Settings.Email(
                                      event.target.value
                                    ).errmessgae,
                                    inputname: Settings.Email(
                                      event.target.value
                                    ).inputname,
                                  });
                                }
                              },
                              type: "text",
                              error:
                                this.state.flagError &&
                                this.state.inputname === "Email"
                                  ? true
                                  : false,
                            }}
                          />
                          {this.state.requirederror &&
                          !this.state.primaryEmail ? (
                            <SnackbarContent
                              icon={InfoIcon}
                              message={this.state.erroertext}
                              // close
                              color="danger"
                            />
                          ) : (
                            ""
                          )}
                          {this.state.flagError &&
                          this.state.inputname === "Email" ? (
                            <SnackbarContent
                              icon={InfoIcon}
                              message={this.state.erroertext}
                              // close
                              color="danger"
                            />
                          ) : (
                            ""
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} id="email">
                          <CustomInput
                            labelText="Secondary Email(Ex. admin@gmail.com)"
                            id="SecondarEmail"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: (event) => {
                                this.setState({
                                  secondaryEmail: event.target.value,
                                });
                                if (
                                  Settings.SecondaryEmail(event.target.value)
                                    .status ||
                                  event.target.value === ""
                                ) {
                                  this.setState({
                                    flagError: false,
                                    erroertext: "",
                                  });
                                } else {
                                  this.setState({
                                    flagError: true,
                                    erroertext: Settings.SecondaryEmail(
                                      event.target.value
                                    ).errmessgae,
                                    inputname: Settings.SecondaryEmail(
                                      event.target.value
                                    ).inputname,
                                  });
                                }
                              },
                              type: "text",
                              error:
                                this.state.flagError &&
                                this.state.inputname === "Secondary Email"
                                  ? true
                                  : false,
                            }}
                          />
                          {this.state.requirederror && !this.state.Email ? (
                            <SnackbarContent
                              icon={InfoIcon}
                              message={this.state.erroertext}
                              // close
                              color="danger"
                            />
                          ) : (
                            ""
                          )}
                          {this.state.flagError &&
                          this.state.inputname === "Secondary Email" ? (
                            <SnackbarContent
                              icon={InfoIcon}
                              message={this.state.erroertext}
                              // close
                              color="danger"
                            />
                          ) : (
                            ""
                          )}
                        </GridItem>
                      </>
                    ) : (
                      ""
                    )}

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Comments(max. 25)"
                        id="Comments"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ comments: event.target.value });
                            if (
                              Settings.Comments(event.target.value).status ||
                              event.target.value === ""
                            ) {
                              this.setState({
                                flagError: false,
                                erroertext: "",
                              });
                            } else {
                              this.setState({
                                flagError: true,
                                erroertext: Settings.Comments(
                                  event.target.value
                                ).errmessgae,
                                inputname: Settings.Comments(event.target.value)
                                  .inputname,
                              });
                            }
                          },
                          type: "text",
                          error:
                            this.state.flagError &&
                            this.state.inputname === "Comments"
                              ? true
                              : false,
                        }}
                      />
                      {this.state.flagError &&
                      this.state.inputname === "Comments" ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                    </GridItem>

                    {/* {this.props.location.state.exclude === true ? (
  <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ color: "primary", marginTop: 25 }}
                      >
                        <InputLabel id="demo-simple-select-helper-label">
                          Exclude Status
                        </InputLabel>
                  
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={this.excludeStatusChange}
                                  checked={this.state.excludeStatus}
                                  style={{ color: "green" }}
                                />
                              }
                            ></FormControlLabel>
                       
                       
                      </GridItem>               
                    ) : (
                      ""
                    )} */}
                  </GridContainer>
                  <GridContainer>
                    {this.cameraAccess === "true" ? (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ color: "primary", marginTop: 25 }}
                      >
                        <InputLabel id="demo-simple-select-helper-label">
                          * Select Gate
                        </InputLabel>
                        {this.state.cameraTypeArray.map(
                          (entryCriteria, index) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={this.CheckboxhandleChange}
                                  id={entryCriteria.cameraid}
                                  checked={
                                    this.state.cameraTypeIds.indexOf(
                                      entryCriteria.cameraid
                                    ) > -1
                                  }
                                  style={{ color: "green" }}
                                />
                              }
                              label={entryCriteria.camera}
                            ></FormControlLabel>
                          )
                        )}
                        {this.state.requirederror &&
                        !(this.state.cameraTypeIds.length !== 0) ? (
                          <SnackbarContent
                            icon={InfoIcon}
                            message={this.state.erroertext}
                            // close
                            color="danger"
                          />
                        ) : (
                          ""
                        )}
                      </GridItem>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      {this.state.vehicle !== "" &&
                      this.state.flatno !== "" &&
                      this.state.mobile !== "" &&
                      this.state.fullname !== "" &&
                      this.state.cameraTypeIds.length !== 0 ? (
                        <Button color="primary" onClick={this.add}>
                          {" "}
                          <AddBoxIcon />
                          Add
                          <span
                            style={{ display: "inline-block", width: "21px" }}
                          ></span>
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            if (
                              Settings.requireerrors([
                                this.state.vehicle,
                                this.state.flatno,
                                this.state.mobile,
                                this.state.fullname,
                                this.state.cameraTypeIds,
                              ]).status === true
                            ) {
                              this.setState({
                                requirederror: true,
                                erroertext: Settings.requireerrors([
                                  this.state.companyname,
                                  this.state.mobile,
                                  this.state.companytype,
                                  this.state.cameraTypeIds,
                                ]).errmessgae,
                              });
                              setTimeout(() => {
                                this.setState({ requirederror: false });
                              }, 3000);
                            }
                          }}
                        >
                          {" "}
                          <AddBoxIcon />
                          Add
                          <span
                            style={{ display: "inline-block", width: "21px" }}
                          ></span>
                        </Button>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                    
                        <Button
                          color="white"
                          onClick={() => {
                            this.props.history.push("/vehicle");
                          }}
                        >
                          <CancelPresentationIcon />
                          Cancel
                        </Button>
                      
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}></GridItem>
                  </GridContainer>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}></GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Add));

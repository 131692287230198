import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { withRouter } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Alert from '../../lprfolders/GlobalConstant/Alert'
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    // KeyboardDatePicker,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
// import 'date-fns';
// import FindReplaceIcon from '@material-ui/icons/FindReplace';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Radio from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
// import { ModeComment } from '@material-ui/icons';
const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },

    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class RegisterVisitor extends Component {
    constructor(props) {
        super(props);

        this.token = localStorage.getItem('token');
        this.state = {
            flagError: false,
            netWorkError: false,
            erroertext: "",
            inputname: "",
            tc: false,
            bc: false,
            flatno: "",
            fullname: "",
            mobile: "",
            vehicle: props.lpr,
            visitorname: "",
            companyname: "",
            hostname: "",
            hostnameerror: false,
            tomeet: "",
            comments: "",
            statusid: 1,
            statuschecked: true,
            statuschecked1: false,
            selectedDate: new Date(),
            online: window.navigator.onLine,
            selectedDate: new Date(),
            comments: "",
            tenantTypeArray: [],
            tenantType: [],
            tenantid: "",



        }
    }
    componentDidMount() {

        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);

        let api = HOST + "/tenant/getall"
        axios.get(api, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            this.setState({ tenantTypeArray: response.data });
            // this.setState({ floorTypes: response.data });
        }).catch(err => {
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })
    }

    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }

    handleDateChange = (date) => {
        this.setState({ selectedDate: date });
    };
    add = () => {
        let count = 1
        let api = HOST + "/visitor/add"

        let body =
        {
            visitor: this.state.visitorname,
            mobile: this.state.mobile,
            vehicle: this.state.vehicle,
            tomeet: this.state.tomeet,
            statusid: this.state.statusid,
            tenantid: this.state.tenantid,
            // intime: moment(this.state.selectedDate).format('YYYY-MM-DD, h:mm:ss a'),
            expectedtime: moment(this.state.selectedDate).format("YYYY-MM-DD"),
            comments: this.state.comments

        }
        if (this.state.vehicle === "") {
            count--
        }
        if (this.state.vehicle) {
            if (Settings.Vehicle(this.state.vehicle).status) {
                count--
            }
            else {
                count++
                this.setState({ flagError: true, erroertext: Settings.Vehicle(this.state.vehicle).errmessgae, inputname: Settings.Vehicle(this.state.vehicle).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (Settings.PersonName(this.state.visitorname).status) {
            if (Settings.mobilenumber(this.state.mobile).status) {
                if (Settings.Comments(this.state.tomeet).status) {


                } else {
                    count++
                    this.setState({ flagError: true, erroertext: "Tomeet length must be minium 3 maximum 25", inputname: Settings.Comments(this.state.tomeet).inputname })
                    setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                }

            }

            else {
                count++
                this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.mobile).errmessgae, inputname: Settings.mobilenumber(this.state.mobile).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        else {
            count++
            this.setState({ hostnameerror: false, flagError: true, erroertext: Settings.PersonName(this.state.visitorname).errmessgae, inputname: Settings.PersonName(this.state.visitorname).inputname })
            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        }
        if (count === 0) {
            if (this.state.online) {

                this.setState({ loader: true })
                axios.post(api, body, {
                    headers: {
                        "authToken": this.token,
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json'
                    }
                }).then(res => {
                    if (res.status === 201) {
                        this.setState({ loader: false })
                        this.showNotification("tc")
                        setTimeout(() => { this.props.history.push('/visitor') }, 2000);
                    }
                }).catch(err => {
                    if (err.message === "Network Error") {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                    if (err.response) {
                        if (err.response.data.statusid === 409) {
                            this.setState({ flagError: true, loader: false, erroertext:"Vehicle is Already Registered as Visitor on "+ err.response.data.onthisdate.split("T")[0], inputname: "Vehicle" })
                            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                        }
                        if (err.response.data.statusid === 408) {
                            this.setState({ flagError: true, loader: false, erroertext: "Vehicle is Already Registered as Visitor on "+ err.response.data.onthisdate.split("T")[0], inputname: "Vehicle" })
                            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                        }
                        if (err.response.status === 502) {
                            this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                            setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                        }
                    }
                })
            }
            else {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
        }

    }
    handleChange = (event, values) => {
        if (values) {
            this.setState({
                tenantid: values.props.value
            });
        }
    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    onChangestatus = (event) => {
        this.setState({ statusid: event.target.value, statuschecked: !this.state.statuschecked, statuschecked1: !this.state.statuschecked1 })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                {/* <GridContainer>
                    {this.state.tc ? <Snackbar
                        place="tc"
                        color="success"
                        icon={ThumbUpIcon}
                        message={" Visitor " + this.state.erroertext}
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    /> : ""}
                    <GridItem xs={12} sm={12} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Add visitor")}</h4>
                                <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please fill in all required feilds")}</p>
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Visitor Name(max. 20 characters)"
                                            id="Name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {this.setState({ visitorname: event.target.value })
                                                if (Settings.PersonName(event.target.value).status) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.PersonName(event.target.value).errmessgae, inputname: Settings.PersonName(event.target.value).inputname })
                                                }
                                            },
                                                value: this.state.visitorname,
                                                type: "text",
                                                error: (this.state.flagError && this.state.inputname === "PersonName") || (this.state.requirederror && this.state.visitorname === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.visitorname ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "PersonName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Mobile Number(10 digits)"
                                            id="Mobile"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {this.setState({ mobile: event.target.value })
                                                if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname:"Mobile" })
                                                }
                                            },
                                                onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                type: "number",
                                                value: this.state.mobile,
                                                error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.mobile ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Vehicle Number(max. 10 characters)"
                                            id="Vehicle Number"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {this.setState({ vehicle: event.target.value })
                                                if (Settings.Vehicle(event.target.value).status) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.Vehicle(event.target.value).errmessgae, inputname: "Vehicle" })
                                                }
                                            },
                                                onInput: (e) => e.target.value = ("" + e.target.value).toUpperCase().toString().slice(0, 10),
                                                type: "text",
                                                value: this.state.vehicle,
                                                error: (this.state.flagError && this.state.inputname === "Vehicle") ? true : false
                                            }}
                                        />
                                        {this.state.flagError && this.state.inputname === "Vehicle" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Host name(max. 20 characters)"
                                            id="Host name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) =>{ this.setState({ hostname: event.target.value })
                                                if (Settings.HoStName(event.target.value).status) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.HoStName(event.target.value).errmessgae, inputname: Settings.HoStName(event.target.value).inputname })
                                                }
                                            },
                                                type: "text",
                                                value: this.state.hostname,
                                                error: (this.state.flagError && this.state.inputname === "Hostname") || (this.state.requirederror && this.state.hostname === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.hostname ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Hostname" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Company(max. 20 characters)"
                                            id="Company"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {this.setState({ companyname: event.target.value })
                                                if (Settings.CompnayName(event.target.value).status) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.CompnayName(event.target.value).errmessgae, inputname: "CompanyName" })
                                                }
                                            },
                                                type: "text",
                                                value: this.state.companyname,
                                                error: (this.state.flagError && this.state.inputname === "CompanyName") || (this.state.requirederror && this.state.companyname === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.companyname ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "CompanyName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Purpose(max. 25 characters)"
                                            id="Purpose"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) =>{ this.setState({ purpose: event.target.value })
                                                if (Settings.Comments(event.target.value).status) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.Comments(event.target.value).errmessgae, inputname: "Comments" })
                                                }
                                            },
                                                type: "text",
                                                value: this.state.purpose,
                                                error: (this.state.flagError && this.state.inputname === "Comments") || (this.state.requirederror && this.state.purpose === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.purpose ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Comments" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>

                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <p style={{ color: "gray", marginTop: 10 }}>* Status</p>
                                        <GreenRadio
                                            onChange={this.onChangestatus}
                                            checked={this.state.statuschecked}
                                            value={1}
                                            name="radio-button-demo"
                                        // inputProps={{ 'aria-label': 1 }}/
                                        />
                                        <span >Expected</span>
                                        <GreenRadio
                                            checked={this.state.statuschecked1}
                                            onChange={this.onChangestatus}
                                            value={2}
                                            name="radio-button-demo"
                                        // inputProps={{ 'aria-label': 2 }}
                                        />
                                        <span  >Check-in</span>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardTimePicker
                                                    style={{ marginTop: 30 }}
                                                    id="time-picker"
                                                    label="* In time"
                                                    disabled={this.state.statuschecked ? false : true}
                                                    // disableToolbar={this.state.statusid === 2 ? true :false}
                                                    value={this.state.statuschecked ? this.state.selectedDate : new Date()}
                                                    onChange={(date) => {
                                                        var dateof = new Date();
                                                        var milliseconds = dateof.getTime();
                                                        if ((milliseconds - date.getTime()) < 60000) {
                                                            this.setState({ selectedDate: date })
                                                        } else {
                                                            this.setState({ selectedDate: dateof, flagError: true, erroertext: "Invalid time selection", inputname: "Intime" })
                                                            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                                                        }
                                                    }}
                                                    disableToolbar={false}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                        {this.state.flagError && this.state.inputname === "Intime" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        {this.state.visitorname !== "" && this.state.mobile !== "" && this.state.hostname !== "" && this.state.companyname !== "" && this.state.purpose !== "" ?
                                            <Button color="primary" onClick={this.add}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                            <Button onClick={() => {
                                                if (Settings.requireerrors([this.state.vehicle, this.state.flatno, this.state.mobile, this.state.fullname]).status === true) {
                                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.hostname, this.state.visitorname, this.state.companyname]).errmessgae })
                                                    setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                }
                                            }}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                        }
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={1}>
                                        <Button color="white" onClick={() => {
                                            this.props.history.push('/visitor')
                                        }}><CancelPresentationIcon />Cancel</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer> */}


                <GridContainer>
                    {this.state.tc ? <Snackbar
                        place="tc"
                        color="success"
                        icon={ThumbUpIcon}
                        message={" Visitor " + this.state.erroertext}
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    /> : ""}
                    <GridItem xs={12} sm={12} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Register visitor")}</h4>
                                <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please fill in all required feilds")}</p>
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText=" *Vehicle Number(max. 10 characters)"
                                            id="Vehicle Number"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ vehicle: event.target.value })
                                                    if (Settings.Vehicle(event.target.value).status) {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.Vehicle(event.target.value).errmessgae, inputname: "Vehicle" })
                                                    }
                                                },
                                                onInput: (e) => e.target.value = ("" + e.target.value).toUpperCase().toString().slice(0, 10),
                                                type: "text",
                                                value: this.props.lpr,
                                                // disabled:true,
                                                error: (this.state.flagError && this.state.inputname === "Vehicle") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.vehicle ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Vehicle" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Visitor Name(max. 20 characters)"
                                            id="Name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ visitorname: event.target.value })
                                                    if (Settings.PersonName(event.target.value).status) {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.PersonName(event.target.value).errmessgae, inputname: Settings.PersonName(event.target.value).inputname })
                                                    }
                                                },
                                                value: this.state.visitorname,
                                                type: "text",
                                                error: (this.state.flagError && this.state.inputname === "PersonName") || (this.state.requirederror && this.state.visitorname === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.visitorname ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "PersonName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    {sessionStorage.getItem("role") === "IsManager" && sessionStorage.getItem("tenant") === "true" ? < GridItem xs={12} sm={12} md={6}>
                                        <FormControl color="primary" style={{ width: "100%", height: "60%", marginTop: 28 }}>
                                            <InputLabel id="demo-simple-select-helper-label">* Tenant</InputLabel>

                                            <Select

                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.tenantid}
                                                onChange={this.handleChange}
                                                color='green'
                                                style={{ fontSize: 15 }}                            >
                                                {this.state.tenantTypeArray.map((entryCriteria, index) => (
                                                    <MenuItem value={entryCriteria.tenantid}>{entryCriteria.tenant}</MenuItem>

                                                ))}
                                            </Select>
                                            {this.state.requirederror && !this.state.tenantid ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </FormControl>
                                    </GridItem>

                                        : ""}
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Mobile Number(10 digits)"
                                            id="Mobile"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ mobile: event.target.value })
                                                    if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: "Mobile" })
                                                    }
                                                },
                                                onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                type: "number",
                                                value: this.state.mobile,
                                                error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.mobile ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* To meet(max. 25 characters)"
                                            id="tomeet"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ tomeet: event.target.value })
                                                    if (Settings.Comments(event.target.value).status) {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.Comments(event.target.value).errmessgae, inputname: "Comments" })
                                                    }
                                                },
                                                type: "text",
                                                value: this.state.tomeet,
                                                error: (this.state.flagError && this.state.inputname === "Comments") || (this.state.requirederror && this.state.tomeet === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.tomeet ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Comments" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container >
                                                <KeyboardTimePicker
                                                    style={{ marginTop: 30, color: green }}
                                                    id="time-picker"
                                                    label="* In time"
                                                    // disabled={this.state.statuschecked ? false : true}
                                                    // disableToolbar={this.state.statusid === 2 ? true :false}
                                                    value={this.state.statuschecked ? this.state.selectedDate : new Date()}
                                                    onChange={(date) => {
                                                        var dateof = new Date();
                                                        var milliseconds = dateof.getTime();
                                                        if ((milliseconds - date.getTime()) < 60000) {
                                                            this.setState({ selectedDate: date })
                                                        } else {
                                                            this.setState({ selectedDate: dateof, flagError: true, erroertext: "Invalid time selection", inputname: "Intime" })
                                                            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                                                        }
                                                    }}
                                                    disableToolbar={false}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                        {this.state.flagError && this.state.inputname === "Intime" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""} */}
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                style={{ marginTop: 30, color: green }}
                                                margin="normal"
                                                color="primary"
                                                id="date-picker-dialog"
                                                label="Visit Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.selectedDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={new Date()}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText=" Comments"
                                            id="comments"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ comments: event.target.value })

                                                },
                                                value: this.state.comments,
                                                type: "text",
                                            }}
                                        />

                                    </GridItem>
                                </GridContainer>


                                <GridContainer>


                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        {this.state.visitorname !== "" && this.state.mobile !== "" && this.state.vehicle !== "" && this.state.tomeet !== "" && this.state.vehicle !== "" ?
                                            <Button color="primary" onClick={this.add}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                            <Button onClick={() => {
                                                if (Settings.requireerrors([this.state.vehicle, this.state.flatno, this.state.mobile, this.state.fullname]).status === true) {
                                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.hostname, this.state.visitorname, this.state.companyname, this.state.vehicle]).errmessgae })
                                                    setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                }
                                            }}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                        }
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={1}>
                                        <Button color="white" onClick={() => {
                                            this.props.history.push('/visitor')
                                        }}><CancelPresentationIcon />Cancel</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>

            </div>
        );
    }
}
export default withRouter((withStyles(styles)(RegisterVisitor)));

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Search from "@material-ui/icons/Search";
import headerstyles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import CustomInput from "components/serachinput/Serachinput";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { Icon } from "@iconify/react";
import microsoftExcel from "@iconify/icons-mdi/microsoft-excel";
import TablePagination from "@material-ui/core/TablePagination";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { withRouter } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import { HOST } from "lprfolders/config";
import LoaderComponent from "lprfolders/GlobalConstant/LoaderComponent";
import Alert from "lprfolders/GlobalConstant/Alert";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Snackbar from "components/Snackbar/Snackbar.js";
import Settings from "lprfolders/GlobalConstant/Settings";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import IconButton from "@material-ui/core/IconButton";
import CropFreeIcon from "@material-ui/icons/CropFree";
import Pusher from "pusher-js";
import { TableFooter } from "@material-ui/core";
import QrReader from "react-qr-reader";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Add from "views/visitors/Add";
const previewStyle = {
  height: 300,
  width: 300,
};

const marignstyles = {
  root: {
    margin: 0,
    width: "100%",
  },
};
const theme = createMuiTheme({
  overrides: {
    MuiTableRow: {
      root: {
        //for the body
        height: "100%",
      },
      head: {
        //for the head
        height: "100%",
      },
      activeSortIcon: {
        opacity: 1,
      },
      // Half visible for inactive icons
      inactiveSortIcon: {
        display: "none",
      },
      icon: {
        display: "none",
      },
      active: {
        "& $icon": {
          display: "inline",
        },
      },
      footer: {
        "& > td > div": {
          height: 30,
          minHeight: 30,
        },
        backgroundColor: "grey",
        height: 30,
      },
    },
  },
});
const useStyles = makeStyles(styles, headerstyles, marignstyles, theme);

function CustomTable(props) {
  const initialValues = {
    lpr: "",
    entry: "",
    name: "",
    mobile: "",
    toMeet: "",
  };
  const pusherkey=window.sessionStorage.getItem("pusherkey");
  const pusherchannel=window.sessionStorage.getItem("pusherchannel");
  const pusherevent=window.sessionStorage.getItem("pusherevent");

  const token = localStorage.getItem("token");
  const classes = useStyles();
  const [TableMaindata, setData] = React.useState([]);
  const finalCount = React.useRef([]);
  const [openforstatus, setOpenForStatus] = React.useState(false);
  // const [openForShareActivityConfimr, setopenForShareActivityConfimr] = React.useState(false)
  const [openForShareActivity, setOpenForShareActivity] = React.useState(false);
  const [ShareActivityData, setShareActivityData] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [videostate, SetVideoState] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [imageopen, setImageopen] = React.useState(false);
  const [details, setDetails] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [visitorOpen, setVisitorOpen] = React.useState(false);
  const [visitorLpr, setVisitorLpr] = React.useState("");
  const [visitorEntry, setVisitorEntry] = React.useState("");
  const [visitorLprId, setVisitorLprId] = React.useState("");
  const [visitorEntryDate, setVisitorEntryDate] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [vehicle, setVehicle] = React.useState("");
  const [visitorName, setVisitorName] = React.useState("");
  const [toMeet, setToMeet] = React.useState("");
  const [visitorId, setVisiorId] = React.useState("");
  const [imagevalue, setImagevalue] = React.useState("");
  // const [imagevehiclevalue, setImagevehicleValue] = React.useState("")
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [netWorkError, setnetWorkError] = React.useState(false);
  const [erroertext, seterroertext] = React.useState("");
  const [ModalForAsk, SetModalForAsk] = React.useState("");
  const [tl, setTL] = React.useState(false);
  const [tc, setTC] = React.useState(false);
  const [tcc, setTCC] = React.useState(false);
  const [tr, setTR] = React.useState(false);
  const [bl, setBL] = React.useState(false);
  const [bc, setBC] = React.useState(false);
  const [br, setBR] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [Online, setOnline] = React.useState(window.navigator.onLine);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [headers, setHeaders] = React.useState([]);
  const { tableHead, tableHeaderColor, routename, cameracode } = props;
  const indexOfLastPost = page * rowsPerPage;
  const getVehiclesDetails = () => {
    let api;

    if (routename === "lpr") {
      setLoader(true);
      props.vehicleLatestVehcileDetails("", "", 0, 0, "", "", "", 0, true);
      api = HOST + "/" + routename + "/getall";
      axios
        .get(api, {
          headers: {
            authToken: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data !== "") {
            setLoader(false);

            setData(response.data);
          } else {
            setData([]);
            setLoader(false);
            showNotification("tcc");
            seterroertext("No records found");
          }
          if (routename === "lpr") {
            if (response.data === "") {
              props.vehicleLatestVehcileDetails(
                "",
                "",
                0,
                0,
                "",
                "",
                "",
                0,
                false,
                false
              );
            } else {
              let incount = 0;
              let OutCount = 0;
              let UnmatchedCount = 0;
              response.data.forEach((item) => {
                if (item.direction === "ENTRY") {
                  incount++;
                }
                if (item.direction !== "ENTRY") {
                  OutCount++;
                }
                if (item.db_match === false) {
                  UnmatchedCount++;
                }
              });
              props.vehicleLatestVehcileDetails(
                response.data[0].eventdate,
                response.data[0].eventtime,
                OutCount,
                UnmatchedCount,
                response.data[0].reading,
                response.data[0].direction,
                response.data[0].fullimagepath,
                incount,
                false
              );
            }
          }
        })
        .catch((err) => {
          if (err.message === "Network Error") {
            setLoader(false);
            setnetWorkError(true);
            seterroertext(Settings.netWorkError().errmessgae);
            setTimeout(() => {
              setnetWorkError(false);
            }, 3000);
          }
          if (err.response) {
            if (err.response.status === 502) {
              setLoader(false);
              setnetWorkError(true);
              seterroertext(Settings.Server().errmessgae);
              setTimeout(() => {
                setnetWorkError(false);
              }, 3000);
            }
          }
        });
    } else {
      api = HOST + "/" + routename + "/getall";
      setLoader(true);
      axios
        .get(api, {
          headers: {
            authToken: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data !== "") {
            setLoader(false);

            setData(response.data);
          } else {
            setData([]);
            setLoader(false);
            showNotification("tcc");
            seterroertext("No records found");
          }
        })
        .catch((err) => {
          if (err.message === "Network Error") {
            setLoader(false);
            setnetWorkError(true);
            seterroertext(Settings.netWorkError().errmessgae);
            setTimeout(() => {
              setnetWorkError(false);
            }, 3000);
          }
          if (err.response) {
            if (err.response.status === 502) {
              setLoader(false);
              setnetWorkError(true);
              seterroertext(Settings.Server().errmessgae);
              setTimeout(() => {
                setnetWorkError(false);
              }, 3000);
            }
          }
        });
    }
  };
  const getpuhserData = () => {
    let api;

    if (routename === "lpr") {
      setLoader(true);
      props.vehicleLatestVehcileDetails("", "", 0, 0, "", "", "", 0, true);
      api = HOST + "/" + routename + "/getall";
      axios
        .get(api, {
          headers: {
            authToken: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data !== "") {
            setLoader(false);

            setData(response.data);
          } else {
            setData([]);
            setLoader(false);
            showNotification("tcc");
            seterroertext("No records found");
          }
          if (routename === "lpr") {
            if (response.data === "") {
              props.vehicleLatestVehcileDetails(
                "",
                "",
                0,
                0,
                "",
                "",
                "",
                0,
                false,
                false
              );
            } else {
              let incount = 0;
              let OutCount = 0;
              let UnmatchedCount = 0;
              response.data.forEach((item) => {
                if (item.direction === "ENTRY") {
                  incount++;
                }
                if (item.direction !== "ENTRY") {
                  OutCount++;
                }
                if (item.db_match === false) {
                  UnmatchedCount++;
                }
              });
              if (window.sessionStorage.getItem("camera") === "true") {
                props.changeCameracode(response.data[0].cameracode);
              }
              if (response.data[0].camera === "MULTI") {
                props.vehicleLatestVehcileDetails(
                  response.data[0].eventdate,
                  response.data[0].eventtime,
                  OutCount,
                  UnmatchedCount,
                  response.data[0].reading,
                  response.data[0].direction,
                  response.data[0].fullimagepath,
                  incount,
                  false
                );
              } else {
                props.vehicleLatestVehcileDetails(
                  response.data[0].eventdate,
                  response.data[0].eventtime,
                  OutCount,
                  UnmatchedCount,
                  response.data[0].reading,
                  response.data[0].camera,
                  response.data[0].fullimagepath,
                  incount,
                  false
                );
              }
            }
          }
        })
        .catch((err) => {
          if (err.message === "Network Error") {
            setLoader(false);
            setnetWorkError(true);
            seterroertext(Settings.netWorkError().errmessgae);
            setTimeout(() => {
              setnetWorkError(false);
            }, 3000);
          }
          if (err.response) {
            if (err.response.status === 502) {
              setLoader(false);
              setnetWorkError(true);
              seterroertext(Settings.Server().errmessgae);
              setTimeout(() => {
                setnetWorkError(false);
              }, 3000);
            }
          }
        });
    }
  };

  const showNotification = (place) => {
    switch (place) {
      case "tl":
        if (!tl) {
          setTL(true);
          setTimeout(function () {
            setTL(false);
          }, 6000);
        }
        break;
      case "tc":
        if (!tc) {
          setTC(true);
          setTimeout(function () {
            setTC(false);
          }, 6000);
        }
        break;
      case "tcc":
        if (!tcc) {
          setTCC(true);
          setTimeout(function () {
            setTCC(false);
          }, 6000);
        }
        break;
      case "tr":
        if (!tr) {
          setTR(true);
          setTimeout(function () {
            setTR(false);
          }, 6000);
        }
        break;
      case "bl":
        if (!bl) {
          setBL(true);
          setTimeout(function () {
            setBL(false);
          }, 6000);
        }
        break;
      case "bc":
        if (!bc) {
          setBC(true);
          setTimeout(function () {
            setBC(false);
          }, 6000);
        }
        break;
      case "br":
        if (!br) {
          setBR(true);
          setTimeout(function () {
            setBR(false);
          }, 6000);
        }
        break;
      default:
        break;
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const export_table_to_csv = (html, filename) => {
    var csv = [];
    var cols = [];
    for (var j = 0; j < tableHead.length; j++) {
      if(tableHead[j].name == "Speed" && window.sessionStorage.getItem("speed") === "true" ){
        cols.push(tableHead[j].name);

      }
      else if (tableHead[j].name !== "Action" && tableHead[j].name !== "Visitor" && tableHead[j].name !== "Speed") {
        cols.push(tableHead[j].name);
    
      }
    }
    csv.push(cols.join(","));
    TableMaindata.forEach((x, i) => {
      var row = [];
      tableHead.forEach((y, k) => {
        // if (tableHead[y].name !== "action") {

        if (y.prop === "isactive" && x[y.prop]) {
          row.push(x[y.prop]);
        } else {
          if (y.prop === "s.no") {
            row.push(i + 1);
          }else if(y.prop === "speed"){
           if( window.sessionStorage.getItem("speed") === "true" )
            row.push(x[y.prop]);
          }else {
            row.push(x[y.prop]);
          }
        }
        // }
      });
      csv.push(row.join(","));
    });
    download_csv(csv.join("\n"), filename);
  };
  const download_csv = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const getvisitordetails = (visitorid) => {
    let api = HOST + "/visitor/get/" + visitorid;
    axios
      .get(api, {
        headers: {
          authToken: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setMobile(response.data.mobile);
        setVehicle(response.data.vehicle);
        setVisitorName(response.data.visitor);
        setToMeet(response.data.tomeet);
        setVisiorId(response.data.visitorid);

        setDetails(true);
        setLoader(false);
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          setnetWorkError(true);
          setLoader(false);
          seterroertext(Settings.netWorkError().errmessgae);
          setTimeout(() => {
            setnetWorkError(false);
          }, 3000);
        }
        if (err.response) {
          if (err.response.status === 502) {
            setnetWorkError(true);
            setLoader(false);
            seterroertext(Settings.Server().errmessgae);
            setTimeout(() => {
              setnetWorkError(false);
            }, 3000);
          }
        }
      });
  };

  const row = (x, i, headers) => (
    <>
      {headers.map((y, k) => {
        if (y.prop === "isactivity" && x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Button color="primary">Yes</Button>
            </TableCell>
          );
        } else if (y.prop === "isactivity" && !x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Button
                color="info"
                onClick={() => {
                  setOpenForShareActivity(true);
                  setShareActivityData(x);
                  SetVideoState(false);
                }}
              >
                <Tooltip title={"Scan QR to Share"} placement="right">
                  <CropFreeIcon />
                </Tooltip>
              </Button>
            </TableCell>
          );
        } else if (y.prop === "camera" && x[y.prop] === "MULTI") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              {x.direction}
            </TableCell>
          );
        } else if (y.prop === "camera") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              {x.camera}
            </TableCell>
          );
        } else if (y.prop === "db_match" && x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Tooltip title={"Match"} placement="right">
                <FiberManualRecordRoundedIcon style={{ color: "green" }} />
              </Tooltip>
            </TableCell>
          );
        } else if (
          y.prop === "speed" &&
          window.sessionStorage.getItem("speed") === "true"
        ) {
          return (
            <TableCell
              className={classes.tableCell}
              key={k}
              style={{
                color: x.speed > 30 ? "red" : x.speed>20 && x.speed<=30?"#FFBF00":"black",
              }}
            >
              {x.speed.split(".")[0]} kmph
            </TableCell>
          );
        } else if (
          y.prop === "speed" &&
          window.sessionStorage.getItem("speed") === "false"
        ) {
          return <TableCell className={classes.tableCell} key={k}></TableCell>;
        } else if (y.prop === "db_match" && !x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Tooltip title={"No Match"} placement="right">
                <FiberManualRecordRoundedIcon style={{ color: "red" }} />
              </Tooltip>
            </TableCell>
          );
        } else if (y.prop === "isactive" && x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <FiberManualRecordRoundedIcon style={{ color: "green" }} />
            </TableCell>
          );
        } else if (y.prop === "isactive" && !x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <FiberManualRecordRoundedIcon style={{ color: "red" }} />
            </TableCell>
          );
        } else if (y.prop === "fullimagepath") {
          return (
            <TableCell
              className={classes.tableCell}
              key={k}
              style={{ width: 150 }}
            >
              <>
                <Tooltip title={"Vehicle Image"} placement="bottom">
                  <VisibilityIcon
                    style={{
                      color: "#1877f2",
                      cursor: "pointer",
                      marginLeft: "34px",
                    }}
                    onClick={() => {
                      setImagevalue(x.fullimagepath);
                      setImageopen(true);
                    }}
                  />
                </Tooltip>
              </>
            </TableCell>
          );
        } else if (
          y.prop === "visitoradd" &&
          x.direction === "ENTRY" &&
          x.db_match === false
        ) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <>
                {window.sessionStorage.getItem("visitor") === "false" ? (
                  ""
                ) : x.visitorid !== null ? (
                  <Tooltip title={"Expected Visitor"} placement="bottom">
                    <PersonAddIcon
                      style={{ color: "orange", cursor: "pointer" }}
                      onClick={() => {
                        setVisitorLprId(x.lprid);

                        getvisitordetails(x.visitorid);
                      }}
                    />
                  </Tooltip>
                ) : x.statusid ? (
                  <Tooltip title={"Visitor In"} placement="bottom">
                    <PersonAddIcon
                      style={{ color: "green", cursor: "pointer" }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={"Add Visitor"} placement="bottom">
                    <PersonAddIcon
                      style={{ color: "#1877f2", cursor: "pointer" }}
                      onClick={() => {
                        setVisitorLpr(x.reading);
                        setVisitorEntry(x.eventtime);
                        setVisitorEntryDate(x.eventdate);
                        setVisitorLprId(x.lprid);
                        setVisitorOpen(true);
                      }}
                    />
                  </Tooltip>
                )}
              </>
            </TableCell>
          );
        } else if (y.prop === "s.no") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              {indexOfLastPost + (i + 1)}
            </TableCell>
          );
        } else {
          return (
            <TableCell
              className={classes.tableCell}
              key={k}
              style={{ width: 500 }}
            >
              {x[y.prop]}
            </TableCell>
          );
        }
      })}
    </>
  );
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const stableSort = (array, comparator) => {
    finalCount.current = array.map((el, index) => [el, index]);
    finalCount.current.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return finalCount.current.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const handleNetworkChange = () => {
    setOnline(window.navigator.onLine);
  };
  useEffect(() => {
    // getVehiclesDetails()
    window.addEventListener("offline", handleNetworkChange);
    window.addEventListener("online", handleNetworkChange);

    var pusher = new Pusher(pusherkey, {
      cluster: "ap2",
      encrypted: true,
    });
    var channel = pusher.subscribe(pusherchannel);
    channel.bind(pusherevent, (data) => {
      if (localStorage.getItem("companyid") === data.companyid) {
        setTimeout(() => getpuhserData(), 3000);
        // getpuhserData()
      }
    });






    var pusher = new Pusher("9be88ce55e75df211139", {
      cluster: "ap2",
      encrypted: true,
    });
    var channel = pusher.subscribe("lpr");
    channel.bind("lprdetails", (data) => {
      if (localStorage.getItem("companyid") === data.companyid) {
        setTimeout(() => getpuhserData(), 3000);
        // getpuhserData()

      }
    });

    var pusher = new Pusher("6bdb44a4fb167d61c689", {
      cluster: "ap2",
      encrypted: true,
    });
    var channel = pusher.subscribe("lpr_1");
    channel.bind("lprdetails_1", (data) => {
      if (localStorage.getItem("companyid") === data.companyid) {
        setTimeout(() => getpuhserData(), 3000);
        // getpuhserData()

      }
    });


    setHeaders(tableHead);
    if (window.sessionStorage.getItem("speed") === "false") {
      tableHead.map((header, key) => {
        if (header.name === "Speed") {
          tableHead.splice(key, 1);
        }
      });
      setHeaders(tableHead);
    }

    if (Online) {
      if (window.sessionStorage.getItem("camera") === "false") {
        setLoader(true);
        if (routename === "lpr") {
          props.vehicleLatestVehcileDetails("", "", 0, 0, "", "", "", 0, true);
        }
        let api = HOST + "/" + routename + "/getall";
        axios
          .get(api, {
            headers: {
              authToken: token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            setLoader(false);
            if (routename === "lpr") {
              if (response.data === "") {
                props.vehicleLatestVehcileDetails(
                  "",
                  "",
                  0,
                  0,
                  "",
                  "",
                  "",
                  0,
                  false
                );
              } else {
                let incount = 0;
                let OutCount = 0;
                let UnmatchedCount = 0;
                response.data.forEach((item) => {
                  if (item.direction === "ENTRY") {
                    incount++;
                  }
                  if (item.direction === "EXIT") {
                    OutCount++;
                  }
                  if (item.db_match === false) {
                    UnmatchedCount++;
                  }
                });
                if (response.data[0].camera === "MULTI") {
                  props.vehicleLatestVehcileDetails(
                    response.data[0].eventdate,
                    response.data[0].eventtime,
                    OutCount,
                    UnmatchedCount,
                    response.data[0].reading,
                    response.data[0].direction,
                    response.data[0].fullimagepath,
                    incount,
                    false
                  );
                } else {
                  props.vehicleLatestVehcileDetails(
                    response.data[0].eventdate,
                    response.data[0].eventtime,
                    OutCount,
                    UnmatchedCount,
                    response.data[0].reading,
                    response.data[0].camera,
                    response.data[0].fullimagepath,
                    incount,
                    false
                  );
                }
              }
            }
            if (response.data !== "") {
              setLoader(false);

              setData(response.data);
            } else {
              setData([]);
              setLoader(false);
              showNotification("tcc");
              seterroertext("No records found");
            }
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              setLoader(false);
              setnetWorkError(true);
              seterroertext(Settings.netWorkError().errmessgae);
              setTimeout(() => {
                setnetWorkError(false);
              }, 3000);
            }
            if (err.response) {
              if (err.response.status === 502) {
                setLoader(false);
                setnetWorkError(true);
                seterroertext(Settings.Server().errmessgae);
                setTimeout(() => {
                  setnetWorkError(false);
                }, 3000);
              }
            }
          });
      }
      if (window.sessionStorage.getItem("camera") === "true") {
        setLoader(true);
        if (routename === "lpr") {
          props.vehicleLatestVehcileDetails("", "", 0, 0, "", "", "", 0, true);
        }
        let api = HOST + "/" + routename + "/getall";
        axios
          .get(api, {
            headers: {
              authToken: token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            setLoader(false);
            if (routename === "lpr") {
              if (response.data === "") {
                props.vehicleLatestVehcileDetails(
                  "",
                  "",
                  0,
                  0,
                  "",
                  "",
                  "",
                  0,
                  false
                );
              } else {
                let incount = 0;
                let OutCount = 0;
                let UnmatchedCount = 0;
                response.data.forEach((item) => {
                  if (item.direction === "ENTRY") {
                    incount++;
                  }
                  if (item.direction === "EXIT") {
                    OutCount++;
                  }
                  if (item.db_match === false) {
                    UnmatchedCount++;
                  }
                });
                props.changeCameracode(response.data[0].cameracode);
                if (response.data[0].camera === "MULTI") {
                  props.vehicleLatestVehcileDetails(
                    response.data[0].eventdate,
                    response.data[0].eventtime,
                    OutCount,
                    UnmatchedCount,
                    response.data[0].reading,
                    response.data[0].direction,
                    response.data[0].fullimagepath,
                    incount,
                    false
                  );
                } else {
                  props.vehicleLatestVehcileDetails(
                    response.data[0].eventdate,
                    response.data[0].eventtime,
                    OutCount,
                    UnmatchedCount,
                    response.data[0].reading,
                    response.data[0].camera,
                    response.data[0].fullimagepath,
                    incount,
                    false
                  );
                }
              }
            }

            if (response.data !== "") {
              setLoader(false);

              setData(response.data);
            } else {
              setData([]);
              setLoader(false);
              showNotification("tcc");
              seterroertext("No records found");
            }
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              setLoader(false);
              setnetWorkError(true);
              seterroertext(Settings.netWorkError().errmessgae);
              setTimeout(() => {
                setnetWorkError(false);
              }, 3000);
            }
            if (err.response) {
              if (err.response.status === 502) {
                setLoader(false);
                setnetWorkError(true);
                seterroertext(Settings.Server().errmessgae);
                setTimeout(() => {
                  setnetWorkError(false);
                }, 3000);
              }
            }
          });
      }
    } else {
      setLoader(false);
      setnetWorkError(true);
      seterroertext(Settings.netWorkError().errmessgae);
      setTimeout(() => {
        setnetWorkError(false);
      }, 3000);
    }

    return () => {
      window.removeEventListener("offline", handleNetworkChange);
      window.removeEventListener("online", handleNetworkChange);
    };
  }, []);

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  const handleScan = (data) => {
    if (data !== null) {
      var jsonStrig = "[{";
      var items = data.split("\n");
      for (var i = 0; i < items.length; i++) {
        var current = items[i].split(":");
        jsonStrig += '"' + current[0] + '":"' + current[1] + '",';
      }
      jsonStrig = jsonStrig.substr(0, jsonStrig.length - 1);
      jsonStrig += "}]";
      var obj = JSON.parse(jsonStrig);
      var finalObj = Object.values(obj[0]);
      setOpenForShareActivity(false);
      SetVideoState(false);
      if (
        finalObj[1] === ShareActivityData.vehicle &&
        finalObj[2] === ShareActivityData.mobile
      ) {
        SetModalForAsk(true);
      } else {
        setLoader(false);
        showNotification("tcc");
        seterroertext("Invalid QR Code");
      }
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("camera") === "true") {
      setLoader(true);

      if (routename === "lpr") {
        props.vehicleLatestVehcileDetails("", "", 0, 0, "", "", "", 0, true);
      }
      if (cameracode !== "lprdata" && cameracode !== "") {
        let api = HOST + "/" + routename + "/cameragetall/" + cameracode;
        axios
          .get(api, {
            headers: {
              authToken: token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            setLoader(false);
            if (routename === "lpr") {
              if (response.data.length === 0) {
                props.vehicleLatestVehcileDetails(
                  "",
                  "",
                  0,
                  0,
                  "",
                  "",
                  "",
                  0,
                  false
                );
              } else {
                let incount = 0;
                let OutCount = 0;
                let UnmatchedCount = 0;
                response.data.forEach((item) => {
                  if (item.direction === "ENTRY") {
                    incount++;
                  }
                  if (item.direction === "EXIT") {
                    OutCount++;
                  }
                  if (item.db_match === false) {
                    UnmatchedCount++;
                  }
                });

                if (response.data[0].camera === "MULTI") {
                  props.vehicleLatestVehcileDetails(
                    response.data[0].eventdate,
                    response.data[0].eventtime,
                    OutCount,
                    UnmatchedCount,
                    response.data[0].reading,
                    response.data[0].direction,
                    response.data[0].fullimagepath,
                    incount,
                    false
                  );
                } else {
                  props.vehicleLatestVehcileDetails(
                    response.data[0].eventdate,
                    response.data[0].eventtime,
                    OutCount,
                    UnmatchedCount,
                    response.data[0].reading,
                    response.data[0].camera,
                    response.data[0].fullimagepath,
                    incount,
                    false
                  );
                }
              }
            }

            if (response.data.length !== 0) {
              setLoader(false);

              setData(response.data);
            } else {
              setData([]);
              setLoader(false);
              showNotification("tcc");
              seterroertext("No records found");
            }
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              setLoader(false);
              setnetWorkError(true);
              seterroertext(Settings.netWorkError().errmessgae);
              setTimeout(() => {
                setnetWorkError(false);
              }, 3000);
            }
            if (err.response) {
              if (err.response.status === 502) {
                setLoader(false);
                setnetWorkError(true);
                seterroertext(Settings.Server().errmessgae);
                setTimeout(() => {
                  setnetWorkError(false);
                }, 3000);
              }
            }
          });
      }
      if (cameracode === "lprdata") {
        setLoader(true);
        if (routename === "lpr") {
          props.vehicleLatestVehcileDetails("", "", 0, 0, "", "", "", 0, true);
        }
        let api = HOST + "/" + routename + "/getall";
        axios
          .get(api, {
            headers: {
              authToken: token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            setLoader(false);
            if (routename === "lpr") {
              if (response.data === "") {
                props.vehicleLatestVehcileDetails(
                  "",
                  "",
                  0,
                  0,
                  "",
                  "",
                  "",
                  0,
                  false
                );
              } else {
                let incount = 0;
                let OutCount = 0;
                let UnmatchedCount = 0;
                response.data.forEach((item) => {
                  if (item.direction === "ENTRY") {
                    incount++;
                  }
                  if (item.direction === "EXIT") {
                    OutCount++;
                  }
                  if (item.db_match === false) {
                    UnmatchedCount++;
                  }
                });

                if (response.data[0].camera === "MULTI") {
                  props.vehicleLatestVehcileDetails(
                    response.data[0].eventdate,
                    response.data[0].eventtime,
                    OutCount,
                    UnmatchedCount,
                    response.data[0].reading,
                    response.data[0].direction,
                    response.data[0].fullimagepath,
                    incount,
                    false
                  );
                } else {
                  props.vehicleLatestVehcileDetails(
                    response.data[0].eventdate,
                    response.data[0].eventtime,
                    OutCount,
                    UnmatchedCount,
                    response.data[0].reading,
                    response.data[0].camera,
                    response.data[0].fullimagepath,
                    incount,
                    false
                  );
                }
              }
            }

            if (response.data !== "") {
              setLoader(false);
              setData(response.data);
            } else {
              setData([]);
              setLoader(false);
              showNotification("tcc");
              seterroertext("No records found");
            }
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              setLoader(false);
              setnetWorkError(true);
              seterroertext(Settings.netWorkError().errmessgae);
              setTimeout(() => {
                setnetWorkError(false);
              }, 3000);
            }
            if (err.response) {
              if (err.response.status === 502) {
                setLoader(false);
                setnetWorkError(true);
                seterroertext(Settings.Server().errmessgae);
                setTimeout(() => {
                  setnetWorkError(false);
                }, 3000);
              }
            }
          });
      }
    }
  }, [cameracode]);

  const UpdateStatus = (visitorId, visitorLprId) => {
    if (Online) {
      let statusid = 2;
      let routename = "visitor";

      let body = {
        visitorid: visitorId,
        lprid: visitorLprId,
        statusid: statusid,
      };
      setOpenForStatus(false);
      axios
        .put(HOST + "/" + routename + "/updatestatus/", body, {
          headers: {
            authToken: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          showNotification("tc");
          seterroertext(
            String(routename).charAt(0).toUpperCase() +
              String(routename).slice(1) +
              " status " +
              Alert.upadateSuccess
          );
          window.location.reload();
        })
        .catch((err) => {
          if (err.message === "Network Error") {
            setLoader(false);
            setnetWorkError(true);
            seterroertext(Settings.netWorkError().errmessgae);
            setTimeout(() => {
              setnetWorkError(false);
            }, 3000);
          }
          if (err.response) {
            if (err.response.status === 502) {
              setLoader(false);
              setnetWorkError(true);
              seterroertext(Settings.Server().errmessgae);
              setTimeout(() => {
                setnetWorkError(false);
              }, 3000);
            }
          }
        });
    } else {
      setLoader(false);
      setnetWorkError(true);
      seterroertext(Settings.netWorkError().errmessgae);
      setTimeout(() => {
        setnetWorkError(false);
      }, 3000);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };
  return (
    <div>
      {tcc ? (
        <SnackbarContent
          icon={InfoIcon}
          message={erroertext}
          // close
          color="danger"
        />
      ) : (
        ""
      )}
      <div className={classes.tableResponsive}>
        {tc ? (
          <Snackbar
            place="tc"
            color="success"
            icon={ThumbUpIcon}
            message={erroertext}
            open={tc}
            closeNotification={() => setTC(false)}
            close
          />
        ) : (
          ""
        )}
        <Dialog
          // fullScreen={fullScreen}
          open={imageopen}
          onClose={() => setImageopen(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            onClose={() => setImageopen(false)}
          >
            {""}
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={() => setImageopen(false)}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <img
                  src={imagevalue}
                  alt="License veihcle"
                  style={{ width: "100%" }}
                  onError={(e) => {
                    e.target.src =
                      "https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/noimage.png";
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        <Dialog
          // fullScreen={fullScreen}
          fullWidth="md"
          maxWidth="md"
          open={visitorOpen}
          onClose={() => setVisitorOpen(false)}
          aria-labelledby="responsive-dialog-title"
          style={{ height: "100%" }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            onClose={() => setVisitorOpen(false)}
          >
            {""}
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={() => setVisitorOpen(false)}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ height: "100%" }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Add
                  lpr={visitorLpr}
                  entryTime={visitorEntry}
                  lprId={visitorLprId}
                  entryDate={visitorEntryDate}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        {/* visitor details */}
        <Dialog
          // fullScreen={fullScreen}
          open={details}
          onClose={() => setDetails(false)}
          aria-labelledby="responsive-dialog-title"
          fullWidth
          style={{ height: "100%" }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            onClose={() => setDetails(false)}
          >
            {""}
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={() => setDetails(false)}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ height: "100%" }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}></GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {netWorkError ? (
                    <SnackbarContent
                      icon={InfoIcon}
                      message={erroertext}
                      // close
                      color="danger"
                    />
                  ) : (
                    ""
                  )}
                  {loader ? <LoaderComponent /> : ""}

                  <CardHeader color="primary" style={{ height: 20 }}>
                    <h4
                      className={classes.cardTitleWhite}
                      style={{ marginTop: -5 }}
                    >
                      {Settings.titleCase("Visitor record")}
                    </h4>
                    {/* <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Details pertaining to the visitor")}</p> */}
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: -20 }}
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <h6
                          style={{
                            borderBottom: "0.8px ridge",
                            fontWeight: "500",
                          }}
                          className={classes.cardCategory}
                        >
                          Vehicle Number :
                        </h6>
                        <h4
                          style={{ marginTop: -22, fontSize: 18 }}
                          className={classes.description}
                        >
                          {vehicle}
                        </h4>
                      </GridItem>
                      <GridItem
                        style={{ marginTop: -20 }}
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <h6
                          style={{
                            borderBottom: "0.8px ridge",
                            fontWeight: "500",
                          }}
                          className={classes.cardCategory}
                        >
                          Visitor Name :
                        </h6>
                        <h4
                          style={{ marginTop: -22, fontSize: 18 }}
                          className={classes.description}
                        >
                          {visitorName}
                        </h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: -20 }}
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <h6
                          style={{
                            borderBottom: "0.8px ridge",
                            fontWeight: "500",
                          }}
                          className={classes.cardCategory}
                        >
                          Mobile :
                        </h6>
                        <h4
                          style={{ marginTop: -22, fontSize: 18 }}
                          className={classes.description}
                        >
                          {mobile}
                        </h4>
                      </GridItem>
                      <GridItem
                        style={{ marginTop: -20 }}
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <h6
                          style={{
                            borderBottom: "0.8px ridge",
                            fontWeight: "500",
                          }}
                          className={classes.cardCategory}
                        >
                          To meet
                        </h6>
                        <h4
                          style={{ marginTop: -22, fontSize: 18 }}
                          className={classes.description}
                        >
                          {toMeet}
                        </h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        style={{ marginTop: -20 }}
                        xs={12}
                        sm={12}
                        md={6}
                      ></GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={5}
                        style={{ marginTop: -20 }}
                      >
                        <Button
                          color="primary"
                          onClick={() => {
                            UpdateStatus(visitorId, visitorLprId);
                            setDetails(false);
                          }}
                        >
                          {" "}
                          Check In
                          <span
                            style={{ display: "inline-block", width: "100%" }}
                          ></span>
                        </Button>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}></GridItem>
                    </GridContainer>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        <Dialog
          // fullScreen={fullScreen}
          open={openForShareActivity}
          onClose={() => {
            SetVideoState(true);
            setOpenForShareActivity(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            onClose={() => {
              SetVideoState(true);
              setOpenForShareActivity(false);
            }}
          >
            {"Scan QR to Share Activity"}
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={() => {
                setOpenForShareActivity(false);
                SetVideoState(true);
              }}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div style={{ overflow: "hidden" }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={previewStyle}
                    legacyMode={videostate}
                    // ref={(stream)=> {video.current = stream}}
                    facingMode="environment"
                  />
                </GridItem>
              </GridContainer>
            </div>
          </DialogContent>
        </Dialog>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5} lg={4}>
            <CustomInput
              formControlProps={{
                className: classes.root,
              }}
              inputProps={{
                placeholder: "Search",
                onChange: (e) => {
                  setFilterText(e.target.value);
                },
              }}
            />
            <Button
              color="primary"
              aria-label="edit"
              justIcon
              round
              onClick={() => {
                const filteritems = TableMaindata.filter((x) => {
                  const values = headers.filter((y, k) => {
                    return String(x[y.prop])
                      .toLocaleLowerCase()
                      .includes(filterText.toLocaleLowerCase())
                      .trim();
                  });
                  if (values.length > 0) {
                    return true;
                  } else {
                    return false;
                  }
                });
                setData(filteritems);
              }}
            >
              <Tooltip title={"Search"} placement="right">
                <Search />
              </Tooltip>
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={5} lg={3}></GridItem>
          <GridItem xs={12} sm={12} md={2} lg={2}>
            {/* {window.sessionStorage.getItem('tenant') === "true" && window.localStorage.getItem('role') !== "IsTenant" && routename === "vehicle" ?
              vehicleIds.length !== 0 ? <Button color="primary" onClick={() => updateTenantApproval(vehicleIds)} >Vehicles Approval</Button> : <Button>Vehicles Approval</Button> : ""} */}
          </GridItem>
          <GridItem xs={12} sm={12} md={2} lg={2}>
            <Button
              color="info"
              onClick={() => {
                var html = document.querySelector("table").outerHTML;
                export_table_to_csv(
                  html,
                  "Vehicle Activity on " +
                    moment(new Date()).format("DD-MM-YYYY") +
                    ".csv"
                );
              }}
            >
              <Icon icon={microsoftExcel} />
              Export Excel
            </Button>
          </GridItem>
        </GridContainer>
        {loader ? <LoaderComponent /> : ""}

        <Table className={classes.table}>
          {headers !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow} aria-checked={checked}>
                {headers.map((prop, key) => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      style={{ width: 300 }}
                      key={key}
                    >
                      <TableSortLabel
                        IconComponent={ArrowUpwardIcon}
                        active={orderBy === prop.prop}
                        hideSortIcon={
                          prop.name !== "S.No" &&
                          prop.name !== "Vehicle Image" &&
                          prop.name !== "Speed" &&
                          prop.name !== "Action" &&
                          prop.name !== "Share Activity" &&
                          prop.name !== "Comments" &&
                          prop.name !== "Visitor" &&
                          prop.name !== "Vehicle Approval" &&
                          prop.name !== ""
                            ? false
                            : true
                        }
                        direction={orderBy === prop.prop ? order : "asc"}
                        onClick={() => {
                          if (
                            prop.name !== "S.No" &&
                            prop.name !== "Speed" &&
                            prop.name !== "Vehicle Image" &&
                            prop.name !== "Action" &&
                            prop.name !== "Share Activity" &&
                            prop.name !== "Comments" &&
                            prop.name !== "Visitor" &&
                            prop.name !== "Vehicle Approval"
                          ) {
                            handleRequestSort(prop.prop);
                          }
                        }}
                      >
                        {window.sessionStorage.getItem("visitor") === "false"
                          ? prop.name === "Visitor"
                            ? (prop.name = "")
                            : prop.name
                          : prop.name}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}

          <TableBody>
            {netWorkError ? (
              <SnackbarContent
                icon={InfoIcon}
                message={erroertext}
                // close
                color="danger"
              />
            ) : (
              ""
            )}
            {TableMaindata.length !== 0 &&
              stableSort(
                filterText
                  ? TableMaindata.filter((x) => {
                      const values = headers.filter((y, k) => {
                        if (typeof filterText !== "number") {
                          return String(x[y.prop])
                            .replace(/\s+/g, "")
                            .toLocaleLowerCase()
                            .includes(
                              String(
                                filterText.replace(/\s+/g, "")
                              ).toLocaleLowerCase()
                            );
                        } else {
                          return String(x[y.prop])
                            .replace(/\s+/g, "")
                            .includes(String(filterText.replace(/\s+/g, "")));
                        }
                      });
                      if (values.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    })
                  : TableMaindata,
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((prop, key) => {
                  return (
                    <TableRow key={key} className={classes.tableBodyRow}>
                      {row(prop, key, headers)}
                    </TableRow>
                  );
                })}
          </TableBody>
          <caption
            style={{
              position: "relative",
              top: "-53px",
              bottom: 0,
              left: "-13px",
              color: "#000",
            }}
          >
            {" "}
            Total :{" "}
            {filterText ? finalCount.current.length : TableMaindata.length}
          </caption>
          <TableFooter className={classes.footer}>
            <TableRow className={classes.footer}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={"Records Per Page"}
                count={
                  filterText ? finalCount.current.length : TableMaindata.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};
// EnhancedTableHead.propTypes = {
//   classes: PropTypes.object.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };
CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
export default withRouter(CustomTable);

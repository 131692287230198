import React, { useEffect } from "react";
import PropTypes, { element } from "prop-types";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Search from "@material-ui/icons/Search";
import headerstyles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
// core components
import CustomInput from "components/serachinput/Serachinput";
import Button from "components/CustomButtons/Button.js";
// npm install --save-dev @iconify/react @iconify/icons-mdi
import { Icon } from "@iconify/react";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import microsoftExcel from "@iconify/icons-mdi/microsoft-excel";
import TablePagination from "@material-ui/core/TablePagination";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import { withRouter } from "react-router-dom";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FileSaver from "file-saver";
import JSZip from "jszip";
import AWS from "aws-sdk";
import JSZipUtils from "jszip-utils";
import { HOST } from "../lprfolders/config";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import { updateBreak } from "typescript";
import axios from "axios";
import Tenant from "./Tenant";
import moment from "moment";
import { assign } from "lodash";
import InfoIcon from "@material-ui/icons/Info";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import LoaderComponent from "lprfolders/GlobalConstant/LoaderComponent";

// import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
const marignstyles = {
  root: {
    margin: 0,
    width: "100%",
  },
};

const useStyles = makeStyles(styles, headerstyles, marignstyles);
const S3_BUCKET = "gaidos3";
const REGION = "us-east-2";
const bucketname = "https://s3.us-east-2.amazonaws.com/gaidos3";

AWS.config.update({
  accessKeyId: "AKIARF7XYXWLFW5DVLKR",
  secretAccessKey: "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",
});
function CustomTable(props) {
  const classes = useStyles();
  const token = localStorage.getItem("token");

  const [TableMaindata, setData] = React.useState([]);
  const [TableMaindata1, setData1] = React.useState([]);
  const [lprData, setLprData] = React.useState([]);

  const [tenantData, setTenantData] = React.useState([]);
  const [databaseVehicle, setDatabaseVehicle] = React.useState([]);
  const [netWorkError, setnetWorkError] = React.useState(false);

  const [loader, setLoader] = React.useState(false);
  const [erroertext, seterroertext] = React.useState("");
  const finalCount = React.useRef([]);
  const [filterText, setFilterText] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { tableData, tableHeaderColor, datefro, dateto, routename } = props;
  const [order, setOrder] = React.useState("asc");
  const [tableHead, setTableHead] = React.useState(props.tableHead);
  const [orderBy, setOrderBy] = React.useState("");
  const [imagevalue, setImagevalue] = React.useState("");
  const [imageopen, setImageopen] = React.useState(false);
  const [reportid, setReportid] = React.useState(1);
  const reportTypes = [
    { reportid: 1, reportType: "Lpr Report" },
    { reportid: 2, reportType: "Tenant Report" },
    { reportid: 3, reportType: "Tenant Count" },
  ];

  const indexOfLastPost = page * rowsPerPage;
  const [selectedValue, setSelectedValue] = React.useState("");
  const [tenant, setTenant] = React.useState("");
  const [cameras, setCameras] = React.useState([]);
  const [maxcarcountid, setMaxCarCountid] = React.useState();
  const [maxbikecountid, setMaxBikeCountid] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [entryImage, setEntryImage] = React.useState("");

  const [exitImage, setExitImage] = React.useState("");

  const handleVisibilityClick = (event,x) => {
    setAnchorEl(event.currentTarget);
    setEntryImage(x.fullimagepath);
    setExitImage(x.exitfullimagepath);
  };
  // const indexOfLastPost = page * rowsPerPage;
  useEffect(() => {
    if (window.sessionStorage.getItem("speed") === "false") {
      tableHead.map((header, key) => {
        if (header.name === "Speed") {
          tableHead.splice(key, 1);
        }
      });
      setTableHead(tableHead);
    }

    setData(tableData);
    setData1(tableData);
    setLprData(tableData);
  }, [tableData]);

  useEffect(() => {
    let api1 = HOST + "/camera/getall";
    axios
      .get(api1, {
        headers: {
          authToken: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        response.data.forEach((camera) => {
          if (camera.maingate === 1) {
            cameras.push(camera);
          }
        });
        setCameras(cameras);
      });

    if (routename === "lpr") {
      let apis = HOST + "/tenant/getall";
      axios
        .get(apis, {
          headers: {
            authToken: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.length !== 0) {
            setTenantData(response.data);
            setTenant(response.data[0].tenant);
          } // // setCompanyarry(array)
        });

      let api1 = HOST + "/vehicle/getall";
      axios
        .get(api1, {
          headers: {
            authToken: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setDatabaseVehicle(response.data);
          // // setCompanyarry(array)
        });
    }
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  const stableSort = (array, comparator) => {
    finalCount.current = array.map((el, index) => [el, index]);
    finalCount.current.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return finalCount.current.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const export_table_to_csv = (html, filename) => {
    var csv = [];
    var cols = [];
    for (var j = 0; j < tableHead.length; j++) {
      cols.push(tableHead[j].name);
    }
    csv.push(cols.join(","));
    TableMaindata.forEach((x, i) => {
      var row = [];
      tableHead.forEach((y, k) => {
        if (y.prop === "isactive" && x[y.prop]) {
          row.push(x[y.prop]);
        } else if (y.prop === "fullimagepath") {
          row.push(x[y.prop]);
        }
        else if  (y.prop === "camera" && x[y.prop] === "MULTI") {
          row.push(x.direction);
        }  else {
          if (y.prop === "s.no") {
            row.push(i + 1);
          } else {
            row.push(x[y.prop]);
          }
        }
      });
      csv.push(row.join(","));
    });
     download_csv(csv.join("\n"), filename);
  };
  const download_csv = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };
  const row = (x, i, headers) => (
    <>
      {headers.map((y, k) => {
        if (y.prop === "db_match" && x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <FiberManualRecordRoundedIcon style={{ color: "green" }} />
            </TableCell>
          );
        } else if (y.prop === "db_match" && !x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <FiberManualRecordRoundedIcon style={{ color: "red" }} />
            </TableCell>
          );
        } else if (y.prop === "fullimagepath") {
          return (
            <TableCell
              className={classes.tableCell}
              key={k}
              style={{
                backgroundColor:
                  maxbikecountid === indexOfLastPost + (i + 1) ||
                  maxcarcountid === indexOfLastPost + (i + 1)
                    ? "red"
                    : "#fff",
                color:
                  maxbikecountid === indexOfLastPost + (i + 1) ||
                  maxcarcountid === indexOfLastPost + (i + 1)
                    ? "#fff"
                    : "#000",
              }}
            >
              <>
                <Tooltip title={"Vehicle Image"} placement="bottom">
                  <VisibilityIcon
                    style={{
                      color:
                        maxbikecountid === indexOfLastPost + (i + 1) ||
                        maxcarcountid === indexOfLastPost + (i + 1)
                          ? "#fff"
                          : "#1877f2",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setImagevalue(x.fullimagepath);
                      setImageopen(true);
                    }}
                  />
                </Tooltip>
              </>
            </TableCell>
          );
        } 
        else if (y.prop === "camera" && x[y.prop] === "MULTI") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              {x.direction}
            </TableCell>
          );
        } else if (y.prop === "camera") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              {x.camera}
            </TableCell>
          );
        } else if (y.prop === "images" &&  x[y.prop] !== null) {
          return (
            <TableCell
            className={classes.tableCell}
            key={k}
            style={{ width: 150 }}
            >
             
                  <VisibilityIcon
                   key={k}

                     style={{
                      color: "#1877f2",
                      cursor: "pointer",
                      marginLeft: "34px",
                    }}
                    onClick={(event)=>handleVisibilityClick(event,x)}
                  />
                  <Menu
                    key={k}

                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >

                    <MenuItem
                      key={k}
                      onClick={() => {
                        setImagevalue(entryImage);
                        setImageopen(true);
                      }}
                    >
                      Entry
                    </MenuItem>
                    <MenuItem
                      key={k}

                      onClick={() => {
                        setImagevalue(exitImage);
                        setImageopen(true);
                      }}
                    >
                      Exit
                    </MenuItem>
                  </Menu>
          
            </TableCell>
          );
        } else if (y.prop === "date" && x[y.prop] !== null) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              {x.date.split("T")[0]}
            </TableCell>
          );
        } else if (y.prop === "halttime" && x[y.prop] === null) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              Over Time
            </TableCell>
          );
        } else if (y.prop === "halttime" && x[y.prop] !== null) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              {x.halttime.split(":")[0] +
                "hrs" +
                " " +
                x.halttime.split(":")[1] +
                "mins"}
            </TableCell>
          );
        } else if (y.prop === "intime" && x[y.prop] === null) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              --
            </TableCell>
          );
        } else if (y.prop === "outtime" && x[y.prop] === null) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              --
            </TableCell>
          );
        } else if (y.prop === "s.no") {
          return (
            <TableCell
              className={classes.tableCell}
              key={k}
              style={{
                backgroundColor:
                  maxbikecountid === indexOfLastPost + (i + 1) ||
                  maxcarcountid === indexOfLastPost + (i + 1)
                    ? "red"
                    : "#fff",
                color:
                  maxbikecountid === indexOfLastPost + (i + 1) ||
                  maxcarcountid === indexOfLastPost + (i + 1)
                    ? "#fff"
                    : "#000",
              }}
            >
              {maxcarcountid === indexOfLastPost + (i + 1)}

              {indexOfLastPost + (i + 1)}
            </TableCell>
          );
        } else if (
          y.prop === "speed" &&
          window.sessionStorage.getItem("speed") === "true"
        ) {
          return (
            <TableCell
              className={classes.tableCell}
              key={k}
              style={{
                color:
                  x.speed > 30
                    ? "red"
                    : x.speed > 20 && x.speed <= 30
                    ? "#FFBF00"
                    : "black",
              }}
            >
              {x.speed.split(".")[0]} kmph
            </TableCell>
          );
        } else if (
          y.prop === "speed" &&
          window.sessionStorage.getItem("speed") === "false"
        ) {
          return <TableCell className={classes.tableCell} key={k}></TableCell>;
        } else {
          return (
            <TableCell
              className={classes.tableCell}
              key={k}
              style={{
                backgroundColor:
                  maxbikecountid === indexOfLastPost + (i + 1) ||
                  maxcarcountid === indexOfLastPost + (i + 1)
                    ? "red"
                    : "#fff",
                color:
                  maxbikecountid === indexOfLastPost + (i + 1) ||
                  maxcarcountid === indexOfLastPost + (i + 1)
                    ? "#fff"
                    : "#000",
              }}
            >
              {x[y.prop]}
            </TableCell>
          );
        }
      })}
    </>
  );

  const deleteJSON = () => {
    const s3 = new AWS.S3({ apiVersion: "2006-03-01" });

    tableData.forEach(function (data, i) {
      var url = data.fullimagepath;
      url = url.replace(/_0.jpg/g, ".json");
      url = url.replace("https://s3.us-east-2.amazonaws.com/gaidos3/", "");

      var params1 = { Bucket: S3_BUCKET, Key: url };

      s3.deleteObject(params1, function (err, data) {
        if (err) console.log(err, err.stack);
        // error
        else console.log("deleted object"); // deleted
      });
    });
  };

  const fileDownloadHandler = () => {
    var zip = new JSZip();
    var count = 0;
    const images = [];
    tableData.map((image, i) => {
      images.push({ img: image.fullimagepath, vehiclenumber: image.reading });
    });

    images.map((url, i) => {
      var filename = url.img;
      filename = filename.toString().split("/");
      var fname = filename[4];
      var zipFilename =
        fname + "VehicleActivityImagesfrom" + datefro + "to" + dateto + ".zip";
      // var filemname2=filename[3].toString().split("-")
      // console.log(filemname2[1].jpg)
      var name = url.vehiclenumber + "_" + filename[6] + "_" + filename[8];
      JSZipUtils.getBinaryContent(url.img, function (err, data) {
        // if (err) {
        //     throw err;
        // }
        zip.file(name, data, { binary: true });
        count++;
        if (count == images.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, zipFilename);
          });
        }
      });
      // let blob = fetch(url.fullimagepath).then(r => r.blob());
      // zip.file(name, blob, { binary: true })
      // count++;
      // if (count == tableData.length) {
      //     zip.generateAsync({ type: 'blob' }).then(function (content) {
      //         FileSaver.saveAs(content, zipFilename);
      //     });
      // }
    });
  };
  const parkedVehicleList = (vehicleData) => {
    // setData(TableMaindata.filter(row => row.direction === 'ENTRY'))
    // var entryData = [];
    // var exitData = [];
    // var data = [];
    // var VehicleData = vehicleData

    // VehicleData.forEach(row => {
    //     if (row.direction === 'ENTRY') {
    //         entryData.push(row);

    //     } else {
    //         exitData.push(row);
    //     }
    // });

    // for (let index = 0; index < entryData.length; index++) {
    //     let count = 0;
    //     const element = entryData[index];

    //     for (let i = 0; i < exitData.length; i++) {
    //         const exitElement = exitData[i];
    //         if (exitElement.reading === element.reading) {
    //             //console.log(element.reading +" "+exitElement.reading);

    //             count++
    //             exitData.splice(i, 1);
    //             // console.log("yes")

    //         } else {
    //             //console.log(element.reading + " " + exitElement.reading);
    //             // console.log(element.reading + " " + exitElement.reading);
    //         }
    //     }
    //     if (count === 0) {
    //         data.push(element)
    //     } else if (count >= 1) {
    //         entryData.splice(index, 1);
    //     }

    // }
    let headers = [
      { name: "Match", prop: "db_match" },
      { name: "Vehicle Number", prop: "reading" },
      { name: "Date", prop: "eventdate" },
      { name: "Direction", prop: "direction" },
      { name: "In Time", prop: "intime" },
      { name: "Out Time", prop: "outtime" },
      { name: "Halt Time", prop: "halttime" },
      { name: "Vehicle Image", prop: "fullimagepath" },
    ];

    setTableHead(headers);
    let body = {
      datefrom: datefro,
      dateto: dateto,
    };
    setLoader(true);

    let api = HOST + "/report/parkingreport";
    axios
      .post(api, body, {
        headers: {
          authToken: token,
          Accept: "application/json",
          // 'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        // let data = [];
        // response.data.map((vehicle) => {
        //     if (vehicle.outtime === null) {
        //         data.push(vehicle)
        //     }
        // })
        // console.log(data)

        if (response.data !== "") {
          let data = [];
          response.data.map((vehicle) => {
            if (vehicle.outtime === null) {
              data.push(vehicle);
            }
          });
          setLoader(false);
          setData(data);
          setLprData(data);
        } else {
          setData([]);
          setLoader(false);
          // showNotification("tcc")
          seterroertext("No records found");
        }
      });
  };

  const tenantCount = (tenantname) => {
    let data = [];
    let RegisteredVehicle = [];
    let vehicleDetails = [];
    let headers = [
      { name: "S.no", prop: "s.no" },
      // { name: 'id', prop: "id" },
      { name: "Tenant", prop: "tenant" },
      { name: "Vehicle", prop: "reading" },
      { name: "Direction", prop: "camera" },
      { name: "Time", prop: "eventtime" },
      { name: "Cars In/Out", prop: "carcount" },
      { name: "Car Parking Usage", prop: "carusage" },
      //  { name: 'Max CarCount', prop: 'maxcarcount' },
      { name: "Bike In/Out", prop: "bikecount" },
      { name: "Bike Parking Usage", prop: "bikeusage" },
      //  { name: 'Max Bike Count', prop: "maxbikecount" },
      { name: "Vehicle Image", prop: "fullimagepath" },
    ];
    setTableHead(headers);
    let count;

    TableMaindata1.forEach((item) => {
      if (item.db_match === true) {
        RegisteredVehicle.push(item);
      }
    });
    databaseVehicle.map((tenant) => {
      RegisteredVehicle.map((vehicle) => {
        if (vehicle.reading.toLowerCase() === tenant.vehicle.toLowerCase()) {
          Object.assign(vehicle, { tenant: tenant.tenant });
          vehicleDetails.push(vehicle);
        }
      });
    });

    // tenantData.map((tenant) => {
    //     count = 0;
    //     vehicleDetails.map((vehicle) => {
    //         if (tenant.firstname.toLowerCase() === vehicle.tenant.toLowerCase()) {
    //             count++
    //         }
    //     })
    //     data.push({ "tenant": tenant.tenant, "count": count })
    // })
    var bikecount = 0;
    var carcount = 0;
    var bikemaxcount = 0;
    var carmaxcount = 0;
    var carparkingcount = 0;
    var bikeparkingcount = 0;
    var count1 = 0;
    var id = 0;
    var maxcarcountid = 0;
    var maxbikecountid = 0;

    vehicleDetails.map((vehicles) => {
      var obj = [];
      // && vehicles.cameracode==='1682344953259'
      cameras.forEach((cameras) => {
        if (
          vehicles.tenant === tenantname &&
          vehicles.cameracode === cameras.cameracode
        ) {
          count1++;

          if (vehicles.vehicle_category === "MOTORCYCLE") {
            if (vehicles.direction === "ENTRY") {
              id++;
              bikecount++;
              bikeparkingcount = bikeparkingcount + 1;
              if (bikecount >= bikemaxcount) {
                bikemaxcount = bikecount;
              }
              if (bikeparkingcount === bikemaxcount) {
                maxbikecountid = id;
              }
              Object.assign(
                vehicles,
                { id: id },
                { tenant: vehicles.tenant },
                { bikecount: 1 },
                { bikeusage: bikeparkingcount },
                { maxbikecount: bikemaxcount },
                { carcount: 0 },
                { carusage: 0 },
                { maxcarcount: 0 }
              );
              data.push(vehicles);
            } else {
              bikecount--;
              id++;
              bikeparkingcount = bikeparkingcount - 1;
              if (bikecount >= bikemaxcount) {
                bikemaxcount = bikecount;
              }
              if (bikeparkingcount === bikemaxcount) {
                maxcarcountid = id;
              }
              Object.assign(
                vehicles,
                { id: id },
                { tenant: vehicles.tenant },
                { bikecount: -1 },
                { bikeusage: bikeparkingcount },
                { maxbikecount: bikemaxcount },
                { carcount: 0 },
                { carusage: 0 },
                { maxcarcount: 0 }
              );
              data.push(vehicles);
            }
          } else {
            if (vehicles.direction === "ENTRY") {
              carcount++;
              id++;
              carparkingcount = carparkingcount + 1;
              if (carcount >= carmaxcount) {
                carmaxcount = carcount;
              }
              if (carparkingcount === carmaxcount) {
                maxcarcountid = id;
              }
              Object.assign(
                vehicles,
                { id: id },
                { tenant: vehicles.tenant },
                { carcount: 1 },
                { carusage: carparkingcount },
                { maxcarcount: carmaxcount },
                { bikecount: 0 },
                { bikeusage: 0 },
                { maxbikecount: 0 }
              );
              data.push(vehicles);
            } else {
              carcount--;
              carparkingcount = carparkingcount - 1;
              id++;
              if (carcount >= carmaxcount) {
                carmaxcount = carcount;
              }
              if (carparkingcount === carmaxcount) {
                maxcarcountid = id;
              }
              Object.assign(
                vehicles,
                { id: id },
                { tenant: vehicles.tenant },
                { carcount: -1 },
                { carusage: carparkingcount },
                { maxcarcount: carmaxcount },
                { bikecount: 0 },
                { bikeusage: 0 },
                { maxbikecount: 0 }
              );
              data.push(vehicles);
            }
          }
        }
      });
    });
    setMaxBikeCountid(maxbikecountid);
    setMaxCarCountid(maxcarcountid);

    setData(data);
    setLprData(data);
  };
  const tenantDetails = () => {
    let vehicleDetails = [];
    let RegisteredVehicle = [];
    let headers = [
      { name: "Match", prop: "db_match" },

      { name: "Tenant User", prop: "tenant" },

      { name: "Vehicle Number", prop: "reading" },

      { name: "Date", prop: "eventdate" },

      { name: "Time", prop: "eventtime" },

      { name: "Direction", prop: "camera" },

      { name: "Vehicle Image", prop: "fullimagepath" },
    ];
    setTableHead(headers);
    TableMaindata1.forEach((item) => {
      if (item.db_match === true) {
        RegisteredVehicle.push(item);
      }
    });
    databaseVehicle.map((tenant) => {
      RegisteredVehicle.map((vehicle) => {
        if (vehicle.reading.toLowerCase() === tenant.vehicle.toLowerCase()) {
          Object.assign(vehicle, { tenant: tenant.tenant });
          vehicleDetails.push(vehicle);
        }
      });
    });
    setData(vehicleDetails);
    setLprData(vehicleDetails);
  };
  const inAndTallyVehicle = (vehicleData) => {
    // setData(TableMaindata.filter(row => row.direction === 'ENTRY'))
    var VehicleData = [];
    vehicleData.map((element) => {
      VehicleData.push(element);
    });
    var entryData = [];
    var exitData = [];
    var data = [];
    VehicleData.forEach((row) => {
      if (row.direction === "ENTRY") {
        entryData.push(row);
      } else {
        exitData.push(row);
      }
    });
    const exitvehiclesdata = exitData;

    let headers = [
      { name: "Match", prop: "db_match" },
      { name: "Vehicle Number", prop: "reading" },
      { name: "Date", prop: "eventdate" },
      // { name: "Direction", prop: "direction" },
      { name: "In Time", prop: "intime" },
      { name: "Out Time", prop: "outtime" },
      { name: "Halt Time", prop: "halttime" },
      { name: "Vehicle Image", prop: "images" },
    ];

    // const time1 = '10:30 AM';
    // const time2 = '12:00 AM';

    // const format = 'hh:mm A';
    // const diff = moment(time2, format).diff(moment(time1, format));

    // // Get the difference in minutes
    // const minutesDiff = moment.duration(diff).asMinutes();

    setTableHead(headers);

    let body = {
      datefrom: datefro,
      dateto: dateto,
    };

    setLoader(true);
    let api = HOST + "/report/parkingreport";
    axios
      .post(api, body, {
        headers: {
          authToken: token,
          Accept: "application/json",
          // 'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        let data = [];
        response.data.map((vehicle) => {
          if (vehicle.outtime === null) {
            data.push(vehicle);
          }
        });

        if (response.data !== "") {
          setLoader(false);
          setData(response.data);
          setLprData(response.data);
        } else {
          setData([]);
          setLoader(false);
          // showNotification("tcc")
          seterroertext("No records found");
        }
      });
  };

  const handleChange = (event, values) => {
    if (values) {
      setReportid(values.props.value);
      if (values.props.value === "2") {
        setTableHead(props.tableHead);
        setSelectedValue("");
        setTenant(tenantData[0].tenant);
        tenantDetails();
        setMaxBikeCountid(0);
        setMaxCarCountid(0);
      } else if (values.props.value === "3") {
        setTableHead(props.tableHead);
        setSelectedValue("3");
        tenantCount(tenant);
      } else {
        setTableHead(props.tableHead);
        setLprData(tableData);
        setData(TableMaindata1);
        setMaxBikeCountid(0);
        setMaxCarCountid(0);
        setSelectedValue("");
        setTenant(tenantData[0].tenant);
      }
    }
  };
  const handleParkingChange = (event, values) => {
    if (values) {
      setReportid(values.props.value);
      if (values.props.value === "2") {
        //setTableHead(props.tableHead)
        // setData(tableData)
        setData([]);
        parkedVehicleList(props.tableData);
      } else if (values.props.value === "1") {
        setTableHead(props.tableHead);
        setLprData(TableMaindata1);
        setData(TableMaindata1);
      } else if (values.props.value === "3") {
        setData([]);
        inAndTallyVehicle(props.tableData);
      }
    }
  };

  const handleTenantSelectChange = (event, values) => {
    setTenant(values.props.value);
    tenantCount(values.props.value);
  };
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={imageopen}
        onClose={() => setImageopen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          onClose={() => setImageopen(false)}
        >
          {""}
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            onClick={() => setImageopen(false)}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <img
                src={imagevalue}
                alt="License veihcle"
                style={{ width: "100%" }}
                onError={(e) => {
                  e.target.src =
                    "https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/noimage.png";
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <div className={classes.tableResponsive}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5} lg={4}>
            <CustomInput
              formControlProps={{
                className: classes.root,
              }}
              inputProps={{
                placeholder: "Search",

                onChange: (e) => {
                  setFilterText(e.target.value);
                  const filteritems = TableMaindata.filter((x) => {
                    const values = tableHead.filter((y, k) => {
                      if (e.target.type !== "number") {
                        return String(x[y.prop])
                          .toLocaleLowerCase()
                          .includes(String(e.target.value).toLocaleLowerCase());
                      } else {
                        return String(x[y.prop]).includes(
                          String(e.target.value)
                        );
                      }
                    });
                    if (values.length > 0) {
                      return true;
                    } else {
                      return false;
                    }
                  });
                  if (!e.target.value) {
                    setData(lprData);
                  } else {
                    setData(filteritems);
                  }
                },
              }}
            />
            <Button
              color="primary"
              aria-label="edit"
              justIcon
              round
              onClick={() => {
                const filteritems = TableMaindata.filter((x) => {
                  const values = tableHead.filter((y, k) => {
                    return String(x[y.prop])
                      .toLocaleLowerCase()
                      .includes(filterText.toLocaleLowerCase());
                  });
                  if (values.length > 0) {
                    return true;
                  } else {
                    return false;
                  }
                });
                setData(filteritems);
              }}
            >
              <Search />
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={5} lg={6}>
            {/* <Button color="primary" onClick={() => deleteJSON()} >

                            Delete JSON</Button> */}

            {/* 
                        <Button color="primary" onClick={() => fileDownloadHandler()} >

                            Download Images</Button> */}
            {/* <Button color="primary" onClick={() => parkedVehicleList()} > Get Parked Vehicles </Button> */}
            {props.history.location.pathname !== "/search" &&
            routename === "lpr" &&
            sessionStorage.getItem("parkinganalysis") === "true" ? (
              <>
                {" "}
                <FormControl
                  color="primary"
                  style={{ height: "60%", paddingRight: 20 }}
                >
                  <InputLabel>Parking Reports</InputLabel>

                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={reportid}
                    onChange={handleParkingChange}
                    color="green"
                    style={{ fontSize: 15 }}
                  >
                    <MenuItem value="1">Get ALL Vehicles</MenuItem>
                    <MenuItem value="2">Get Parked Vehicles</MenuItem>
                    <MenuItem value="3">IN & OUT Tally</MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : (
              ""
            )}

            {routename === "lpr" &&
            sessionStorage.getItem("tenant") === "true" &&
            props.vehicle === "" ? (
              <>
                <FormControl color="primary" style={{ height: "60%" }}>
                  <InputLabel>Report Type</InputLabel>

                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={reportid}
                    onChange={handleChange}
                    color="green"
                    style={{ fontSize: 15 }}
                  >
                    {/* {reportTypes.map((entryCriteria, index) => (
                                    <MenuItem value={entryCriteria.reportid}>{entryCriteria.reportType.toLocaleLowerCase()}</MenuItem>

                                ))} */}
                    <MenuItem value="1">All Vehicle Data</MenuItem>
                    <MenuItem value="2">Tenant Wise Data</MenuItem>
                    <MenuItem value="3">Parking Usage Report</MenuItem>
                  </Select>
                </FormControl>
                {selectedValue === "3" ? (
                  <FormControl
                    style={{
                      width: "20%",
                      height: "60%",
                      marginTop: -1,
                      marginLeft: 10,
                    }}
                  >
                    <InputLabel>Tenant</InputLabel>
                    <Select
                      value={tenant}
                      onChange={handleTenantSelectChange}
                      name="tenant"
                    >
                      {tenantData.map((entryCriteria, index) => (
                        <MenuItem value={entryCriteria.tenant}>
                          {entryCriteria.tenant}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={2} lg={2}>
            <Button
              color="info"
              style={{ marginLeft: "-10px" }}
              onClick={() => {
                var html = document.querySelector("table").outerHTML;
                if (routename === "lpr") {
                  export_table_to_csv(
                    html,
                    "Vehicle Activity from " + datefro + "to " + dateto + ".csv"
                  );
                }
              }}
            >
              <Icon icon={microsoftExcel} />
              Export Excel
            </Button>
          </GridItem>
        </GridContainer>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            // && !headerror
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={key}
                    >
                      <TableSortLabel
                        IconComponent={ArrowUpwardIcon}
                        active={orderBy === prop.prop}
                        hideSortIcon={
                          prop.name !== "S.No" &&
                          prop.name !== "Speed" &&
                          prop.name !== "Vehicle Image" &&
                          prop.name !== "Action" &&
                          prop.name !== "Share Activity" &&
                          prop.name !== "Comments"
                            ? false
                            : true
                        }
                        direction={orderBy === prop.prop ? order : "asc"}
                        onClick={() => {
                          if (
                            prop.name !== "S.No" &&
                            prop.name !== "Vehicle Image" &&
                            prop.name !== "Speed" &&
                            prop.name !== "Action" &&
                            prop.name !== "Share Activity" &&
                            prop.name !== "Comments"
                          ) {
                            handleRequestSort(prop.prop);
                          }
                        }}
                      >
                        {prop.name}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {netWorkError ? (
              <SnackbarContent
                icon={InfoIcon}
                message={erroertext}
                // close
                color="danger"
              />
            ) : (
              ""
            )}
            {loader ? <LoaderComponent /> : ""}

            {TableMaindata.length !== 0 &&
              stableSort(
                filterText
                  ? TableMaindata.filter((x) => {
                      const values = tableHead.filter((y, k) => {
                        if (typeof filterText !== "number") {
                          return String(x[y.prop])
                            .toLocaleLowerCase()
                            .includes(String(filterText).toLocaleLowerCase());
                        } else {
                          return String(x[y.prop]).includes(String(filterText));
                        }
                      });
                      if (values.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    })
                  : TableMaindata,
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((prop, key) => {
                  return (
                    <TableRow key={key} className={classes.tableBodyRow}>
                      {row(prop, key, tableHead)}
                    </TableRow>
                  );
                })}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}></GridItem>
            </GridContainer>
          </TableBody>
          <caption
            style={{
              position: "relative",
              top: "-53px",
              bottom: 0,
              left: "-13px",
              color: "#000",
            }}
          >
            {" "}
            Total :{" "}
            {TableMaindata.length !== undefined
              ? filterText
                ? finalCount.current.length
                : TableMaindata.length
              : 0}
          </caption>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={"Records Per Page"}
            // component="div"
            // labelDisplayedRows={({ from, to, count }) => `Showing ${ page} to ${(page * rowsPerPage) > TableMaindata.length ? TableMaindata.length : page * rowsPerPage } of ${TableMaindata.length} Results`}
            count={
              filterText ? finalCount.current.length : TableMaindata.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
        {selectedValue === "3" ? (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5} lg={5}></GridItem>
              <GridItem xs={12} sm={12} md={5} lg={3}></GridItem>
              <GridItem xs={12} sm={12} md={5} lg={4}>
                <div style={{ display: "flex", marginTop: -20 }}>
                  {" "}
                  <legend
                    style={{
                      marginRight: 10,
                      color: "red",
                      backgroundColor: "red",
                      height: "20px",
                      width: "20px",
                    }}
                  ></legend>
                  <b>Maximum Parking Usage</b>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
export default withRouter(CustomTable);

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "Grids/Database";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import React, { Component } from 'react';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
// npm install --save-dev @iconify/react @iconify/icons-mdi
import { Icon } from '@iconify/react';
import fileExcel from '@iconify/icons-mdi/file-excel';
import Button from "components/CustomButtons/Button.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import moment from 'moment'

import './App.css'
import Settings from '../../lprfolders/GlobalConstant/Settings'
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addcompany: false,
      currentdate: new Date()
    }
  }
  render() {

    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
        <GridItem xs={6} sm={6} md={6} lg={7} xl={9}>
              </GridItem>
              <GridItem xs={5} sm={5} md={5} lg={5} xl={3}  >
                <div style={{ display: "inline"}}>
                  <Button color="primary" style={{marginLeft:5}} onClick={() => {
                    this.props.history.push('/vehicle/bulk')
                  }}><Icon icon={fileExcel} />Bulk upload</Button>
              {/* </GridItem>
              <GridItem xs={12} sm={3} md={3} lg={2} > */}
                  <Button color="primary" style={{marginLeft:5}} onClick={() => {
                    this.props.history.push({
                      pathname: '/vehicle/add', state: {
                          exclude: false
                      }
                  })
                  }}><AddBoxIcon /> Add Vehicle</Button>
                {/* {window.sessionStorage.getItem("vehicleexclude")==='true' ? <Button color="primary" style={{marginLeft:5}} onClick={() => {
                    this.props.history.push('/vehicle/exclude')
                  }}><AddBoxIcon /> Exclude Vehicles</Button>:""} */}
                  </div>
              </GridItem>
            
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Vehicle records")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {Settings.capitalizeFLetter("Vehicle details as on ")} {moment(this.state.currentdate).format("DD-MM-YYYY")}
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="success"
                  tableHead={[
                    {
                      name: "",
                      prop: "checkbox"
                    },
                    {
                      name: "S.No",
                      prop: "s.no"
                    },
                    {
                      name: "EMP ID.",
                      prop: "flatno"
                    },
                    {
                      name: "Vehicle Number",
                      prop: "vehicle"
                    },
                    {
                      name: "Mobile Number",
                      prop: "mobile"
                    },
                    {
                      name: "Person Name",
                      prop: "fullname"
                    },
                    {
                      name: "Email",
                      prop: "email"
                    },
                    {
                      name: "Gate Access",
                      prop: "camera"
                    },
                    {
                      name: "Tenant",
                      prop: "tenantuser"
                    },
                    {
                      name: "Added By",
                      prop: "addedby"
                    },
                    {
                      name: "Created On",
                      prop: "createdon"
                    },
                    {
                      name: "Comments",
                      prop: "comments"
                    },  
                    // {
                    //   name: "Blocked",
                    //   prop: "exclude"
                    // },                
                    {
                      name: "Action",
                      prop: "action"
                    }
                  ]}
                  tableData={this.state.vehicles}
                  routename="vehicle"
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter((withStyles(styles)(Dashboard)));

import Alert from './Alert'
import moment from 'moment'
import CookieSetService from './CookieSetService'
export const requireerrors = function () {
  return { status: true, errmessgae: Alert.EmptyError }
}
export const mobilenumber = function (data) {
  if (Alert.phonenoPattern.test(data.replace(/\s+/g, ''))) {
    return { status: true, errmessgae: "" }
  } else {
    return { status: false, errmessgae: Alert.phonenoPatternMsg, inputname: "Mobile" }
  }

}
export const Email = function (data) {
  if (Alert.emailPattern.test(String(data).replace(/\s+/g, '').toLowerCase())) {
    return { status: true, errmessgae: "" }
  } else {
    return { status: false, errmessgae: Alert.email_Login, inputname: "Email" }
  }
}
export const SecondaryEmail = function (data) {
  if (Alert.emailPattern.test(String(data).replace(/\s+/g, '').toLowerCase())) {
    return { status: true, errmessgae: "" }
  } else {
    return { status: false, errmessgae: Alert.email_Login, inputname: "Secondary Email" }
  }
}
export const Website = function (data) {
  if (Alert.CompanyURLPattern.test(data.replace(/\s+/g, ''))) {
    return { status: true, errmessgae: "" }
  } else {
    return { status: false, errmessgae: Alert.WebsiteUrl, inputname: "Website" }
  }
}
export const FirstName = function (data) {
  if (((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 8))) {
    if (Alert.Aplhabets.test(data.replace(/\s+/g, ''))) {
      return { status: true, errmessgae: "" }
    } else {
      return { status: false, errmessgae: Alert.UsersFirstalpha, inputname: "FirstName" }
    }
  }
  else {
    return { status: false, errmessgae: Alert.UsersminmaxFirstname, inputname: "FirstName" }
  }
}
export const LastName = function (data) {
  if (((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 8))) {
    if (Alert.Aplhabets.test(data.replace(/\s+/g, ''))) {
      return { status: true, errmessgae: "" }
    } else {
      return { status: false, errmessgae: Alert.Userslastalpha, inputname: "LastName" }
    }
  }
  else {
    return { status: false, errmessgae: Alert.UsersminmaxLastname, inputname: "LastName" }
  }
}
export const CompnayName = function (data) {
  if (data === 409) {
    return { status: false, errmessgae: Alert.CompnayExisted, inputname: "CompanyName" }
  }
  if (((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20))) {
    if (Alert.AlphanumericPattern.test(data.replace(/\s+/g, ''))) {
      return { status: true, errmessgae: "" }
    } else {
      return { status: false, errmessgae: Alert.CompanyName, inputname: "CompanyName" }
    }
  }
  else {
    return { status: false, errmessgae: Alert.Companyminmax, inputname: "CompanyName" }
  }
}
export const PersonName = function (data) {
  if (Alert.Aplhabets.test(data.replace(/\s+/g, ''))) {
    if (((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20))) {
      return { status: true, errmessgae: "" }
    }
    else {
      return { status: false, errmessgae: Alert.PersonNameminamx, inputname: "PersonName" }
    }
  } else {
    return { status: false, errmessgae: Alert.PersonCharacters, inputname: "PersonName" }
  }

}
export const BulkuploadPersonName = function (data) {
  if (Alert.Aplhabets.test(data)) {
    if (((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20))) {
      return { status: true, errmessgae: "" }
    }
    else {
      return { status: false, errmessgae: Alert.PersonNameminamx, inputname: "PersonName" }
    }
  } else {
    return { status: false, errmessgae: Alert.PersonCharacters, inputname: "PersonName" }
  }

}
export const HoStName = function (data) {
  if (((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20))) {
    if (Alert.Aplhabets.test(data.replace(/\s+/g, ''))) {
      return { status: true, errmessgae: "" }
    } else {
      return { status: false, errmessgae: Alert.PersonCharacters, inputname: "Hostname" }
    }
  }
  else {
    return { status: false, errmessgae: Alert.PersonNameminamx, inputname: "Hostname" }
  }
}
export const Comments = function (data) {
  if ((data.replace(/\s+/g, '').length <= 25)) {
    return { status: true, errmessgae: "" }
  }
  else {
    return { status: false, errmessgae: Alert.CommenstLengthErrors, inputname: "Comments" }
  }

}
export const FlatNo = function (data) {
  if ((data.length <= 10)) {
    if (Alert.AlphanumericPattern.test(data.replace(/\s+/g, ''))) {
      return { status: true, errmessgae: "" }
    } else {
      return { status: false, errmessgae: Alert.dynamicaplahapatternError + "EMP ID.", inputname: "FlatNo" }
    }
  }
  else {
    return { status: false, errmessgae: Alert.FlatNoLegthError, inputname: "FlatNo" }
  }
}
export const Vehicle = function (data) {
  if (data === 409) {
    return { status: false, errmessgae: "Vehicle already existed with us.", inputname: "Vehicle" }
  }
  if (Alert.AlphanumericPattern.test(data.replace(/\s+/g, ''))) {
    if (((data.replace(/\s+/g, '').length >= 5) && (data.replace(/\s+/g, '').length <= 10))) {
      return { status: true, errmessgae: "" }
    }
    else {
      return { status: false, errmessgae: Alert.VehicleLengthError, inputname: "Vehicle" }
    }
  } else {
    return { status: false, errmessgae: Alert.dynamicaplahapatternError + "Vehicle No.", inputname: "Vehicle" }
  }
}
export const TrackVehicle = function (data) {

  if (Alert.AlphanumericPattern.test(data.replace(/\s+/g, ''))) {
    if (((data.replace(/\s+/g, '').length >= 2) && (data.replace(/\s+/g, '').length <= 10))) {
      return { status: true, errmessgae: "" }
    }
    else {
      return { status: false, errmessgae: Alert.TrackVehicleLengthError, inputname: "Vehicle" }
    }
  } else {
    return { status: false, errmessgae: Alert.dynamicaplahapatternError + "Vehicle No.", inputname: "Vehicle" }
  }
}
export const UserEXist = function (data) {
  if (data === 409) {
    return { status: false, errmessgae: Alert.CompnayExisted, inputname: "User" }
  }
}
export const Password = function (data, passvalue) {
  if ((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20) && (passvalue === "NewPassword")) {
    return { status: true, errmessgae: "" }
  }
  else if ((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20) && (passvalue === "ConfirmPassword")) {
    return { status: true, errmessgae: "" }
  }
  else {
    return { status: false, errmessgae: Alert.passwordError, inputname: passvalue }
  }
}
export const PasswordMain = function (data) {
  if ((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20)) {
    return { status: true, errmessgae: "" }
  }
  else {
    return { status: false, errmessgae: Alert.passwordError, inputname: "Password" }
  }
}
export const NewPasswordMain = function (data) {
  if ((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20)) {
    return { status: true, errmessgae: "" }
  }
  else {
    return { status: false, errmessgae: Alert.passwordError, inputname: "NewPassword" }
  }
}
export const ConfirmPasswordMain = function (data) {
  if ((data.replace(/\s+/g, '').length >= 3) && (data.replace(/\s+/g, '').length <= 20)) {
    return { status: true, errmessgae: "" }
  }
  else {
    return { status: false, errmessgae: Alert.passwordError, inputname: "ConfirmPassword" }
  }
}
export const BulkuploadHedearCheck = function (data, header) {
  if (JSON.stringify(Object.keys(data[0])) === JSON.stringify(header)) {
    return { status: true, errmessgae: "" }
  }
  else {
    return { status: false, errmessgae: Alert.HedaerColoumError + "  " + header.toString() + "." }
  }
}
export const BulkuploadDataCheck = function (data, header) {
  let message = []
  let indexValue = []
  var unique = {}
  let valuesstatus = false
  data.forEach((obj, index) => {
    var prop = "COMMENTS";
    delete obj[prop];
    if (Object.values(obj).every(o => String(o).replace(/\s+/g, '') !== "")) {
      if (FlatNo(String(obj.EMPID).replace(/\s+/g, '')).status) {
        if (Vehicle(String(obj.VEHICLENUMBER).replace(/\s+/g, '')).status) {
          if (!unique[String(obj.VEHICLENUMBER).replace(/\s+/g, '')]) {
            unique[obj.VEHICLENUMBER] = true
            if (mobilenumber(String(obj.MOBILENUMBER).replace(/\s+/g, '')).status) {
              if (PersonName(obj.PERSONNAME).status) {

              }
              else {
                indexValue.push(index + 1)
                message.push(PersonName(String(obj.PERSONNAME).replace(/\s+/g, '')).errmessgae)
              }
            }
            else {
              indexValue.push(index + 1)
              message.push(mobilenumber(String(obj.MOBILENUMBER).replace(/\s+/g, '')).errmessgae)
            }
            return true
          } else {
            indexValue.push(index + 1)
            message.push("A record with this Vehicle Number already exists.")
            return false
          }
        }
        else {
          indexValue.push(index + 1)
          message.push(Vehicle(String(obj.VEHICLENUMBER).replace(/\s+/g, '')).errmessgae)
        }
      }
      else {
        indexValue.push(index + 1)
        message.push(FlatNo(String(obj.EMPID).replace(/\s+/g, '')).errmessgae)
      }
    } else {
      indexValue.push(index + 1)
      message.push(Alert.EmptyColomns)
    }
  })

  return { status: indexValue.length === 0 ? true : false, errmessgae: message[0], errorindex: valuesstatus ? [] : indexValue }
}
export const Statucheck = function (status, data) {
  if (String(status).toUpperCase() === "EXPECTED" || String(status).toUpperCase() === "CHECKEDIN") {
    if (Alert.Timevalidation.test(moment(data).format("hh:mm A"))) {
      if ((new Date().getTime() - Date.parse(new Date().getDate() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getFullYear() + " " + moment(data).format("hh:mm A"))) < 60000 && (String(status).toUpperCase() === "EXPECTED" || String(status).toUpperCase() === "CHECKEDIN")) {
        return { status: true, errmessgae: "" }
      } else {
        return { status: false, errmessgae: "Oops! status is either expected or checkedin, you have to enter future time only." }
      }
    } else {
      return { status: false, errmessgae: Alert.TimeError }
    }
  } else {
    return { status: false, errmessgae: Alert.Statusformat }
  }
}
export const golbalcookiefun = (response, remember) => {
  if (!remember) {
    const options = {
      path: '/'
    }
    CookieSetService.set(response.access_toke, options)
    return true;
  }

  let date = new Date()
  date.setTime(date.getTime() + (60 * 24) * (60) * (1000))
  const options = {
    path: '/',
    expires: date
  }
  CookieSetService.set('access_token', response.access_toke, options)
  CookieSetService.set('username', response.username, options)
  CookieSetService.set('password', response.password, options)
  CookieSetService.set('checkSatus', remember, options)

  return true;
}
export const BulkuploadVisitorsDatacheck = function (data, header) {
  let message = []
  let indexValue = []
  var unique = {}
  data.forEach((obj, index) => {
    if (Object.values(obj).every(o => String(o).replace(/\s+/g, '') !== "")) {
      if (Vehicle(String(obj.LICENSEPLATENO).replace(/\s+/g, '')).status) {
        if (!unique[String(obj.LICENSEPLATENO).replace(/\s+/g, '')]) {
          unique[obj.LICENSEPLATENO] = true
          if (mobilenumber(String(obj.MOBILENUMBER).replace(/\s+/g, '')).status) {
            // valuesstatus = true
            if (PersonName(String(obj.TOMEET).replace(/\s+/g, '')).status) {
              // valuesstatus = true
              if (PersonName(String(obj.VISITORNAME).replace(/\s+/g, '')).status) {
                // valuesstatus = true
                // if (Comments(String(obj.PURPOSE).replace(/\s+/g, '')).status) {
                //   // valuesstatus = true
                //   // if (Statucheck(String(obj.STATUS).replace(/\s+/g, ''), String(obj.INTIME).replace(/\s+/g, '')).status) {

                //   // } else {
                //   //   indexValue.push(index)
                //   //   message.push(Statucheck(String(obj.STATUS).replace(/\s+/g, ''), String(obj.INTIME).replace(/\s+/g, '')).errmessgae)
                //   // }
                // } else {
                //   indexValue.push(index)
                //   message.push("Purpose length must be minimum 3 maximum 25")
                // }
              } else {
                indexValue.push(index)
                message.push("Host name   " + PersonName(String(obj.VISITORNAME).replace(/\s+/g, '')).errmessgae)
              }
            }
            else {
              indexValue.push(index)
              message.push("Visitor name   " + PersonName(String(obj.TOMEET).replace(/\s+/g, '')).errmessgae)
            }
          }
          else {
            indexValue.push(index)
            message.push(mobilenumber(String(obj.MOBILENUMBER).replace(/\s+/g, '')).errmessgae)
          }
          return true
        }
        else {
          indexValue.push(index)
          message.push("Duplicate vehicle numbers are not allowed")
          return false
        }
      }
      else {
        indexValue.push(index)
        message.push(Vehicle(String(obj.LICENSEPLATENO).replace(/\s+/g, '')).errmessgae)
      }
    } else {
      indexValue.push(index)
      message.push(Alert.EmptyColomns)
    }
  })
  return { status: indexValue.length === 0 ? true : false, errmessgae: message[0], errorindex: indexValue }
}

export const netWorkError = function () {
  return { errmessgae: Alert.NetworkError }
}
export const Server = function () {
  return { errmessgae: Alert.ServerError }
}
export const Unauthrized = function () {
  return { errmessgae: Alert.UnauthorizedError }
}
export const camelCase = function (str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  });
}
export const titleCase = function (text) {
  let wordsArray = text.toLowerCase().split(' ')
  let capsArray = []
  wordsArray.forEach(word => {
    capsArray.push(word[0].toUpperCase() + word.slice(1))
  });
  return capsArray.join(' ')
}
export const capitalizeFLetter = function (string) {
  let x = string
  return x[0].toUpperCase() + x.slice(1)
}
export const CapitlizeCase = function (str) {
  var res = str.toUpperCase();
  return res
}
export const showNotification = place => {
  switch (place) {

    case "tc":
      if (!this.state.tc) {
        this.setState({ tc: true });
        setTimeout(function () {
          this.setState({ tc: false });
        }, 6000);
      }
      break;


    case "bc":
      if (!this.state.bc) {
        this.setState({ bc: true });
        setTimeout(function () {
          this.setState({ bc: false });
        }, 6000);
      }
      break;
    default:
      break;
  }
}
export const NetInfo = () => {
  return window.navigator.onLine
}
export default { NewPasswordMain,SecondaryEmail, ConfirmPasswordMain, TrackVehicle, golbalcookiefun, HoStName, NetInfo, capitalizeFLetter, CapitlizeCase, titleCase, camelCase, PasswordMain, Statucheck, BulkuploadVisitorsDatacheck, BulkuploadDataCheck, BulkuploadHedearCheck, Password, PersonName, Vehicle, FlatNo, Comments, requireerrors, mobilenumber, Email, Website, FirstName, LastName, CompnayName, netWorkError, Server, Unauthrized }

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";


import "firebase/compat/database";
const firebaseConfig = {
  apiKey: "AIzaSyAhOwvxdutgmFFSWzJPfAjUagxWN-D0Xyo",
  authDomain: "display-7d042.firebaseapp.com",
  databaseURL: "https://display-7d042-default-rtdb.firebaseio.com",
  projectId: "display-7d042",
  storageBucket: "display-7d042.appspot.com",
  messagingSenderId: "458341892955",
  appId: "1:458341892955:web:b6072a1b923b752988d07e"
};
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
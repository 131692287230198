import React, { Component } from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Switch, withRouter } from "react-router-dom";
import Settings from "../../lprfolders/GlobalConstant/Settings";
import LoaderComponent from "../../lprfolders/GlobalConstant/LoaderComponent";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from "@material-ui/icons/Info";
import axios from "axios";
import Table from "Grids/Reports";
import { HOST } from "../../lprfolders/config";
import moment from "moment";
import "./report.css";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import 'date-fns';

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import AssessmentIcon from "@material-ui/icons/Assessment";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
class LprReports extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.state = {
      open: false,
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      vehicle: "",
      vehicles: [],
      flagError: false,
      netWorkError: false,
      inputname: "",
      erroertext: "",
      showtable: false,
      mobile: "",
      emptystate: false,
      tenantData: [],
      databaseVehicles: [],
      vehiclecount: [],
      labels: ["a"],
      component: "",
    };
  }

  handleFromDateChange = (date) => {
    this.setState({ selectedFromDate: date });
  };
  handleToDateChange = (date) => {
    this.setState({ selectedToDate: date });
  };

  componentDidMount() {
    let apis = HOST + "/tenant/getall";
    axios
      .get(apis, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({ tenantData: response.data });
      });
    let api1 = HOST + "/vehicle/getall";
    axios
      .get(api1, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({ databaseVehicles: response.data });
      });
  }

  getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
    }
    return arr;
  };

  getEntryCount = function (start, end) {
    var entryCount = [];
    var count = 0;
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
    }
    arr.map((date) => {
      count = 0;
      this.state.vehicles.map((vehicle) => {
        if (vehicle.eventdate === date.split("-").reverse().join("-"))
          if (vehicle.direction === "ENTRY") {
            count++;
          }
      });
      entryCount.push(count);
    });
    return entryCount;
  };

  getExitCount = function (start, end) {
    var exitCount = [];
    var count = 0;
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
    }
    arr.map((date) => {
      count = 0;
      this.state.vehicles.map((vehicle) => {
        if (vehicle.eventdate === date.split("-").reverse().join("-"))
          if (vehicle.direction === "EXIT") {
            count++;
          }
      });
      exitCount.push(count);
    });
    return exitCount;
  };

  trackVehicle = () => {
    this.setState({ showtable: false });
    let api = HOST + "/report/lpr";
    let body = {
      vehicle: this.state.vehicle,
      mobile: this.state.mobile,
      datefrom: moment(this.state.selectedFromDate).format("YYYY-MM-DD"),
      dateto: moment(this.state.selectedToDate).format("YYYY-MM-DD"),
    };
    let startDate = this.state.selectedFromDate;
    let todate = this.state.selectedToDate;
    let count = 0;
    if (this.state.vehicle === "") {
      count = 0;
    }
    if (this.state.vehicle) {
      if (Settings.Vehicle(this.state.vehicle).status) {
        count = 0;
      } else {
        count++;
        this.setState({
          flagError: true,
          erroertext: Settings.Vehicle(this.state.vehicle).errmessgae,
          inputname: Settings.Vehicle(this.state.vehicle).inputname,
        });
        setTimeout(() => {
          this.setState({ flagError: false });
        }, 3000);
      }
    }
    if (startDate.getTime() <= todate.getTime()) {
    } else {
      count++;
      this.setState({
        erroertext: "To date should be after the start date ",
        flagError: true,
      });
      setTimeout(() => {
        this.setState({ flagError: false });
      }, 3000);
    }
    if (count === 0) {
      // this.setState({ showtable: true })
      this.setState({ loader: true });
      axios
        .post(api, body, {
          headers: {
            authToken: this.token,
            Accept: "application/json",
            // 'Content-Type': 'application/json'
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            this.setState({ emptystate: true, loader: false });
            this.setState({ showtable: false });
            setTimeout(() => {
              this.setState({ emptystate: false });
            }, 3000);
          } else {
            this.setState({
              vehicles: res.data,
              loader: false,
              emptystate: false,
            });
            this.setState({ showtable: true });
            let data = [];
            let RegisteredVehicle = [];
            let vehicleDetails = [];
            let count;
            this.state.vehicles.forEach((item) => {
              if (item.db_match === true) {
                RegisteredVehicle.push(item);
              }
            });
            this.state.databaseVehicles.map((tenant) => {
              RegisteredVehicle.map((vehicle) => {
                if (
                  vehicle.reading.toLowerCase() === tenant.vehicle.toLowerCase()
                ) {
                  Object.assign(vehicle, { tenant: tenant.firstname });
                  vehicleDetails.push(vehicle);
                }
              });
            });

            this.state.tenantData.map((tenant) => {
              count = 0;
              vehicleDetails.map((vehicle) => {
                if (
                  tenant.firstname.toLowerCase() ===
                  vehicle.tenant.toLowerCase()
                ) {
                  count++;
                }
              });
              data.push({ tenant: tenant.tenant, count: count });
            });

            this.setState({ vehiclecount: data });
          }
        })
        .catch((err) => {
          this.setState({ showtable: true });
          if (err.message === "Network Error") {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.netWorkError().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
          if (err.response) {
            if (err.response.data.statusid === 409) {
              this.setState({
                flagError: true,
                loader: false,
                erroertext: Settings.Vehicle(409).errmessgae,
                inputname: Settings.Vehicle(409).inputname,
              });
              setTimeout(() => {
                this.setState({ flagError: false });
              }, 3000);
            }
            if (err.response.status === 502) {
              this.setState({
                netWorkError: true,
                loader: false,
                erroertext: Settings.Server().errmessgae,
              });
              setTimeout(() => {
                this.setState({ netWorkError: false });
              }, 3000);
            }
          }
        });
    }
  };

  // tenantCount = () => {

  //     let data = []
  //     let RegisteredVehicle = [];
  //     let vehicleDetails = [];
  //     let count;
  //     console.log(this.state.vehicles)

  //     this.state.vehicles.forEach(item => {
  //         if (item.db_match === true) {
  //             RegisteredVehicle.push(item)
  //         }
  //     })
  //     this.state.databaseVehicles.map((tenant) => {
  //         RegisteredVehicle.map((vehicle) => {
  //             if (vehicle.reading.toLowerCase() === tenant.vehicle.toLowerCase()) {
  //                 Object.assign(vehicle, { "tenant": tenant.firstname })
  //                 vehicleDetails.push(vehicle)
  //             }
  //         })
  //     })

  //     this.state.tenantData.map((tenant) => {
  //         count = 0;
  //         vehicleDetails.map((vehicle) => {
  //             if (tenant.firstname.toLowerCase() === vehicle.tenant.toLowerCase()) {
  //                 count++
  //             }
  //         })
  //         console.log(count)
  //         data.push({ "tenant": tenant.tenant, "count": count })
  //     })

  //     this.setState({ vehiclecount: data })

  // }

  render() {
    const labels = this.getDaysArray(
      moment(this.state.selectedFromDate).format("YYYY-MM-DD"),
      moment(this.state.selectedToDate).format("YYYY-MM-DD")
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Entry",
          data: this.getEntryCount(
            moment(this.state.selectedFromDate).format("YYYY-MM-DD"),
            moment(this.state.selectedToDate).format("YYYY-MM-DD")
          ),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Exit",
          data: this.getExitCount(
            moment(this.state.selectedFromDate).format("YYYY-MM-DD"),
            moment(this.state.selectedToDate).format("YYYY-MM-DD")
          ),
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Dataset 2",
          data: [10, 10, 10],
          backgroundColor: "rgb(255,0,0)",
          borderColor: "rgb(255,0,0)",
          type: "line",
          borderDash: [10, 5],
          order: 0,
        },
      ],
    };

    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Select Period</h4>
                <p className={classes.cardCategoryWhite}>
                  Results between the selected dates will be displayed
                </p>
              </CardHeader>
              <CardBody>
                {this.state.flagError ? (
                  <SnackbarContent
                    icon={InfoIcon}
                    message={this.state.erroertext}
                    // close
                    color="danger"
                  />
                ) : (
                  ""
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          color="primary"
                          id="date-picker-dialog"
                          label="Select from Date"
                          format="dd/MM/yyyy"
                          value={this.state.selectedFromDate}
                          onChange={this.handleFromDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          maxDate={new Date()}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          color="primary"
                          id="date-picker-dialog"
                          label="Select to Date"
                          format="dd/MM/yyyy"
                          value={this.state.selectedToDate}
                          maxDate={new Date()}
                          onChange={this.handleToDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button color="primary" onClick={this.trackVehicle}>
              <AssessmentIcon />
              Generate Report
            </Button>
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={5}>

                        {this.state.showtable ? <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="primary">
                                        <h4 className={classes.cardTitleWhite}>  Vehicles Count  </h4>

                                    </CardHeader>
                                    <CardBody>
                                        <table>
                                            <th style={{width:100}}>S.No</th>
                                            <th style={{width:100}}>Tenant</th>
                                            <th style={{width:100}}>Count</th>
                                            {this.state.vehiclecount.map((tenantcount, index) => {
                                                return (
                                                    <tr>
                                                        <td style={{width:100,textAlign:'center'}}>{index + 1}</td>
                                                        <td style={{width:100,textAlign:'center'}}>{tenantcount.tenant}</td>
                                                        <td style={{width:100,textAlign:'center'}}>{tenantcount.count}</td>

                                                    </tr>
                                                );

                                            })}

                                        </table>
                                        <Bar options={options}
                                            data={data} />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                            </GridItem>
                        </GridContainer>
                            : ""}
                    </GridItem> */}
        </GridContainer>

        {/* <GridContainer style={{ height: 1000, width: 1000 }}>
                    <GridItem xs={12} sm={12} md={5} lg={5}  >
                        <Pie options={pieOptions} data={piedata} ></Pie>
                    </GridItem>
                </GridContainer> */}
        {this.state.loader ? <LoaderComponent /> : ""}
        {this.state.emptystate ? (
          <SnackbarContent
            icon={InfoIcon}
            message={"No records found"}
            // close
            color="danger"
          />
        ) : (
          ""
        )}
        {this.state.showtable ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Vehicle Activity</h4>
                  <p className={classes.cardCategoryWhite}>
                    Vehicle movement from{" "}
                    {moment(this.state.selectedFromDate).format("DD-MM-YYYY")}{" "}
                    to {moment(this.state.selectedToDate).format("DD-MM-YYYY")}
                  </p>
                </CardHeader>
                <CardBody>
                  {this.state.netWorkError ? (
                    <SnackbarContent
                      icon={InfoIcon}
                      message={this.state.erroertext}
                      // close
                      color="danger"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.loader ? <LoaderComponent /> : ""}

                  <Table
                    tableHeaderColor="success"
                    tableHead={[
                      {
                        name: "Match",
                        prop: "db_match",
                      },
                      {
                        name: "Vehicle Number",
                        prop: "reading",
                      },
                      {
                        name: "Date",
                        prop: "eventdate",
                      },
                      {
                        name: "Time",
                        prop: "eventtime",
                      },
                      {
                        name: "Speed",
                        prop: "speed",
                      },
                      {
                        name: "Direction",
                        prop: "camera",
                      },
                      {
                        name: "Vehicle Image",
                        prop: "fullimagepath",
                      },
                    ]}
                    tableData={this.state.vehicles}
                    routename="lpr"
                    trackVehicle={this.trackVehicle}
                    vehicle={this.state.vehicle}
                    mobile={this.state.mobile}
                    datefro={moment(this.state.selectedFromDate).format(
                      "YYYY-MM-DD"
                    )}
                    dateto={moment(this.state.selectedToDate).format(
                      "YYYY-MM-DD"
                    )}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}></GridItem>
          </GridContainer>
        ) : (
          ""
        )}

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}></GridItem>
          {/* <GridItem xs={12} sm={12} md={6}>
                        <Card>
                            <LinearProgress variant="determinate" value={20}/>

                        </Card>
                    </GridItem> */}
        </GridContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(LprReports));

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "Grids/Speed";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import React, { Component } from "react";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
// npm install --save-dev @iconify/react @iconify/icons-mdi
import { Icon } from "@iconify/react";
import fileExcel from "@iconify/icons-mdi/file-excel";
import Button from "components/CustomButtons/Button.js";
import AddBoxIcon from "@material-ui/icons/AddBox";
import moment from "moment";

import Settings from "../../lprfolders/GlobalConstant/Settings";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import InfoIcon from "@material-ui/icons/Info";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CardFooter from "components/Card/CardFooter.js";
import { HOST } from "lprfolders/config";
import axios from "axios";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");

    this.state = {
      addcompany: false,
      currentdate: new Date(),
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      vehicle: "",
      vehicles: [],
      flagError: false,
      netWorkError: false,
      inputname: "",
      erroertext: "",
      showtable: false,
      mobile: "",
      emptystate: false,
    };
  }
  trackVehicle = () => {
    this.setState({ showtable: false });
    let api = HOST + "/lpr/alert/getall";
    let body = {
      vehicle: this.state.vehicle,
      mobile: this.state.mobile,
      datefrom: moment(this.state.selectedFromDate).format("YYYY-MM-DD"),
      dateto: moment(this.state.selectedToDate).format("YYYY-MM-DD"),
    };
    let startDate = this.state.selectedFromDate;
    let todate = this.state.selectedToDate;
    let count = 0;
    if (this.state.vehicle === "") {
      count = 0;
    }
    if (this.state.vehicle) {
      if (Settings.Vehicle(this.state.vehicle).status) {
        count = 0;
      } else {
        count++;
        this.setState({
          flagError: true,
          erroertext: Settings.Vehicle(this.state.vehicle).errmessgae,
          inputname: Settings.Vehicle(this.state.vehicle).inputname,
        });
        setTimeout(() => {
          this.setState({ flagError: false });
        }, 3000);
      }
    }
    if (startDate.getTime() <= todate.getTime()) {
    } else {
      count++;
      this.setState({
        erroertext: "To date should be after the start date ",
        flagError: true,
      });
      setTimeout(() => {
        this.setState({ flagError: false });
      }, 3000);
    }
    if (count === 0) {
      // this.setState({ showtable: true })
      this.setState({ loader: true });
      axios
        .post(api, body, {
          headers: {
            authToken: this.token,
            Accept: "application/json",
            // 'Content-Type': 'application/json'
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            this.setState({ emptystate: true, loader: false });
            this.setState({ showtable: false });
            setTimeout(() => {
              this.setState({ emptystate: false });
            }, 3000);
          } else {
            this.setState({
              vehicles: res.data,
              loader: false,
              emptystate: false,
            });
            this.setState({ showtable: true });
          }
        })
        .catch((err) => {
          this.setState({ showtable: true });
          if (err.message === "Network Error") {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.netWorkError().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
          if (err.response) {
            if (err.response.data.statusid === 409) {
              this.setState({
                flagError: true,
                loader: false,
                erroertext: Settings.Vehicle(409).errmessgae,
                inputname: Settings.Vehicle(409).inputname,
              });
              setTimeout(() => {
                this.setState({ flagError: false });
              }, 3000);
            }
            if (err.response.status === 502) {
              this.setState({
                netWorkError: true,
                loader: false,
                erroertext: Settings.Server().errmessgae,
              });
              setTimeout(() => {
                this.setState({ netWorkError: false });
              }, 3000);
            }
          }
        });
    }
  };
  handleFromDateChange = (date) => {
    this.setState({ selectedFromDate: date });
  };
  handleToDateChange = (date) => {
    this.setState({ selectedToDate: date });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Select Period</h4>
                    <p className={classes.cardCategoryWhite}>
                      Results between the selected dates will be displayed
                    </p>
                  </CardHeader>
                  <CardBody>
                    {this.state.flagError ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              color="primary"
                              id="date-picker-dialog"
                              label="Select from Date"
                              format="dd/MM/yyyy"
                              value={this.state.selectedFromDate}
                              onChange={this.handleFromDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              maxDate={new Date()}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              color="primary"
                              id="date-picker-dialog"
                              label="Select to Date"
                              format="dd/MM/yyyy"
                              value={this.state.selectedToDate}
                              maxDate={new Date()}
                              onChange={this.handleToDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <Button color="primary" onClick={this.trackVehicle}>
                  <AssessmentIcon />
                  Generate Report
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}></GridItem>
          <GridItem xs={12} sm={12} md={4}></GridItem>
          <GridItem xs={12} sm={12} md={4}></GridItem>
        </GridContainer>
        {this.state.emptystate ? (
          <SnackbarContent
            icon={InfoIcon}
            message={"No records found"}
            // close
            color="danger"
          />
        ) : (
          ""
        )}
        {this.state.showtable ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    {Settings.titleCase("Vehicle Violation records")}
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    {Settings.capitalizeFLetter(
                      "Vehicle Violation details as on "
                    )}{" "}
                    {moment(this.state.currentdate).format("DD-MM-YYYY")}
                  </p>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="success"
                    tableHead={[
                      {
                        name: "S.No",
                        prop: "s.no",
                      },
                      // {
                      //   name: "Match",
                      //   prop: "db_match"
                      // },
                      {
                        name: "Vehicle Number",
                        prop: "reading",
                      },
                      {
                        name: "Direction",
                        prop: "camera",
                      },
                      {
                        name: "Speed",
                        prop: "speed",
                      },
                      {
                        name: "Time",
                        prop: "eventtime",
                      },
                      {
                        name: "Vehicle Image",
                        prop: "fullimagepath",
                      },
                      {
                        name: "Mail Status",
                        prop: "issend",
                      },
                    ]}
                    tableData={this.state.vehicles}
                    routename="lpr"
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}></GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Dashboard));

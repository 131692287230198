import React from 'react';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {  makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    leftAlignDialogActions: {
        justifyContent: 'flex-start',
        paddingLeft: 23
    }
}))
const DeleteConfirmDialog = (props) => {
    const classes = useStyles();
    const { title, children, open, setOpen, onConfirm } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >

            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions classes={{ root: classes.leftAlignDialogActions }}>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                    color="white"
                >
                    { "Cancel"}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color="danger"
                >
                    {"Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteConfirmDialog;
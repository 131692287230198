import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { withRouter } from "react-router-dom";
import Settings from "../../lprfolders/GlobalConstant/Settings";
import LoaderComponent from "../../lprfolders/GlobalConstant/LoaderComponent";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from "@material-ui/icons/Info";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Alert from "../../lprfolders/GlobalConstant/Alert";
import axios from "axios";
import { HOST } from "../../lprfolders/config";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  root: {},
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class Edit extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.companyid = localStorage.getItem("companyid");
    this.cameraAccess = sessionStorage.getItem("camera");

    this.state = {
      flagError: false,
      netWorkError: false,
      erroertext: "",
      vehicleid: "",
      vehicle: "",
      flatno: "",
      fullname: "",
      mobile: "",
      comments: "",
      inputname: "",
      tc: false,
      bc: false,
      tenantTypeArray: [],
      tenantType: [],
      tenantid: "",
      cameraTypes: [],
      cameraTypeArray: [],
      camreaType: [],
      cameraTypeIds: [],
      deletedCameraIds: [],
      cameraSelect: false,
      vehiclecameraids: [],
      updateCameraid: [],
      data: this.props.location.state.propdata,
      Email: "",
    };
  }
  componentDidMount() {
    this.getvehicledetails();

    let api = HOST + "/tenant/getall";
    axios
      .get(api, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({ tenantTypeArray: response.data });
        // this.setState({ floorTypes: response.data });
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          this.setState({
            netWorkError: true,
            loader: false,
            erroertext: Settings.netWorkError().errmessgae,
          });
          setTimeout(() => {
            this.setState({ netWorkError: false });
          }, 3000);
        }
        if (err.response) {
          if (err.response.status === 502) {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.Server().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
        }
      });

    // let api1 = HOST + "/camera/getall"
    // axios.get(api1, {
    //     headers: {
    //         "authToken": this.token,
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     }
    // }).then(response => {
    //     this.setState({ cameraTypes: [] });
    //     this.setState({ cameraTypeArray: response.data });
    //     var count = 0
    //     this.state.cameraTypeArray.map((camera) => {
    //         this.state.cameraTypeIds.push({ "cameraid": camera.cameraid, "camera": camera.camera, "checked": false })
    //         this.setState({ cameraTypeIds: this.state.cameraTypeIds })

    //     })

    //     console.log(this.state.updateCameraid)
    //     this.state.cameraTypeIds.map((cameras) => {

    //         this.state.updateCameraid.map((id) => {
    //             console.log(cameras.cameraid + " " + id);

    //             if (cameras.cameraid === id) {
    //                 cameras.checked = true
    //                 console.log(this.state.cameraTypeIds)

    //             }
    //         })
    //     })

    //     this.setState({ cameraTypeIds: this.state.cameraTypeIds })

    // }).catch(err => {
    //     if (err.message === "Network Error") {
    //         this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
    //         setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
    //     }
    //     if (err.response) {
    //         if (err.response.status === 502) {
    //             this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
    //             setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
    //         }
    //     }
    // })
  }
  getvehicledetails = () => {
    this.setState({ loader: true });
    let api =
      HOST + "/vehicle/get/" + this.props.location.state.propdata.vehicleid;
    axios
      .get(api, {
        headers: {
          authToken: this.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        var camid = [];
        var vehiclecamid = [];
        response.data[0].cameraids.forEach((data) => {
          vehiclecamid.push(data.vehiclecameraid);
        });
        response.data[0].cameraids.forEach((data) => {
          camid.push(data.cameraid);
        });

        this.setState({
          vehiclecameraids: vehiclecamid,
          updateCameraid: camid,
        });
        this.setState({
          vehicleid: response.data[0].vehicleid,
          vehicle: response.data[0].vehicle,
          flatno: response.data[0].flatno,
          fullname: response.data[0].fullname,
          mobile: response.data[0].mobile,
          comments: response.data[0].comments,
          tenantid:
            response.data[0].tenantid === null ? "" : response.data[0].tenantid,
          camreaType: response.data[0].camera,
          Email: response.data[0].email,
          // vehiclecameraids: response.data[0].vehiclecameraids,
          // updatevehicleCameraid: response.data[0].cameraids,
        });
        this.setState({ loader: false });
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          this.setState({
            netWorkError: true,
            loader: false,
            erroertext: Settings.netWorkError().errmessgae,
          });
          setTimeout(() => {
            this.setState({ netWorkError: false });
          }, 3000);
        }
        if (err.response) {
          if (err.response.status === 502) {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.Server().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
        }
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.updateCameraid !== this.state.updateCameraid) {
      let api1 = HOST + "/camera/getall";
      axios
        .get(api1, {
          headers: {
            authToken: this.token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.setState({ cameraTypes: [] });
          this.setState({ cameraTypeArray: response.data });
          var count = 0;
          this.state.cameraTypeArray.map((camera) => {
            this.state.cameraTypeIds.push({
              cameraid: camera.cameraid,
              camera: camera.camera,
              checked: false,
            });
            this.setState({ cameraTypeIds: this.state.cameraTypeIds });
          });

          this.state.cameraTypeIds.map((cameras) => {
            this.state.updateCameraid.map((id) => {
              if (cameras.cameraid === id) {
                cameras.checked = true;
              }
            });
          });

          this.setState({ cameraTypeIds: this.state.cameraTypeIds });
        })
        .catch((err) => {
          if (err.message === "Network Error") {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.netWorkError().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
          if (err.response) {
            if (err.response.status === 502) {
              this.setState({
                netWorkError: true,
                loader: false,
                erroertext: Settings.Server().errmessgae,
              });
              setTimeout(() => {
                this.setState({ netWorkError: false });
              }, 3000);
            }
          }
        });
    }
  }
  add = () => {
    let api = HOST + "/vehicle/update";
    let body = {
      vehicleid: this.state.vehicleid,
      vehicle: this.state.vehicle,
      flatno: this.state.flatno,
      fullname: this.state.fullname,
      mobile: this.state.mobile,
      tenantid: this.state.tenantid,
      vehiclecameraids: this.state.vehiclecameraids,
      cameraids: this.state.cameraTypeIds,
      comments: this.state.comments,
      email: this.state.Email,
    };
    let count = 1;
    if (this.state.comments === "") {
      count--;
    }
    if (this.state.comments) {
      if (Settings.Comments(this.state.comments).status) {
        count--;
      } else {
        count++;
        this.setState({
          flagError: true,
          erroertext: Settings.Comments(this.state.comments).errmessgae,
          inputname: Settings.Comments(this.state.comments).inputname,
        });
        setTimeout(() => {
          this.setState({ flagError: false });
        }, 3000);
      }
    }
    if (Settings.FlatNo(this.state.flatno).status) {
      if (Settings.Vehicle(this.state.vehicle).status) {
        if (Settings.PersonName(this.state.fullname).status) {
          if (Settings.mobilenumber(this.state.mobile).status) {
          } else {
            count++;
            this.setState({
              flagError: true,
              erroertext: Settings.mobilenumber(this.state.mobile).errmessgae,
              inputname: Settings.mobilenumber(this.state.mobile).inputname,
            });
            setTimeout(() => {
              this.setState({ flagError: false });
            }, 3000);
          }
        } else {
          count++;
          this.setState({
            flagError: true,
            erroertext: Settings.PersonName(this.state.fullname).errmessgae,
            inputname: Settings.PersonName(this.state.fullname).inputname,
          });
          setTimeout(() => {
            this.setState({ flagError: false });
          }, 3000);
        }
      } else {
        count++;
        this.setState({
          flagError: true,
          erroertext: Settings.Vehicle(this.state.vehicle).errmessgae,
          inputname: Settings.Vehicle(this.state.vehicle).inputname,
        });
        setTimeout(() => {
          this.setState({ flagError: false });
        }, 3000);
      }
    } else {
      count++;
      this.setState({
        flagError: true,
        erroertext: Settings.FlatNo(this.state.flatno).errmessgae,
        inputname: Settings.FlatNo(this.state.flatno).inputname,
      });
      setTimeout(() => {
        this.setState({ flagError: false });
      }, 3000);
    }
    if (count === 0) {
      this.setState({ loader: true });
      axios
        .put(api, body, {
          headers: {
            authToken: this.token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 201) {
            this.setState({ loader: false });
            this.showNotification("tc");
            setTimeout(() => {
              this.props.history.push("/vehicle");
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.message === "Network Error") {
            this.setState({
              netWorkError: true,
              loader: false,
              erroertext: Settings.netWorkError().errmessgae,
            });
            setTimeout(() => {
              this.setState({ netWorkError: false });
            }, 3000);
          }
          if (err.response) {
            if (err.response.data.statusid === 409) {
              this.setState({
                flagError: true,
                loader: false,
                erroertext: Settings.Vehicle(409).errmessgae,
                inputname: Settings.Vehicle(409).inputname,
              });
              setTimeout(() => {
                this.setState({ flagError: false });
              }, 3000);
            }
            if (err.response.status === 502) {
              this.setState({
                netWorkError: true,
                loader: false,
                erroertext: Settings.Server().errmessgae,
              });
              setTimeout(() => {
                this.setState({ netWorkError: false });
              }, 3000);
            }
          }
        });
    }
  };
  handleChange = (event, values) => {
    if (values) {
      this.setState({
        tenantid: values.props.value,
      });
    }
  };

  selectCameras = (event, values) => {
    const {
      target: { value },
    } = event;
    this.setState({
      camreaType: typeof value === "string" ? value.split(",") : value,
    });
    if (values.props.children[0].props.checked === false) {
      if (
        this.state.cameraTypeIds.find((id) => id === values.props.Key) ===
        undefined
      ) {
        this.state.cameraTypeIds.push(values.props.Key);
      }
    } else {
      this.state.cameraTypeIds.find((value, index) => {
        if (value === values.props.Key) {
          this.state.deletedCameraIds.push(value);
          this.state.cameraTypeIds.splice(index, 1);
        }
      });
    }
    this.state.deletedCameraIds.forEach((deletedvalue, index) => {
      this.state.cameraTypeIds.forEach((value) => {
        if (value === deletedvalue) {
          this.state.deletedCameraIds.splice(index, 1);
        }
      });
    });
  };
  CheckboxhandleChange = (event, value) => {
    if (value === false) {
      this.state.cameraTypeIds.map((camera) => {
        if (event.target.id === camera.cameraid) {
          camera.checked = false;
        }
      });
    } else {
      this.state.cameraTypeIds.map((camera) => {
        if (event.target.id === camera.cameraid) {
          camera.checked = true;
        }
      });
    }

    this.setState({ cameraTypeIds: this.state.cameraTypeIds });
  };

  showNotification = (place) => {
    switch (place) {
      case "tc":
        if (!this.state.tc) {
          this.setState({ tc: true, erroertext: Alert.upadateSuccess });
          setTimeout(() => {
            this.setState({ tc: false });
          }, 4000);
        }
        break;
      case "bc":
        if (!this.state.bc) {
          this.setState({ bc: true });
          setTimeout(() => {
            this.setState({ bc: false });
          }, 4000);
        }
        break;
      default:
        break;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.tc ? (
          <Snackbar
            place="tc"
            color="success"
            icon={ThumbUpIcon}
            message={" Vehicle " + this.state.erroertext}
            open={this.state.tc}
            closeNotification={() => this.setState({ tc: false })}
            close
          />
        ) : (
          ""
        )}
        <GridContainer>
          <GridItem xs={12} sm={12} md={2}></GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  {Settings.titleCase("Edit vehicle record")}
                </h4>
                <p className={classes.cardCategoryWhite}>
                  {Settings.capitalizeFLetter(
                    "Update details pertaining to the vehicle"
                  )}
                </p>
              </CardHeader>
              <CardBody>
                {this.state.netWorkError ? (
                  <SnackbarContent
                    icon={InfoIcon}
                    message={this.state.erroertext}
                    // close
                    color="danger"
                  />
                ) : (
                  ""
                )}
                {this.state.loader ? <LoaderComponent /> : ""}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* EMP ID(max. 10 characters)"
                      id="Flat No"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          this.setState({ flatno: event.target.value });
                          if (
                            Settings.FlatNo(event.target.value).status ||
                            event.target.value === ""
                          ) {
                            this.setState({ flagError: false, erroertext: "" });
                          } else {
                            this.setState({
                              flagError: true,
                              erroertext: Settings.FlatNo(event.target.value)
                                .errmessgae,
                              inputname: Settings.FlatNo(event.target.value)
                                .inputname,
                            });
                          }
                        },
                        type: "text",
                        disabled: true,

                        value: this.state.flatno,
                        error:
                          (this.state.flagError &&
                            this.state.inputname === "FlatNo") ||
                          (this.state.requirederror && this.state.flatno === "")
                            ? true
                            : false,
                      }}
                    />
                    {this.state.requirederror && !this.state.flatno ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                    {this.state.flagError &&
                    this.state.inputname === "FlatNo" ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Vehicle Number(max. 10 characters)"
                      id="Vehicle Number"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          this.setState({ vehicle: event.target.value });
                          if (
                            Settings.Vehicle(event.target.value).status ||
                            event.target.value === ""
                          ) {
                            this.setState({ flagError: false, erroertext: "" });
                          } else {
                            this.setState({
                              flagError: true,
                              erroertext: Settings.Vehicle(event.target.value)
                                .errmessgae,
                              inputname: Settings.Vehicle(event.target.value)
                                .inputname,
                            });
                          }
                        },
                        onInput: (e) =>
                          (e.target.value = ("" + e.target.value)
                            .toUpperCase()
                            .toString()
                            .slice(0, 10)),
                        type: "text",
                        value: this.state.vehicle,
                        disabled: true,

                        error:
                          (this.state.flagError &&
                            this.state.inputname === "Vehicle") ||
                          (this.state.requirederror &&
                            this.state.vehicle === "")
                            ? true
                            : false,
                      }}
                    />
                    {this.state.requirederror && !this.state.vehicle ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                    {this.state.flagError &&
                    this.state.inputname === "Vehicle" ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Mobile Number(10 digits)"
                      id="Mobile"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          this.setState({ mobile: event.target.value });
                          if (
                            Settings.mobilenumber(event.target.value).status ||
                            !event.target.value
                          ) {
                            this.setState({ flagError: false, erroertext: "" });
                          } else {
                            this.setState({
                              flagError: true,
                              erroertext: Settings.mobilenumber(
                                event.target.value
                              ).errmessgae,
                              inputname: Settings.mobilenumber(
                                event.target.value
                              ).inputname,
                            });
                          }
                        },
                        onInput: (e) =>
                          (e.target.value = Math.max(
                            0,
                            parseInt(e.target.value)
                          )
                            .toString()
                            .slice(0, 10)),
                        type: "number",
                        disabled: true,

                        value: this.state.mobile,
                        error:
                          (this.state.flagError &&
                            this.state.inputname === "Mobile") ||
                          (this.state.requirederror && this.state.mobile === "")
                            ? true
                            : false,
                      }}
                    />
                    {this.state.requirederror && !this.state.mobile ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                    {this.state.flagError &&
                    this.state.inputname === "Mobile" ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Person Name(max. 20 characters)"
                      id="Person Name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          this.setState({ fullname: event.target.value });
                          if (
                            Settings.PersonName(event.target.value).status ||
                            event.target.value === ""
                          ) {
                            this.setState({ flagError: false, erroertext: "" });
                          } else {
                            this.setState({
                              flagError: true,
                              erroertext: Settings.PersonName(
                                event.target.value
                              ).errmessgae,
                              inputname: Settings.PersonName(event.target.value)
                                .inputname,
                            });
                          }
                        },
                        type: "text",
                        value: this.state.fullname,
                        error:
                          (this.state.flagError &&
                            this.state.inputname === "PersonName") ||
                          (this.state.requirederror &&
                            this.state.fullname === "")
                            ? true
                            : false,
                      }}
                    />
                    {this.state.requirederror && !this.state.fullname ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                    {this.state.flagError &&
                    this.state.inputname === "PersonName" ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} id="email">
                    <CustomInput
                      labelText="Email(Ex. admin@gmail.com)"
                      id="Email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          this.setState({ Email: event.target.value });
                          if (
                            Settings.Email(event.target.value).status ||
                            event.target.value === ""
                          ) {
                            this.setState({
                              flagError: false,
                              erroertext: "",
                            });
                          } else {
                            this.setState({
                              flagError: true,
                              erroertext: Settings.Email(event.target.value)
                                .errmessgae,
                              inputname: Settings.Email(event.target.value)
                                .inputname,
                            });
                          }
                        },
                        type: "text",
                        value: this.state.Email,

                        error:
                          this.state.flagError &&
                          this.state.inputname === "Email"
                            ? true
                            : false,
                      }}
                    />
                    {this.state.requirederror && !this.state.Email ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                    {this.state.flagError &&
                    this.state.inputname === "Email" ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Comments(max. 25)"
                      id="Comments"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          this.setState({ comments: event.target.value });
                          this.setState({ fullname: event.target.value });
                          if (
                            Settings.Comments(event.target.value).status ||
                            event.target.value === ""
                          ) {
                            this.setState({ flagError: false, erroertext: "" });
                          } else {
                            this.setState({
                              flagError: true,
                              erroertext: Settings.Comments(event.target.value)
                                .errmessgae,
                              inputname: Settings.Comments(event.target.value)
                                .inputname,
                            });
                          }
                        },
                        type: "text",
                        value: this.state.comments,
                        error:
                          this.state.flagError &&
                          this.state.inputname === "Comments"
                            ? true
                            : false,
                      }}
                    />
                    {this.state.flagError &&
                    this.state.inputname === "Comments" ? (
                      <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      />
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>

                  {/* 
                                    {sessionStorage.getItem("role") === "IsManager" && sessionStorage.getItem("tenant") === "true" ? < GridItem xs={12} sm={12} md={6}>
                                        <FormControl color="primary" style={{ width: "100%", height: "60%", marginTop: 28 }}>
                                            <InputLabel id="demo-simple-select-helper-label">* Tenant</InputLabel>

                                            <Select

                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.tenantid}
                                                onChange={this.handleChange}
                                                color='green'
                                                style={{ fontSize: 15 }}                            >
                                                {this.state.tenantTypeArray.map((entryCriteria, index) => (
                                                    <MenuItem value={entryCriteria.tenantid}>{entryCriteria.tenant}</MenuItem>

                                                ))}
                                            </Select>
                                            {this.state.requirederror && !this.state.tenantid ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </FormControl>
                                    </GridItem>

                                        : ""} */}
                  {this.cameraAccess === "true" ? (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ color: "primary", marginTop: 25 }}
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        * Gate access
                      </InputLabel>
                      {this.state.cameraTypeIds.map((entryCriteria, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={this.CheckboxhandleChange}
                              id={entryCriteria.cameraid}
                              checked={entryCriteria.checked}
                              style={{ color: "green" }}
                            />
                          }
                          label={entryCriteria.camera}
                        ></FormControlLabel>
                      ))}
                      {this.state.requirederror &&
                      !this.state.cameraTypeIds.filter(
                        (camera) => camera.checked === true
                      ).length >= 1 ? (
                        <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        />
                      ) : (
                        ""
                      )}
                    </GridItem>
                  ) : (
                    ""
                  )}
                  <GridItem xs={12} sm={12} md={4}></GridItem>
                  <GridItem xs={12} sm={12} md={4}></GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <GridContainer>
                  {}
                  <GridItem xs={12} sm={12} md={5}>
                    {this.state.vehicle !== "" &&
                    this.state.flatno !== "" &&
                    this.state.mobile !== "" &&
                    this.state.fullname !== "" &&
                    this.state.cameraTypeIds.filter(
                      (camera) => camera.checked === true
                    ).length >= 1 ? (
                      <Button color="primary" onClick={this.add}>
                        {" "}
                        <DoneOutlineIcon />
                        Save
                        <span
                          style={{ display: "inline-block", width: "21px" }}
                        ></span>
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          if (
                            Settings.requireerrors([
                              this.state.vehicle,
                              this.state.flatno,
                              this.state.mobile,
                              this.state.fullname,
                              this.state.cameraTypeIds,
                            ]).status === true
                          ) {
                            this.setState({
                              requirederror: true,
                              erroertext: Settings.requireerrors([
                                this.state.vehicle,
                                this.state.flatno,
                                this.state.mobile,
                                this.state.fullname,
                                this.state.cameraTypeIds,
                              ]).errmessgae,
                            });
                            setTimeout(() => {
                              this.setState({ requirederror: false });
                            }, 3000);
                          }
                        }}
                      >
                        {" "}
                        <DoneOutlineIcon />
                        Save
                        <span
                          style={{ display: "inline-block", width: "21px" }}
                        ></span>
                      </Button>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/vehicle/view",
                          state: {
                            propdata: this.props.location.state.propdata,
                          },
                        });
                      }}
                    >
                      <CancelPresentationIcon />
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}></GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Edit));

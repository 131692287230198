
import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import moment from 'moment'
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import Settings from '../../lprfolders/GlobalConstant/Settings';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Alert from '../../lprfolders/GlobalConstant/Alert'
import BackupIcon from '@material-ui/icons/Backup';
import { Icon } from '@iconify/react';
import fileExcel from '@iconify/icons-mdi/file-excel';
import Table from "../../components/Bulktable/BulkTable";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import XLSX from 'xlsx';
import DateFnsUtils from '@date-io/date-fns';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class BulkUpload extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            companyname: '',
            mobile: '',
            email: '',
            website: '',
            companyurl: '',
            ctype: '',
            results: [],
            flagError3: false,
            loader: false,
            addButton: true,
            facebookurl: "",
            password: "",
            firstname: "",
            lastname: "",
            instagramurl: "",
            imageURL: "",
            twitterurl: "",
            zomatourl: "",
            linkedinurl: "",
            google: "",
            sms: "",
            ratingurl: "",
            selectedFile: '',
            companytype: "",
            companytypesarray: [],
            roletypesarray: [],
            roletype: "",
            success: false,
            requirederror: false,
            flagError: false,
            erroertext: "",
            inputname: "",
            tc: false,
            bc: false,
            imageuploadbutton: false,
            netWorkError: false,
            xlcoloums: ["LICENSE PLATE NO", "VISITOR NAME", "MOBILE NUMBER", "TO MEET", "COMMENTS"],
            emptystate: false,
            showtable: false,
            headerror: false,
            fileformaterror:false,
            data: [],
            mainarray: [],
            errorindex: [],
            mainfinalarry: [],
            BulkUploadButton: false,
            tenantTypeArray: [],
            tenantid: "",
            tenantname: "",
            selectedDate: new Date(),
            uploadfile: (sessionStorage.getItem("role") === "IsManager" && sessionStorage.getItem("tenant") === "true" ? false : true)

        }
    }

    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    export_table_to_csv = (html, filename) => {
        var csv = [];
        var cols = [];
        if (filename === "samplexlsheet.csv") {
            for (var j = 0; j < this.state.xlcoloums.length; j++) {
                if (this.state.xlcoloums[j].name !== "action") {
                    cols.push(this.state.xlcoloums[j])
                }
            }
            csv.push(cols.join(","));
            this.download_csv(csv.join("\n"), filename);
        }
    }
    download_csv = (csv, filename) => {
        var csvFile;
        var downloadLink;
        csvFile = new Blob([csv], { type: "text/csv" });
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    handleFile(event) {
        if (event.target.files[0].name.split('.')[1] === 'csv') {
            let reader = new FileReader();
            let rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", cellDates: true });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname]
                const data = XLSX.utils.sheet_to_json(ws, {
                    defval: ""
                });
                let ex = JSON.parse(JSON.stringify(data).replace(/\s(?=\w+":)/g, ""))
                let stringjson = JSON.parse(JSON.stringify(ex).replace(/\s(?=\w+":)/g, ""))
                if (data.length === 0) {
                    this.setState({ emptystate: true, showtable: true, })
                } else {
                    let finalarray = []
                    let mainfinalarry = []
                    // stringjson.filter(obj => {
                    //     return finalarray.push(Object.assign({
                    //         vehicle: String(obj.LICENSEPLATENO).replace(/\s+/g, ''),
                    //         mobile: String(obj.MOBILENUMBER).replace(/\s+/g, ''), 
                    //         tomeet: String(obj.TOMEET).replace(/\s+/g, ''),
                    //         visitor: String(obj.VISITORNAME).replace(/\s+/g, ''), 
                    //         statusid: String(obj.STATUS).replace(/\s+/g, ''),
                    //         expectedtime: String(moment(obj.INTIME).format("hh:mm A")).replace(/\s+/g, '')
                    //     }))

                    // })
                    stringjson.filter(obj => {
                        return mainfinalarry.push(Object.assign({
                            vehicle: String(obj.LICENSEPLATENO).replace(/\s+/g, ''),
                            mobile: String(obj.MOBILENUMBER).replace(/\s+/g, ''),
                            tomeet: String(obj.TOMEET).replace(/\s+/g, ''),
                            visitor: String(obj.VISITORNAME).replace(/\s+/g, ''),
                            tenantid: this.state.tenantid,
                            statusid: 1,
                            expectedtime: moment(this.state.selectedDate).format("YYYY-MM-DD"),
                        }))
                    })
                    this.setState({ emptystate: false, showtable: true, mainarray: finalarray, mainfinalarry: mainfinalarry })
                    if (Settings.BulkuploadHedearCheck(data, this.state.xlcoloums).status) {
                        if (Settings.BulkuploadVisitorsDatacheck(stringjson, this.state.xlcoloums).status) {
                            this.setState({ BulkUploadButton: true })
                        } else {
                            this.setState({
                                errorindex: Settings.BulkuploadVisitorsDatacheck(stringjson, this.state.xlcoloums).errorindex,
                                flagError: true, loader: false, showtable: true, erroertext: Settings.BulkuploadVisitorsDatacheck(stringjson, this.state.xlcoloums).errmessgae,
                            })
                            setTimeout(() => { this.setState({ flagError: false }) }, 6000);
                        }
                    } else {
                        this.setState({ flagError: true, headerror: true, mainarray: finalarray, showtable: true, loader: false, erroertext: Settings.BulkuploadHedearCheck(data, this.state.xlcoloums).errmessgae })
                        setTimeout(() => { this.setState({ flagError: false }) }, 10000);
                    }
                }
            }
            if (rABS) reader.readAsBinaryString(event.target.files[0])
            else reader.readAsArrayBuffer(event.target.files[0])
        } else {
            this.setState({ fileformaterror:true, showtable: false, loader: false, erroertext: "File must be .csv extention".errmessgae })
            setTimeout(() => { this.setState({ flagError: false }) }, 10000);
        }


    }
    bulkupload = () => {
        this.setState({ loader: true })
        let api = HOST + "/visitor/bulk"
        axios.post(api, this.state.mainfinalarry, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
            }
        }).then(res => {

            if (res.status === 201) {
                this.setState({ loader: false })
                this.showNotification("tc")
                setTimeout(() => { this.props.history.push('/visitor') }, 2000);
            }
        }).catch(err => {
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 10000)
                }
                if (err.response.status === 408) {
                    this.setState({ netWorkError: true, loader: false, erroertext: err.response.data.error })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })
    }
    componentDidMount() {
        let api = HOST + "/tenant/getall"
        axios.get(api, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            this.setState({ tenantTypeArray: response.data });
        }).catch(err => {
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })

    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    handleChange = (event, values) => {
        if (values) {
            this.setState({
                tenantid: values.props.value,
                tenantname: values.props.name
            });
        }

    }
    handleDateChange = (date) => {
        this.setState({ selectedDate: date });
    };

    render() {
        const { classes } = this.props
        return (
            <div>
                <GridContainer>
                    {this.state.tc ? <Snackbar
                        place="tc"
                        color="success"
                        icon={ThumbUpIcon}
                        message={" Visitors " + this.state.erroertext}
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    /> : ""}

                    <GridItem xs={12} sm={12} md={4}>
                        <Card>
                            <CardHeader color="primary">
                                {window.sessionStorage.getItem("tenant") === "true" && window.sessionStorage.getItem("role") === "IsManager" ? <>  <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Select Tenant and Date")}</h4>
                                    <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Select tenant and Date for Adding multiple Visitors ")}</p>
                                </>
                                    :
                                    <>  <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Select Date")}</h4>
                                        <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Select Date for Adding multiple Visitors ")}</p>
                                    </>}
                            </CardHeader>
                            <CardFooter>
                                <GridContainer>
                                    {window.sessionStorage.getItem("tenant") === "true" && window.sessionStorage.getItem("role") === "IsManager" ? <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                                        <FormControl color="primary" style={{ width: 250, height: "60%", marginBottom: 25 }}>
                                            <InputLabel id="demo-simple-select-helper-label">* Tenant</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.tenantid}
                                                onChange={this.handleChange}
                                                color='green'
                                                style={{ fontSize: 15 }}                            >
                                                {this.state.tenantTypeArray.map((entryCriteria, index) => (
                                                    <MenuItem value={entryCriteria.tenantid} name={entryCriteria.tenant}>{entryCriteria.tenant}</MenuItem>

                                                ))}
                                            </Select>
                                            {this.state.requirederror && !this.state.tenantid ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </FormControl>

                                        {/* {this.state.flagError && this.state.inputname === "PersonName" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""} */}
                                    </GridItem> : ""}
                                    <GridItem xs={12} sm={12} md={12}>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                style={{ color: 'green' }}
                                                margin="normal"
                                                color="primary"
                                                id="date-picker-dialog"
                                                label="Visit Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.selectedDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={new Date()}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>

                                    {window.sessionStorage.getItem("tenant") === "true" && window.sessionStorage.getItem("role") === "IsManager" ? <GridItem xs={12} sm={12} md={5}>
                                        {this.state.tenantid !== "" ?
                                            <Button color="primary"
                                                onClick={() => {
                                                    this.setState({ uploadfile: true })
                                                }}
                                            >
                                                Select
                                                <span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                            <Button onClick={() => {
                                                if (Settings.requireerrors([this.state.tenantid]).status === true) {
                                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.tenantid, this.state.cameraTypeIds]).errmessgae })
                                                    setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                }
                                            }}> Select<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                        }
                                    </GridItem> : ""}
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>




                    {this.state.uploadfile ? <GridItem xs={12} sm={12} md={5}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Bulk Upload data")}</h4>
                                <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Download template to adding new visitors.")}</p>
                            </CardHeader>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button color="info" onClick={() => this.export_table_to_csv("", "samplexlsheet.csv")}><Icon icon={fileExcel} />Download Template</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        {/* <button className="addvehiclebutton" onClick={() => {
                                            this.setState({ showtable: false })
                                            document.getElementById("file").click()
                                        }}><i className="fa fa-cloud-upload" style={{ fontSize: "20px", }}></i> Bulk upload</button>
                                        <input id="file" hidden type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(event) => {
                                            this.handleFile(event)
                                           
                                            event.target.value = ''
                                        }
                                        } /> */}
                                        <input id="file" hidden type="file" accept=".csv" onChange={(event) => {
                                            this.handleFile(event)
                                            this.setState({ showtable: false, mainarray: [], BulkUploadButton: false, errorindex: [], erroertext: "" })
                                            event.target.value = null;
                                        }
                                        } />
                                        <Button color="primary" onClick={() => {
                                            this.setState({ showtable: false, mainarray: [], BulkUploadButton: false, errorindex: [], erroertext: "" })
                                            document.getElementById("file").click()
                                        }}><span style={{ display: "inline-block", width: "28px" }}></span><BackupIcon />Upload File<span style={{ display: "inline-block", width: "28px" }}></span></Button>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem> : ""}

                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>

                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>

                    </GridItem>
                    <GridItem xs={12} sm={6} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={2}>
                        {this.state.BulkUploadButton ? <div style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: "center" }}>
                            <Button color="primary" onClick={() => {
                                this.bulkupload()
                            }}><span style={{ display: "inline-block", width: "15px" }}></span><DoneOutlineIcon />Save<span style={{ display: "inline-block", width: "15px" }}></span></Button>
                        </div> : ""}
                    </GridItem>
                </GridContainer>
                {this.state.fileformaterror ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={"File Must be extention of '.csv'"}
                                    // close
                                    color="danger"
                                /> : ""}
                {this.state.showtable ? <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> {Settings.titleCase("Vehicle Details")}</h4>
                                <p className={classes.cardCategoryWhite}>
                                    {Settings.capitalizeFLetter("Results are shown below")}
                                </p>
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                {this.state.emptystate ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={"No records found"}
                                    // close
                                    color="danger"
                                /> : ""}
                                <Table
                                    tableHeaderColor="success"
                                    tableHead={
                                        [
                                            // {
                                            //     name: "STATUS",
                                            //     prop: "statusid"
                                            // },
                                            {
                                                name: "LICENSE PLATE NO",
                                                prop: "vehicle"
                                            },
                                            {
                                                name: "VISITOR NAME",
                                                prop: "visitor"
                                            },
                                            {
                                                name: "MOBILE NUMBER",
                                                prop: "mobile"
                                            },
                                            {
                                                name: "TO MEET",
                                                prop: "tomeet"
                                            },
                                            {
                                                name: "COMMENTS",
                                                prop: "comments"
                                            },

                                            // {
                                            //     name: "HOST NAME",
                                            //     prop: "hostname"
                                            // },
                                            // {
                                            //     name: "COMPANY",
                                            //     prop: "company"
                                            // },
                                            // {
                                            //     name: "PURPOSE",
                                            //     prop: "purpose"
                                            // },
                                            // {
                                            //     name: "IN TIME",
                                            //     prop: "intime"
                                            // },
                                        ]}
                                    tableData={this.state.mainfinalarry}
                                    routename="vehicle"
                                    erroertext={this.state.erroertext}
                                    flagError={this.state.flagError}
                                    headerror={this.state.headerror}
                                    errorindex={this.state.errorindex}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    </GridItem>
                </GridContainer> : ""}
            </div>
        );
    }
}

export default withRouter((withStyles(styles)(BulkUpload)));


import { withStyles } from "@material-ui/core/styles";
import DateRange from "@material-ui/icons/DateRange";
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "Grids/Dashboard";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Component } from 'react';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Icon } from '@iconify/react';
import cctvIcon from '@iconify/icons-mdi/cctv';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import Settings from '../../lprfolders/GlobalConstant/Settings';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Tooltip } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { HOST } from '../../lprfolders/config';
import axios from 'axios';
import { set, ref } from "firebase/database";
import { db } from "../../firebaseConfig";
import ReactSpeedometer from "react-d3-speedometer";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem('token');

    this.count = 1;
    this.state = {
      vehicles: [],
      invehiclescount: 0,
      outvehiclescount: 0,
      umatchcount: 0,
      latestvehicle: null,
      modalIsOpen3: false,
      imageviewindex: 0,
      empty: false,
      postperpage: 10,   //state assign
      currentpost: 1,
      indexperpage: 0,
      newPage: 0,
      newPage1: 10,
      loader: false,
      loader1: false,
      extraCount: 0,
      netWorkError: false,
      direction: "",
      eventtime: "",
      eventdate: "",
      fullimagepath: "",
      CurrentDate: new Date(),
      imageCheck: false,
      parkingOpen: false,
      availablecount: 0,
      capacity: "",
      parkedcount: "",
      changesOn: "false",
      errorcount: 0,
      online: window.navigator.onLine,
      todos: "",
      count: "",
      countdata: [],
      name: window.localStorage.getItem('company'),
      uuid: "",
      changedcount: "",
      carsCount: 0,
      cameraTypeArray: [],
      cameracode: "",
      reloadtime: 3

    }
  }


  componentDidMount() {

    window.addEventListener('offline', this.handleNetworkChange);
    window.addEventListener('online', this.handleNetworkChange);
    // onValue(ref(db), (snapshot) => {

    //   const array = []
    //   const data = snapshot.val();
    //   console.log(data)

    //   if (data !== null) {
    //     Object.values(data).map((todo) => {
    //       //console.log(todo.name === this.state.name)
    //       if (todo.name === this.state.name) {
    //         console.log(todo)
    //         this.setState({
    //           count: todo.count,
    //           uuid: todo.uuid
    //         })
    //         console.log(this.state.count + " " + this.state.uuid)

    //       }
    //     });
    //   }
    // });

    let api1 = HOST + "/camera/getall"
    axios.get(api1, {
      headers: {
        "authToken": this.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      this.setState({ cameraTypes: [] });
      this.setState({ cameraTypeArray: response.data });



    }).catch(err => {
      if (err.message === "Network Error") {
        this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
      }
      if (err.response) {
        if (err.response.status === 502) {
          this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
          setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
        }
      }
    })
  }
  n = (num, len = 2) => {
    return `${num}`.padStart(len, '0');
  }
  writeToDatabase = (countss) => {
    if (countss.toString().length >= 4 || countss <= 0) {
      countss = 0;
    }
    let num = this.n((countss), 3)

    set(ref(db), {
      DATA: num.toString(),
      Gaido1001: this.state.countdata
    });

  };

  // handleUpdate = (count) => {
  //   const name = this.state.name;
  //   const uuid = this.state.uuid

  //   update(ref(db, `/${name}`), {

  //     count,
  //     name
  //   });
  // };

  getCountDetails = () => {
    let api = HOST + '/' + "count" + '/getall'
    axios.get(api, {
      headers: {
        "authToken": this.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(response => {

      if (response.data !== "") {
        this.setState({
          // invehiclescount: response.data[0].parkedin,
          //outvehiclescount: response.data[0].parkedout,
          //umatchcount: response.data[0].unmatchcount,
          availablecount: response.data[0].available,
          capacity: response.data[0].capacity,
          parkedcount: response.data[0].parkedcount,
          carsCount: response.data[0].capacity - response.data[0].available

        })
        this.writeToDatabase(this.state.availablecount)

        //  this.handleUpdate(this.state.availablecount)

      }
    })

  }

  getlatestvehcileDetails = (eventdate, eventtime, outvehiclescount, umatchcount, latestvehicle, direction, fullimagepath, invehiclescount, loadersatate) => {
    this.setState({
      loader: loadersatate,
      eventdate: eventdate,
      eventtime: eventtime,
      latestvehicle: latestvehicle,
      direction: direction,
      fullimagepath: fullimagepath,
      // invehiclescount: invehiclescount,
      // outvehiclescount: outvehiclescount,
      // umatchcount: umatchcount,

      // imageCheck: imagevaluechek
    })
    localStorage.setItem("reloadcount", 1)
    if (this.state.name === "Gaido Office") {
      this.getCountDetails();
      this.setState({
        invehiclescount: invehiclescount,
        outvehiclescount: outvehiclescount,
        umatchcount: umatchcount,
      })
    } else {
      this.setState({
        invehiclescount: invehiclescount,
        outvehiclescount: outvehiclescount,
        umatchcount: umatchcount,
      })
    }

  }


  changeCameracode = (cameracode) => {
    this.setState({
      cameracode: cameracode
    })
  }
  customIsNaN(x) { return (typeof x === 'number' && x !== 0 && !x); }
  ReturunedImageFun = () => {

    // return !!pattern.test(str);

    // if (Alert.IMageURL.test(this.state.fullimagepath)) {
    //   return <img src={this.state.fullimagepath} alt="vehicle" style={{ width: "100%", }} />
    // } else {
    //   return <img src={require('../../img/noimage.png')} alt="vehicle" style={{ width: "100%", }} />
    // }
  }
  handleNetworkChange = () => {
    this.setState({ online: window.navigator.onLine });

  }
  updateCount = () => {
    let api = HOST + "/count/add"
    let pcount = ""
    if (this.state.changesOn === "true") {


      pcount = (-this.state.parkedcount)
      this.setState({ changedcount: pcount })


    } else {

      pcount = this.state.parkedcount
      this.setState({ changedcount: pcount })


    }
    let body =
    {
      parkedcount: pcount
    }
    //if (this.state.online) {

    axios.post(api, body, {
      headers: {
        "authToken": this.token,
        'Accept': 'application/json',
        // 'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.statusText === "OK") {
        this.setState({ loader: false })
        this.setState({
          parkingOpen: false
        })

        window.location.reload()


      }
    }).catch(err => {
      if (err.message === "Network Error") {
        this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
      }

      if (err.response.status === 502) {
        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
      }

    })
    //  }
    // else {
    //   this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
    //   setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
    // }
  }

  handleRadioChange = (event) => {

    this.setState({
      changesOn: event.target.value,
    })

  };
  incNum = () => {
    this.setState({
      extraCount: this.state.extraCount + 1
    })
  };
  decNum = () => {
    this.setState({
      extraCount: this.state.extraCount - 1
    })
  }
  refreshPage() {

    window.location.reload();

  }
  refreshTable = () => {

    localStorage.setItem("reloadcount", 0)
    setTimeout(this.refreshPage, 3000)

  }


  handleChange = (events, values) => {
    this.setState({
      cameracode: values.props.value
    });

  }
  render() {

    const { classes } = this.props;
    // if (this.state.imageCheck) return (<GridContainer>
    //   <GridItem xs={12} sm={12} md={12}>
    //   <img src={require('../../img/noimage.png')} alt="vehicle" style={{ width: "100%", }} />
    //   </GridItem>
    // </GridContainer>);
    const totalparkingCount = this.state.capacity;
    const spacesLeft = (this.state.invehiclescount - this.state.outvehiclescount - (this.state.parkedcount))
    const spacesLeftPercentage = (Math.ceil((spacesLeft / totalparkingCount) * 100));

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            {this.state.netWorkError ? <SnackbarContent
              icon={InfoIcon}
              message={this.state.erroertext}
              // close
              color="danger"
            /> : ""}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={9}>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            {sessionStorage.getItem("camera") === "true" ?
              <FormControl color="primary" style={{ width: "100%", height: "60%", marginBottom: "20px" }}>
                <InputLabel id="demo-simple-select-helper-label">* Camera</InputLabel>

                <Select

                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={this.state.cameracode}
                  onChange={this.handleChange}
                  color='green'
                  style={{ fontSize: 15 }}                            >
                  <MenuItem value="lprdata">ALL Cameras</MenuItem>
                  {this.state.cameraTypeArray.map((entryCriteria, index) => (

                    <MenuItem value={entryCriteria.cameracode}>{entryCriteria.camera}</MenuItem>

                  ))}
                </Select>
              </FormControl>

              : ""}

          </GridItem>

        </GridContainer>
        <GridContainer>

          <GridItem xs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <Icon icon={cctvIcon} flip="horizontal" />
                    </CardIcon>
                    {this.state.loader1 ? <LoaderComponent /> : ""}
                    <p className={classes.cardCategory}>{Settings.titleCase("In")}</p>
                    <h3 className={classes.cardTitle}>
                      {this.state.invehiclescount}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      {this.customIsNaN(Math.ceil(((this.state.invehiclescount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100)) === true ? "0 % " + Settings.capitalizeFLetter("of Total Vehicle Activity Today") : Math.ceil(((this.state.invehiclescount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100) + "% " + Settings.capitalizeFLetter("of Total Vehicle Activity Today")}
                      {/* { } */}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      < LocalTaxiIcon />
                    </CardIcon>
                    {this.state.loader1 ? <LoaderComponent /> : ""}
                    <p className={classes.cardCategory}>{Settings.titleCase("out")}</p>
                    <h3 className={classes.cardTitle}>{this.state.outvehiclescount}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      {this.customIsNaN(Math.ceil(((this.state.outvehiclescount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100)) === true ? "0 %  " + Settings.capitalizeFLetter("of Total Vehicle Activity Today") : Math.ceil(((this.state.outvehiclescount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100) + "% " + Settings.capitalizeFLetter("of Total Vehicle Activity Today")}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <CancelIcon />
                    </CardIcon>
                    {this.state.loader1 ? <LoaderComponent /> : ""}
                    <p className={classes.cardCategory}>{Settings.titleCase("Un matched")}</p>
                    <h3 className={classes.cardTitle}>{this.state.umatchcount}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      {this.customIsNaN(Math.ceil(((this.state.umatchcount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100)) === true ? "0 % " + Settings.capitalizeFLetter("of Total Vehicle are Unmatched") : Math.ceil(((this.state.umatchcount) / (this.state.invehiclescount + this.state.outvehiclescount)) * 100) + " % " + Settings.capitalizeFLetter("of Total Vehicles Activity are Unmatched")}
                    </div>
                  </CardFooter>
                </Card>

              </GridItem>
              {this.state.name === "Gaido Office" ? <GridItem xs={12} sm={12} md={6} >
                <Card>

                  <CardHeader color="primary">
                    <h6 className={classes.cardTitleWhite}>Available parking</h6>

                    {/* <h3 className={classes.cardTitle}>{this.state.availablecount === null ? "0" : this.state.availablecount}</h3> */}
                  </CardHeader>
                  <CardBody style={{ height: 100 }}>
                    <ReactSpeedometer
                      width={180}
                      segments={5}
                      value={parseInt(spacesLeftPercentage)}
                      minValue={0}
                      maxValue={100}
                      segmentColors={['#32CD32', '#7FFF00', '#ADFF2F', '#DFFF00', '#F04947']}
                      textColor="green"
                      currentValueText={`${spacesLeftPercentage} % Slots used`}
                      needleColor="steelblue"
                      needleTransitionDuration={4000}
                      needleTransition="easeElastic"
                    />
                  </CardBody>

                  <CardFooter style={{ height: 45 }} stats>
                    <div className={classes.stats}>
                      {/* {(this.state.invehiclescount - this.state.outvehiclescount - (this.state.parkedcount)) + Settings.capitalizeFLetter(" Vehicles are Parked")} */}
                      {this.state.availablecount === null ? "0" : this.state.availablecount} slots available of {this.state.capacity}

                    </div>
                    <Tooltip title={"Suggest Correction"} placement="bottom-start">
                      <EmojiObjectsIcon style={{ color: "#FDCC0D", cursor: "pointer" }}
                        onClick={() => {
                          this.setState({
                            parkingOpen: true
                          })
                        }}
                      />
                    </Tooltip>
                  </CardFooter>

                </Card>
              </GridItem> : ""}
              <Dialog
                // fullScreen={fullScreen}
                open={this.state.parkingOpen}
                onClose={() => this.setState({
                  parkingOpen: false
                })}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title" style={{ width: 500 }} onClose={() => this.setState({
                  parkingOpen: false
                })}>
                  {""}
                  <IconButton aria-label="close" style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  }} onClick={() => this.setState({
                    parkingOpen: false
                  })}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <GridContainer >
                    <Grid xs={12} sm={12} md={12}>
                      <Card style={{ border: "2px solid green" }} >
                        <CardHeader color="primary">
                          <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Parking count Correction")}</h4>
                        </CardHeader>
                        {this.state.netWorkError ? <SnackbarContent
                          icon={InfoIcon}
                          message={this.state.erroertext}
                          // close
                          color="danger"
                        /> : ""}
                        {this.state.loader ? <LoaderComponent /> : ""}

                        <CardBody >

                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl style={{ marginTop: 20 }}>
                                {/* <GridContainer>Parked Count:<Button style={{ height: 5, width: 2 }} onClick={this.decNum}><RemoveIcon /></Button>&nbsp;&nbsp;&nbsp;<b>{this.state.extraCount}</b>&nbsp;&nbsp;&nbsp;<Button style={{ height: 5, width: 2 }} onClick={this.incNum}><AddIcon /></Button></GridContainer> */}
                                <label id="demo-row-radio-buttons-group-label">
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-error-radios"
                                    name="Reseller"
                                    defaultValue={this.state.changesOn}

                                    onChange={this.handleRadioChange}
                                  >
                                    <h4>* Changes On :</h4>  &nbsp;&nbsp;&nbsp;&nbsp;
                                    <FormControlLabel value="true" control={<Radio color="success" />}

                                      label="In" />
                                    <FormControlLabel value="false" control={<Radio color="success" />}

                                      label="Out" />
                                  </RadioGroup></label>
                              </FormControl>
                              {this.state.requirederror && !this.state.changesOn ? <SnackbarContent
                                icon={InfoIcon}
                                message={this.state.erroertext}
                                // close
                                color="danger"
                              /> : ""}

                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>

                              <Card style={{ width: 250, height: 80 }}>
                                <CardHeader color="danger" stats icon>
                                  <CardIcon color="danger" style={{ height: 40, width: 40 }}>
                                    <p style={{ marginTop: 5, fontSize: 50, marginRight: 5 }}>P</p>
                                  </CardIcon>
                                  {this.state.loader1 ? <LoaderComponent /> : ""}
                                  <p className={classes.cardCategory}>{Settings.titleCase("Parked Count")}</p>
                                  <h3 className={classes.cardTitle}>{this.state.carsCount}</h3>
                                </CardHeader>

                              </Card></GridItem>
                            <GridItem xs={12} sm={12} md={6} style={{ marginTop: -30 }}>
                              <CustomInput
                                labelText=" *Parked Count"
                                id="count"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  onChange: (event) => {
                                    this.setState({ parkedcount: event.target.value })
                                  },
                                  onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3),
                                  type: "number",
                                  // error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
                                }}
                                style={{ border: "2px solid black" }}
                              />
                              {this.state.requirederror && !this.state.parkedcount ? <SnackbarContent
                                icon={InfoIcon}
                                message={this.state.erroertext}
                                // close
                                color="danger"
                              /> : ""}

                            </GridItem>

                          </GridContainer>


                        </CardBody>
                        <CardFooter>

                          <GridContainer>


                            <GridItem xs={12} sm={12} md={5}>
                              {this.state.changesOn !== "" && this.state.parkedcount ?
                                <Button color="primary" onClick={this.updateCount}> Update<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                <Button onClick={() => {
                                  if (Settings.requireerrors([this.state.changesOn !== "" && this.state.parkedcount]).status === true) {
                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.changesOn !== "" && this.state.parkedcount]).errmessgae })
                                    setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                  }
                                }}>Update<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                              }
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4}>
                            </GridItem>
                          </GridContainer>
                        </CardFooter>
                      </Card>
                    </Grid>
                  </GridContainer>
                </DialogContent>
              </Dialog>
              {/* {this.state.name === "Gaido Office" ? <GridItem xs={12} sm={12} md={6}>

                <Card style={{ height: 150, padding: 20, width: 200 }}>
                  <ReactSpeedometer
                    width={200}
                    segments={5}
                    value={parseInt(spacesLeftPercentage)}
                    minValue={0}
                    maxValue={100}
                    segmentColors={['#32CD32', '#7FFF00', '#ADFF2F', '#DFFF00', '#F04947']}
                    textColor="green"
                    currentValueText={`${spacesLeftPercentage} % Slots used`}
                    needleColor="steelblue"
                    needleTransitionDuration={4000}
                    needleTransition="easeElastic"
                  />
                </Card>
              </GridItem> : ""} */}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Most recent activity")}</h4>
                {this.state.direction === "ENTRY" ? <p className={classes.cardCategoryWhite}> <LocalTaxiIcon className={classes.upArrowCardCategory} />
                  {Settings.CapitlizeCase(this.state.latestvehicle)}
                  {" "}  <TransitEnterexitIcon className={classes.upArrowCardCategory} />
                  {" "} {this.state.direction}
                  {/* {" "} <FiberManualRecordRoundedIcon style={{color:"red"}} /> */}
                </p> : <p className={classes.cardCategoryWhite}>
                  <LocalTaxiIcon className={classes.upArrowCardCategory} /> {this.state.latestvehicle}
                  {" "}  <ExitToAppIcon className={classes.upArrowCardCategory} />
                  {" "}{this.state.direction}
                  {/* {" "} <FiberManualRecordRoundedIcon style={{color:"red"}} /> */}
                </p>}

              </CardHeader>

              <CardBody>
                {this.state.loader ? <LoaderComponent /> : ""}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.state.loader ? null : <img src={
                      this.state.fullimagepath
                      // "https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/fullimage_KA41C1.jpg"
                    } alt="vehicle" style={{ width: "100%" }}
                      onError={(e) => {
                        e.target.src = "https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/noimage.png"
                        if (this.state.fullimagepath && localStorage.getItem("reloadcount") === "1") {
                          this.refreshTable()
                          // window.location.reload()
                        } else {
                          e.target.src = "https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/noimage.png"
                        }

                        // this.setState({
                        //   errorcount: this.state.errorcount++
                        // })
                        // console.log(this.state.errorcount)
                        // e.target.src = "https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/noimage.png"
                        // if (this.state.errorcount == 1) {

                        //   console.log(this.state.errorcount)
                        //   window.location.reload()
                        // }
                        // this.setState({
                        //   errorcount: this.state.errorcount++
                        // })
                      }}
                    />}
                    {/* 
                    {this.state.loader ? null : this.state.fullimagepath==="" ? <img src="https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/noimage.png" alt="vehicle" style={{ width: "100%" }}/> : <img src={
                      this.state.fullimagepath
                      // "https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/fullimage_KA41C1.jpg"
                    } alt="vehicle" style={{ width: "100%" }}/>} */}

                    {/* {this.state.loader ? null : this.ReturunedImageFun()} */}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <DateRange />  {this.state.eventdate}
                  <AccessTimeIcon /> {this.state.eventtime}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>

          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            {/* <ReactSpeedometer
              segments={5}
              value={parseInt(spacesLeftPercentage)}
              minValue={0}
              maxValue={100}
              segmentColors={['#138808', '#FBB454', '#F32424']}
              textColor="green"
              currentValueText={`${spacesLeftPercentage} % Space Used`}


              needleColor="steelblue"
              needleTransitionDuration={4000}
              needleTransition="easeElastic"
            /> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            {/* <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={4}> </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Today's activity")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {Settings.capitalizeFLetter("vehicle movement on")} {moment(this.state.CurrentDate).format("DD-MM-YYYY")}
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="success"
                  tableHead={[
                    {
                      name: "S.No",
                      prop: "s.no"
                    },
                    {
                      name: "Match",
                      prop: "db_match"
                    },
                    {
                      name: "Vehicle Number",
                      prop: "reading"
                    },
                    {
                      name: "Direction",
                      prop: "camera"
                    },
                    // {
                    //   name: "Direction",
                    //   prop: "direction"
                    // },
                    // {
                    //   name: "Vehicle Type",
                    //   prop: "vehicle_category"
                    // },
                    {
                      name: "Speed",
                      prop: "speed"
                    },
                    {
                      name: "Time",
                      prop: "eventtime"
                    },
                    {
                      name: "Vehicle Image",
                      prop: "fullimagepath"
                    },
                    {
                      name: "Visitor",
                      prop: "visitoradd",
                    }
                  ]}
                  vehicleLatestVehcileDetails={this.getlatestvehcileDetails}
                  tableData={this.state.vehicles}
                  routename="lpr"
                  cameracode={this.state.cameracode}
                  changeCameracode={this.changeCameracode}
                />
              </CardBody>
            </Card>

          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);


import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "Grids/Visitor";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import React, { Component } from 'react';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
// npm install --save-dev @iconify/react @iconify/icons-mdi
import { Icon } from '@iconify/react';
import Button from "components/CustomButtons/Button.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import accountGroup from '@iconify/icons-mdi/account-group';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import humanQueue from '@iconify/icons-mdi/human-queue';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
// import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
// import axios from 'axios';
// import { HOST } from '../../lprfolders/config';
import moment from 'moment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Settings from '../../lprfolders/GlobalConstant/Settings';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssessmentIcon from '@material-ui/icons/Assessment';
import fileExcel from '@iconify/icons-mdi/file-excel';

import { grey } from "@material-ui/core/colors";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem('token');
    this.state = {
      addcompany: false,
      open: false,
      openstatus: false,
      checkedout: 0,
      checkedincount: 0,
      expectedcount: 0,
      CurrentDate: new Date(),
    }
  }

  getallvisitorscount = (data, data1, data2, loadersatate) => {
    this.setState({ checkedincount: data, checkedout: data1, expectedcount: data2, loader: loadersatate })
  }
  customIsNaN(x) { return (typeof x === 'number' && x !== 0 && !x); }
  render() {

    const { classes } = this.props;
    return (
      <div>
        <Dialog
          // fullScreen={fullScreen}
          open={this.state.openstatus}
          onClose={() => {
            this.setState({ openstatus: false })
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <WarningIcon style={{ color: "#00acc1", fontSize: "180px" }} />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <h6 className={classes.cardTitle}>
                  Are you sure ?
                </h6>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <p className={classes.cardCategory}>You will not be able recover!</p>

              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Button color="primary" onClick={() => {
                  this.setState({ openstatus: false })
                }}>No</Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={1}>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Button color="danger" onClick={() => {
                  this.setState({ openstatus: false })
                }}>Yes</Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>

              </GridItem>
            </GridContainer>
          </DialogTitle>
        </Dialog>
        <Dialog
          // fullScreen={fullScreen}
          open={this.state.open}
          onClose={() => {
            this.setState({ open: false })
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <DeleteIcon style={{ color: "red", fontSize: "180px" }} />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <h6 className={classes.cardTitle}>
                  Are you sure ?
                </h6>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <p className={classes.cardCategory}>You will not be able to recover this information once it is deleted</p>

              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Button color="primary" onClick={() => {
                  this.setState({ open: false })
                }}>No</Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={1}>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Button color="danger" onClick={() => {
                  this.setState({ open: false })
                }}>Yes</Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>

              </GridItem>
            </GridContainer>
          </DialogTitle>
        </Dialog>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ height: 100 }}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon icon={humanQueue} width="120" height="120" />
                </CardIcon>
                {this.state.loader ? <LoaderComponent /> : ""}
                <p className={classes.cardCategory}>{Settings.titleCase("Expected visitors")}</p>
                <h3 className={classes.cardTitle}>{this.state.expectedcount}</h3>
              </CardHeader>
              {/* <CardFooter stats>
                <div className={classes.stats}>
                  <ArrowUpwardIcon />
                  {this.customIsNaN(Math.ceil((this.state.expectedcount / (this.state.checkedincount + this.state.expectedcount + this.state.checkedout)) * 100)) === true ? 0 : Math.ceil((this.state.expectedcount / (this.state.checkedincount + this.state.expectedcount + this.state.checkedout)) * 100)} % Of expected visitors
                </div>
              </CardFooter> */}
            </Card>
          </GridItem>

          {sessionStorage.getItem("role") === "IsManager" || sessionStorage.getItem("role") === "isSecurity" ? <GridItem xs={12} sm={6} md={3}>
            <Card style={{ height: 100 }}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <AssignmentTurnedInIcon />
                </CardIcon>
                {this.state.loader ? <LoaderComponent /> : ""}
                <p className={classes.cardCategory}>&nbsp;&nbsp;&nbsp;{Settings.titleCase("Check in visitors")}</p>
                <h3 className={classes.cardTitle}>
                  {this.state.checkedincount}
                </h3>
              </CardHeader>
              {/* <CardFooter stats>
                <div className={classes.stats}>
                  <ArrowUpwardIcon />
                  {this.customIsNaN(Math.ceil((this.state.checkedincount / (this.state.checkedincount + this.state.expectedcount + this.state.checkedout)) * 100)) === true ? 0 : Math.ceil((this.state.checkedincount / (this.state.checkedincount + this.state.expectedcount + this.state.checkedout)) * 100)} % Of check in visitors
                </div>
              </CardFooter> */}
            </Card>
          </GridItem> : ""}

          {sessionStorage.getItem("role") === "IsManager" || sessionStorage.getItem("role") === "isSecurity" ? <GridItem xs={12} sm={6} md={3}>
            <Card style={{ height: 100 }}>
              <CardHeader stats icon>
                <CardIcon style={{
                  backgroundColor: "grey",
                  borderRadius: "3px",
                  color: "white",
                  padding: "15px",
                  marginTop: "-20px",
                  marginRight: "15px",
                  float: "left"
                }} >
                  <ExitToAppIcon />
                </CardIcon>
                {this.state.loader ? <LoaderComponent /> : ""}
                <p className={classes.cardCategory}>{Settings.titleCase("Check out visitors")}</p>
                <h3 className={classes.cardTitle}>
                  {this.state.checkedout}
                </h3>
              </CardHeader>
              {/* <CardFooter stats>
                <div className={classes.stats}>
                  <ArrowUpwardIcon />
                  {this.customIsNaN(Math.ceil((this.state.checkedout / (this.state.checkedincount + this.state.expectedcount + this.state.checkedout)) * 100)) === true ? 0 : Math.ceil((this.state.checkedout / (this.state.checkedincount + this.state.expectedcount + this.state.checkedout)) * 100)}  % Of check out visitors
                </div>
              </CardFooter> */}
            </Card>
          </GridItem> : ""}
          {sessionStorage.getItem("role") === "IsManager" || sessionStorage.getItem("role") === "isSecurity" ? <GridItem xs={12} sm={6} md={3}>
            <Card style={{ height: 100 }}>
              <CardHeader stats icon  >
                <CardIcon color="info" >
                  <Icon icon={accountGroup} />
                </CardIcon>
                {this.state.loader ? <LoaderComponent /> : ""}
                <p className={classes.cardCategory}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Settings.titleCase("Total visitors")}</p>
                <h3 className={classes.cardTitle}>{this.state.checkedout + this.state.checkedincount + this.state.expectedcount}</h3>
              </CardHeader>
              {/* <CardFooter stats>
                <div className={classes.stats}>
                  <AccessTimeIcon />
                  Last 24 hours
                </div>
              </CardFooter> */}
            </Card>
          </GridItem> : ""}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} >

            <GridContainer>
              <GridItem xs={12} sm={3} md={3} lg={2} >
                {sessionStorage.getItem("role") === "IsManager" || sessionStorage.getItem("role") === "isSecurity" ? <div style={{ width: 180, display: "flex", alignItems: 'center', justifyContent: "center" }}>
                  <Button color="warning" onClick={() => {
                    this.props.history.push('/visitor/history')
                  }}>&nbsp;<AssessmentIcon /> Visitor History &nbsp;</Button>
                </div> : ""}
              </GridItem>
              <GridItem xs={12} sm={3} md={3} lg={5} >
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={2}>
                <div style={{ width: 180, display: "flex", alignItems: 'center', justifyContent: "center" }}>
                  <Button color="primary" onClick={() => {
                    this.props.history.push('/visitor/bulk')
                  }}><Icon icon={fileExcel} /> &nbsp; Bulk Upload</Button>
                </div>
              </GridItem>

              <GridItem xs={12} sm={3} md={3} lg={3} >
                <div style={{ width: 180, display: "flex", alignItems: 'center', justifyContent: "center" }}>
                  <Button color="primary" onClick={() => {
                    this.props.history.push('/visitor/registervisitor')
                  }}><PersonAddIcon /> &nbsp; Register Visitor</Button>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Visitor records")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {window.sessionStorage.getItem('role') === "IsManager" || window.sessionStorage.getItem('role') === "isSecurity" ? <>{Settings.capitalizeFLetter("Visitor details as on")} {moment(this.state.CurrentDate).format("DD-MM-YYYY")}</> :
                    <>{Settings.capitalizeFLetter("Expected visitor details for Today and the Coming days ")}</>}

                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="success"
                  // tableHead={[
                  //   {
                  //     name: "Status",
                  //     prop: "statusid"
                  //   },
                  //   {
                  //     name: "Expected Date",
                  //     prop: "expecteddate"
                  //   },
                  //   {
                  //     name: "Vehicle Number",
                  //     prop: "vehicle"
                  //   },
                  //   {
                  //     name: "Visitor Name",
                  //     prop: "visitor"
                  //   },
                  //   {
                  //     name: "Mobile Number",
                  //     prop: "mobile"
                  //   },
                  //   {
                  //     name: "Tenant",
                  //     prop: "tenant"
                  //   },
                  //   {
                  //     name: "To Meet",
                  //     prop: "tomeet"
                  //   },
                  //   {
                  //     name: "Added By",
                  //     prop: "addedby"
                  //   },
                  //   // {
                  //   //   name: "Host Name",
                  //   //   prop: "hostname"
                  //   // },
                  //   // {
                  //   //   name: "Company",
                  //   //   prop: "company"
                  //   // },
                  //   // {
                  //   //   name: "Purpose",
                  //   //   prop: "purpose"
                  //   // },
                   
                  //   {
                  //     name: "In Time",
                  //     prop: "intime"
                  //   },
                  //   {
                  //     name: "Out Time",
                  //     prop: "outtime"
                  //   },
                  //   // {
                  //   //   name: "Action",
                  //   //   prop: "action"
                  //   // }
                  // ]
                  // }
                  tableHead={[
                    {
                      name: "Status",
                      prop: "statusid"
                    },
                    {
                      name: "Expected Date",
                      prop: "expecteddate"
                    },
                    {
                      name: "Vehicle Number",
                      prop: "vehicle"
                    },
                    {
                      name: "Vehicle Category",
                      prop: "visitor"
                    },
                    // {
                    //   name: "Mobile Number",
                    //   prop: "mobile"
                    // },
                    // {
                    //   name: "Tenant",
                    //   prop: "tenant"
                    // },
                    {
                      name: "Advisor",
                      prop: "tomeet"
                    },
                    {
                      name: "Added By",
                      prop: "addedby"
                    },
                    // {
                    //   name: "Host Name",
                    //   prop: "hostname"
                    // },
                    // {
                    //   name: "Company",
                    //   prop: "company"
                    // },
                    // {
                    //   name: "Purpose",
                    //   prop: "purpose"
                    // },
                   
                    {
                      name: "In Time",
                      prop: "intime"
                    },
                    {
                      name: "Out Time",
                      prop: "outtime"
                    },
                    // {
                    //   name: "Action",
                    //   prop: "action"
                    // }
                  ]
                  }
                  tableData={this.state.vehicles}
                  vehicleCountfun={this.getallvisitorscount}
                  routename="visitor"
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter((withStyles(styles)(Dashboard)));

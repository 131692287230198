
import React, { Component } from 'react'
import PropTypes, { element } from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Switch, withRouter } from "react-router-dom";
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import ReportTable from "Grids/Reports";
import { HOST } from '../../lprfolders/config';
import moment from 'moment';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { BrowserRouter, Route, } from 'react-router-dom';
import './report.css';


import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
// import 'date-fns';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import annotationPlugin from 'chartjs-plugin-annotation';
import { x64 } from 'crypto-js';




const canvasBackgroundColor = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || '#99ffff';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
}


const footer = (tooltipItems) => {
    let sum = 0;
    const tooltips = Array.from(tooltipItems);

    // tooltipItems.forEach(function(tooltipItem) {
    //   sum += tooltipItem.parsed._stacks.y[0]+tooltipItem.parsed._stacks.y[1];
    // });
    // tooltipItems.map((tooltip) => {
    //     console.log(tooltip)
    // })
    return 'Sum: ' + sum;
};


ChartJS.register(...registerables);
ChartJS.register(annotationPlugin);


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    // select: {
    //     '&:before': {
    //         borderColor: 'white',
    //         color: 'white'
    //     },
    //     '&:after': {
    //         borderColor: 'white',
    //     },
    //     '&:not(.Mui-disabled):hover::before': {
    //         borderColor: 'white',
    //     },
    // },

};
class TenantReports extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            open: false,
            selectedDate: new Date(),
            vehicle: "",
            vehicles: [],
            tableHead: [],
            flagError: false,
            netWorkError: false,
            inputname: "",
            erroertext: "",
            showtable: false,
            mobile: "",
            emptystate: false,
            tenant: "",
            tenantData: [],
            tenantNames: [],
            databaseVehicles: [],
            vehiclecount: [],
            labels: ["a"],
            bikesPieChartData: [],
            carsPieChartData: [],
            totatBikeAllotments: "",
            totatCarAllotments: "",
            bikeTenantData: [],
            carsTenantData: [],
            bikeExcessTenantData: [],
            carsExcessTenantData: [],
            tenantTypeArray: [],
            tenantType: [],
            tenantid: "",
            datebasedtenant: [],
            tenantbaseddata: [],
            datebaseddatalabels: [],
            carsdateLabels: [],
            bikesdateLabels: [],
            carsdateUsageLimit: "",
            bikesdateUsagelimit: "",
            bikeDateData: [],
            carsDateData: [],


        }
    }

    // row = (x, i, header) => (

    //     <>
    //         {console.log(i)}
    //         {header.map((y, k) => {


    //             return (
    //                 <TableCell className={classes.tableCell} key={k} >
    //                     {console.log(k)}

    //                     {x[y.prop]}


    //                 </TableCell>
    //             )


    //         }
    //         )}
    //     </>
    // )
    handleDateChange = (date) => {
        this.setState({ selectedDate: date });
        this.getDateBaseddata(date)
    };
    getDateBaseddata = (date) => {
        let api3 = HOST + "/tenant/tenantcountgetall/" + moment(date).format("YYYY-MM-DD")

        axios.get(api3, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {

            let headers = [
                { name: 'Tenanat Name', prop: "eventdate" },
                { name: 'Cars Allotmemt ', prop: "carcapacity" },
                { name: 'Bikes Allotmemt', prop: "bikecapacity" },
                { name: 'Cars Usage ', prop: "maxcars" },
                { name: 'Bikes Usage', prop: "maxbikes" },
                { name: 'Cars Excess ', prop: "carsexcess" },
                { name: 'Bikes Excess', prop: "bikesexcess" },

            ]


            this.setState({ datebasedtenant: response.data, tableHead: headers })
            this.setState({
                bikeExcessTenantData: [],
                carsExcessTenantData: [],
                bikeTenantData: [],
                carsTenantData: [],
                datebaseddatalabels: [],

            })

            response.data.map((data) => {
                var carsdata = 0;
                var bikesdata = 0;
                if ((data.bikecapacity - data.max_bikes_parking_count) < 0) {
                    bikesdata = (data.bikecapacity - data.max_bikes_parking_count)
                }
                if ((data.carcapacity - data.max_cars_parking_count) < 0) {
                    carsdata = (data.carcapacity - data.max_cars_parking_count);
                }

                this.state.datebaseddatalabels.push(data.tenant)
                this.state.bikeExcessTenantData.push(-bikesdata)
                this.state.carsExcessTenantData.push(-carsdata)
                this.state.bikeTenantData.push(data.max_bikes_parking_count)
                this.state.carsTenantData.push(data.max_cars_parking_count)
            })
            this.setState({
                bikeExcessTenantData: this.state.bikeExcessTenantData,
                carsExcessTenantData: this.state.carsExcessTenantData,
                bikeTenantData: this.state.bikeTenantData,
                carsTenantData: this.state.carsTenantData,
                datebaseddatalabels: this.state.datebaseddatalabels
            })


        }).catch(err => {
            this.setState({ showtable: true })
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.data.statusid === 409) {
                    this.setState({ flagError: true, loader: false, erroertext: Settings.Vehicle(409).errmessgae, inputname: Settings.Vehicle(409).inputname })
                    setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                }
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })
    }
    componentDidMount() {
        let apis = HOST + "/tenant/getall"
        axios.get(apis, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {

            this.getTenantsData(response.data[0].tenantid)

            var tenantnames = [];
            var carsPercentage = [];
            var BikesPercentage = [];

            const getTotalCars = response.data.reduce((total, item) => {
                return total + item.carcapacity
            }, 0);

            const getTotalBikes = response.data.reduce((total, item) => {
                return total + item.bikecapacity
            }, 0);

            this.setState({
                totatBikeAllotments: getTotalBikes,
                totatCarAllotments: getTotalCars
            })

            this.setState({ tenantData: response.data, tenant: response.data[0].tenant })
            this.state.tenantData.map((tenantData) => {

                let carpercentage = (tenantData.carcapacity / this.state.totatCarAllotments) * 100
                // carsPercentage.push(Math.floor(carpercentage));
                carsPercentage.push(tenantData.carcapacity);

                let Bikespercentage = (tenantData.bikecapacity / this.state.totatBikeAllotments) * 100
                // BikesPercentage.push(Math.floor(Bikespercentage));
                BikesPercentage.push(tenantData.bikecapacity);

                tenantnames.push(tenantData.tenant);

            })


            this.setState({ tenantNames: tenantnames, carsPieChartData: carsPercentage, bikesPieChartData: BikesPercentage })

            this.setState({ tenantid: response.data[0].tenant })

        });
        let api1 = HOST + "/vehicle/getall"
        axios.get(api1, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            this.setState({ databaseVehicles: response.data })
        })


        let api3 = HOST + "/tenant/tenantcountgetall/" + moment(this.state.selectedDate).format("YYYY-MM-DD")

        axios.get(api3, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data.length === 0) {
                this.setState({ emptystate: true, loader: false, })
                this.setState({ showtable: false })
                setTimeout(() => { this.setState({ emptystate: false }) }, 3000)
            }
            let headers = [
                { name: 'Tenanat Name', prop: "eventdate" },
                { name: 'Cars Allotmemt ', prop: "carcapacity" },
                { name: 'Bikes Allotmemt', prop: "bikecapacity" },
                { name: 'Cars Usage ', prop: "maxcars" },
                { name: 'Bikes Usage', prop: "maxbikes" },
                { name: 'Cars Excess ', prop: "carsexcess" },
                { name: 'Bikes Excess', prop: "bikesexcess" },

            ]


            this.setState({ datebasedtenant: response.data, tableHead: headers })


            response.data.map((data) => {
                var carsdata = 0;
                var bikesdata = 0;


                if ((data.bikecapacity - data.max_bikes_parking_count) < 0) {
                    bikesdata = (data.bikecapacity - data.max_bikes_parking_count)
                }
                if ((data.carcapacity - data.max_cars_parking_count) < 0) {
                    carsdata = (data.carcapacity - data.max_cars_parking_count);
                }

                this.state.datebaseddatalabels.push(data.tenant)
                this.state.bikeExcessTenantData.push(-bikesdata)
                this.state.carsExcessTenantData.push(-carsdata)
                this.state.bikeTenantData.push(data.max_bikes_parking_count + bikesdata)
                this.state.carsTenantData.push(data.max_cars_parking_count + carsdata)

            })
            this.setState({
                datebaseddatalabels: this.state.datebaseddatalabels,
                bikeExcessTenantData: this.state.bikeExcessTenantData,
                carsExcessTenantData: this.state.carsExcessTenantData,
                bikeTenantData: this.state.bikeTenantData,
                carsTenantData: this.state.carsTenantData
            })

        }).catch(err => {
            this.setState({ showtable: true })
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.data.statusid === 409) {
                    this.setState({ flagError: true, loader: false, erroertext: Settings.Vehicle(409).errmessgae, inputname: Settings.Vehicle(409).inputname })
                    setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                }
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })

        // let api4 = HOST + "/tenant/tenantdatecountgetall/" + 'f88ab49dac1f4cb89d4182a26685b7b3'

        // axios.get(api4, {
        //     headers: {
        //         "authToken": this.token,
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     }
        // }).then(response => {
        //     if (response.data.length === 0) {
        //         this.setState({ emptystate: true, loader: false, })
        //         this.setState({ showtable: false })
        //         setTimeout(() => { this.setState({ emptystate: false }) }, 3000)
        //     }
        //     console.log(response.data)

        //     this.setState({ tenantbaseddata: response.data });
        //     this.setState({
        //         bikesdateUsagelimit: response.data[0].bikecapacity,
        //         carsdateUsageLimit: response.data[0].carcapacity
        //     })
        //     response.data.map((data) => {
        //         this.state.carsdateLabels.push(moment((data.eventdate)).format("DD-MM-YYYY"))
        //         this.state.bikesdateLabels.push(moment((data.eventdate)).format("DD-MM-YYYY"))
        //         this.state.bikeDateData.push(data.maxbikes)
        //         this.state.carsDateData.push(data.maxcars)


        //     })
        //     this.setState({
        //         bikesdateLabels: this.state.bikesdateLabels,
        //         carsdateLabels: this.state.carsdateLabels,
        //         bikeDateData: this.state.bikeDateData,
        //         carsDateData: this.state.carsDateData,

        //     })
        // }).catch(err => {

        //     if (err.message === "Network Error") {
        //         this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
        //         setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
        //     }
        //     if (err.response) {
        //         if (err.response.data.statusid === 409) {
        //             this.setState({ flagError: true, loader: false, erroertext: Settings.Vehicle(409).errmessgae, inputname: Settings.Vehicle(409).inputname })
        //             setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        //         }
        //         if (err.response.status === 502) {
        //             this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
        //             setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
        //         }
        //     }
        // })


    }







    getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
        }
        return arr;
    };


    getTenantsData = (tenantid) => {
        let api4 = HOST + "/tenant/tenantdatecountgetall/" + tenantid

        axios.get(api4, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data.length === 0) {
                this.setState({ emptystate: true, loader: false, })
                this.setState({ showtable: false })
                setTimeout(() => { this.setState({ emptystate: false }) }, 3000)
            } this.setState({ tenantbaseddata: response.data })

            this.setState({
                bikesdateLabels: [],
                carsdateLabels: [],
                bikeDateData: [],
                carsDateData: [],

            })
            this.setState({
                bikesdateUsagelimit: response.data[0].bikecapacity,
                carsdateUsageLimit: response.data[0].carcapacity
            })
            response.data.map((data) => {
                this.state.carsdateLabels.push(moment((data.eventdate)).format("DD-MM-YYYY"))
                this.state.bikesdateLabels.push(moment((data.eventdate)).format("DD-MM-YYYY"))
                this.state.bikeDateData.push(data.maxbikes)
                this.state.carsDateData.push(data.maxcars)


            })
            this.setState({
                bikesdateLabels: this.state.bikesdateLabels,
                carsdateLabels: this.state.carsdateLabels,
                bikeDateData: this.state.bikeDateData,
                carsDateData: this.state.carsDateData,

            })
        }).catch(err => {
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.data.statusid === 409) {
                    this.setState({ flagError: true, loader: false, erroertext: Settings.Vehicle(409).errmessgae, inputname: Settings.Vehicle(409).inputname })
                    setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                }
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })
    }


    handleChange = (event, values) => {
        if (values) {
            this.setState({
                tenant: values.props.name,
                tenantid: values.props.value
            });
        }
        this.getTenantsData(values.props.value)
    }

    getEntryCount = function (start, end) {
        var entryCount = [];
        var count = 0;
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
        }
        arr.map((date) => {
            count = 0;
            this.state.vehicles.map((vehicle) => {
                if (vehicle.eventdate === date.split('-').reverse().join('-'))
                    if (vehicle.direction === "ENTRY") {
                        count++;
                    }
            })
            entryCount.push(count)
        })
        return entryCount;
    };

    getExitCount = function (start, end) {
        var exitCount = [];
        var count = 0;
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
        }
        arr.map((date) => {
            count = 0;
            this.state.vehicles.map((vehicle) => {
                if (vehicle.eventdate === date.split('-').reverse().join('-'))
                    if (vehicle.direction === "EXIT") {
                        count++;
                    }
            })
            exitCount.push(count)
        })
        return exitCount;
    };

    render() {
        const todate = new Date();
        const labels = this.getDaysArray(moment(todate.setDate(todate.getDate() - 6)).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"));
        const CardateData = {
            labels: this.state.carsdateLabels,
            datasets: [
                {
                    label: 'Cars',
                    data: this.state.carsDateData,
                    backgroundColor: 'rgb(75, 192, 192, 0.5)',
                },



            ],

        }
        const bikeDatedata = {
            labels: this.state.bikesdateLabels,
            datasets: [
                {
                    label: 'Bikes',
                    data: this.state.bikeDateData,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',

                },
            ],

        }

        const carpiedata = {
            labels: this.state.tenantNames,
            datasets: [
                {
                    label: '# of Votes',
                    data: this.state.carsPieChartData,
                    backgroundColor: [

                        'rgb(239, 134, 119)',
                        'rgb(160, 231, 125)',
                        'rgb(130, 182, 217)',
                        'rgb(243, 220, 212)',
                        'rgb(194, 194, 180)',
                        'rgb(242, 182, 236)',
                        'rgb(230, 110, 250)',
                        'rgb(132, 166, 232)',
                        'rgb(240, 60, 120)',
                        'rgb(91, 240, 240)',
                        'rgb(125, 245, 215)',
                        'rgb(137, 215, 250)',
                        'rgb(170, 168, 237)',
                        'rgb(180, 237, 214)',
                        'rgb(242, 242, 174)',
                        'rgb(240, 156, 38)',
                        'rgb(195, 247, 116)',
                        'rgb(35, 235, 127)',
                        'rgb(142, 230, 216)',
                        'rgb(231, 234, 235)'
                    ],
                    borderColor: [

                        'rgb(239, 134, 119)',
                        'rgb(160, 231, 125)',
                        'rgb(130, 182, 217)',
                        'rgb(243, 220, 212)',
                        'rgb(194, 194, 180)',
                        'rgb(242, 182, 236)',
                        'rgb(230, 110, 250)',
                        'rgb(132, 166, 232)',
                        'rgb(240, 60, 120)',
                        'rgb(91, 240, 240)',
                        'rgb(125, 245, 215)',
                        'rgb(137, 215, 250)',
                        'rgb(170, 168, 237)',
                        'rgb(180, 237, 214)',
                        'rgb(242, 242, 174)',
                        'rgb(240, 156, 38)',
                        'rgb(195, 247, 116)',
                        'rgb(35, 235, 127)',
                        'rgb(142, 230, 216)',
                        'rgb(231, 234, 235)'
                    ],
                    borderWidth: 1,
                    // tooltip: {
                    //     callbacks: {
                    //         label: function (data) {
                    //             var datasetLabel = data.label;
                    //             var tooltipValue = data.dataset.data[data.dataIndex];
                    //             var tooltipLabel = datasetLabel + ': ' + tooltipValue + '%';
                    //             return tooltipLabel;
                    //         },

                    //     },

                    // }
                },

            ],

        };

        const bikepiedata = {
            labels: this.state.tenantNames,

            datasets: [
                {
                    label: '# of Votes',
                    data: this.state.bikesPieChartData,
                    backgroundColor: [


                        'rgb(239, 134, 119)',
                        'rgb(160, 231, 125)',
                        'rgb(130, 182, 217)',
                        'rgb(243, 220, 212)',
                        'rgb(194, 194, 180)',
                        'rgb(242, 182, 236)',
                        'rgb(230, 110, 250)',
                        'rgb(132, 166, 232)',
                        'rgb(240, 60, 120)',
                        'rgb(91, 240, 240)',
                        'rgb(125, 245, 215)',
                        'rgb(137, 215, 250)',
                        'rgb(170, 168, 237)',
                        'rgb(180, 237, 214)',
                        'rgb(242, 242, 174)',
                        'rgb(240, 156, 38)',
                        'rgb(195, 247, 116)',
                        'rgb(35, 235, 127)',
                        'rgb(142, 230, 216)',
                        'rgb(231, 234, 235)'

                    ],
                    borderColor: [

                        'rgb(239, 134, 119)',
                        'rgb(160, 231, 125)',
                        'rgb(130, 182, 217)',
                        'rgb(243, 220, 212)',
                        'rgb(194, 194, 180)',
                        'rgb(242, 182, 236)',
                        'rgb(230, 110, 250)',
                        'rgb(132, 166, 232)',
                        'rgb(240, 60, 120)',
                        'rgb(91, 240, 240)',
                        'rgb(125, 245, 215)',
                        'rgb(137, 215, 250)',
                        'rgb(170, 168, 237)',
                        'rgb(180, 237, 214)',
                        'rgb(242, 242, 174)',
                        'rgb(240, 156, 38)',
                        'rgb(195, 247, 116)',
                        'rgb(35, 235, 127)',
                        'rgb(142, 230, 216)',
                        'rgb(231, 234, 235)'

                    ],
                    borderWidth: 1,
                    // tooltip: {
                    //     callbacks: {
                    //         label: function (data) {
                    //             var datasetLabel = data.label;
                    //             var tooltipValue = data.dataset.data[data.dataIndex];
                    //             var tooltipLabel = datasetLabel + ': ' + tooltipValue + '%';
                    //             return tooltipLabel;
                    //         }
                    //     }
                    // }
                },

            ],

        };


        const optionstenant = {
            responsive: true,
            interaction: {
                intersect: false,
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },

        };
        const biketenantData = {
            labels: this.state.datebaseddatalabels,
            datasets: [
                {
                    label: 'Bikes',
                    data: this.state.bikeTenantData,
                    backgroundColor: 'rgb(75, 192, 192, 0.5)',
                    stack: 'Stack 0',

                },
                {
                    label: 'Bike Excess',
                    data: this.state.bikeExcessTenantData,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    stack: 'Stack 0',


                },

            ],

        }
        const cartenantData = {
            labels: this.state.datebaseddatalabels,
            datasets: [

                {
                    label: 'Cars',
                    data: this.state.carsTenantData,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    stack: 'Stack 1',


                },
                {
                    label: 'Cars Excess',
                    data: this.state.carsExcessTenantData,
                    backgroundColor: 'rgba(255, 50, 132, 0.5)',
                    stack: 'Stack 1',
                },
            ],

        }

        const bikesDateOptions = {
            responsive: true,
            scales: {

                y: {
                    max: this.state.bikesdateUsagelimit + (this.state.bikesdateUsagelimit * 25) / 100,
                    min: 0,

                    // ticks: {
                    //     stepSize: (this.state.carsdateUsageLimit * 20) / 100
                    // }
                },
            },
            plugins: {

                legend: {
                    display: false
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            yMin: this.state.bikesdateUsagelimit,
                            yMax: this.state.bikesdateUsagelimit,
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 2,
                        }
                    }
                }
            }
        };
        const pieOptions = {

            plugins: {
                legend: {
                    boxWidth: '0.01',
                    display: 'bottom'
                },
                indexAxis: 0,


            },




        }
        const carsDateOptions = {
            responsive: true,
            scales: {

                y: {
                    max: this.state.carsdateUsageLimit + (this.state.carsdateUsageLimit * 25) / 100,
                    min: 0,


                },
            },
            plugins: {
                legend: {
                    display: false
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            yMin: this.state.carsdateUsageLimit,
                            yMax: this.state.carsdateUsageLimit,
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 2,
                        }
                    }
                }
            }
            // scales: {
            //     x: {
            //         stacked: true,
            //     },
            //     y: {
            //         stacked: true,
            //     },
            // },

        };

        // const row = (x) => (
        //     <>
        //         {
        //             x.map((row, i) => {

        //                 this.state.tableHead.map(
        //                     (y, k) => {
        //                         return (
        //                             <TableCell className={classes.tableCell} key={k}>
        //                                 {console.log(row[y.prop])}
        //                                 {row[y.prop]}
        //                             </TableCell>
        //                         )

        //                     })

        //             })
        //         }
        //     </>

        // )

        {/* {headers.map((y, k) => {
    
                    if (y.prop === "db_match" && x[y.prop]) {
                        return (
                            <TableCell className={classes.tableCell} key={k}>
                                <FiberManualRecordRoundedIcon style={{ color: "green" }} />
                            </TableCell>
                        )
                    }
                    else if (y.prop === "db_match" && !x[y.prop]) {
                        return (
                            <TableCell className={classes.tableCell} key={k}>
                                <FiberManualRecordRoundedIcon style={{ color: "red" }} />
                            </TableCell>
                        )
                    }
                    else if (y.prop === "fullimagepath") {
                        return (
                            <TableCell className={classes.tableCell} key={k}>
                                <>
                                    <Tooltip title={"Vehicle Image"} placement="bottom">
                                        <VisibilityIcon style={{ color: "#1877f2", cursor: "pointer" }} onClick={() => {
                                            setImagevalue(x.fullimagepath)
                                            setImageopen(true)
                                        }} />
                                    </Tooltip>
                                </>
                            </TableCell>
                        )
                    }
                    else if (y.prop === "date" && x[y.prop] !== null) {
                        return (
                            <TableCell className={classes.tableCell} key={k}>
                                {x.date.split("T")[0]}
                            </TableCell>
                        )
                    }
                    else if (y.prop === "s.no") {
                        return (
                            <TableCell className={classes.tableCell} key={k}>
                                {indexOfLastPost + (i + 1)}
                            </TableCell>
                        )
                    }
    
                    else {
                        return (
                            <TableCell className={classes.tableCell} key={k}>
                                {x[y.prop]}
                            </TableCell>
                        )
                    }
                }
                )} */}

        {/* {headers.map((y, k) => {
                    <TableCell className={classes.tableCell} key={k}>
                        {x[y.prop]}
                    </TableCell>
                })} */}


        const { classes } = this.props
        return (
            <div>
                {this.state.tenantData.length !== 0 ? <GridContainer>
                    <GridItem xs={12} sm={6} md={4} >
                        <Card >
                            <CardHeader color="warning" >
                                {/* <CardIcon color="warning" style={{height:60,width:60}}>
                                    <Icon icon={humanQueue} />
                                </CardIcon> */}
                                <h4 className={classes.cardTitleWhite}><b>Tenant Wise Parking Allocation</b></h4>


                            </CardHeader>
                            <CardBody style={{
                                height: "300px",
                                overflowY: "scroll",
                            }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >Tenant Name</TableCell>
                                            <TableCell > Car Limit</TableCell>
                                            <TableCell > Bike Limit</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tenantData.map((row) => (
                                            <TableRow
                                                key={row.tenant}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.tenant}
                                                </TableCell>
                                                <TableCell align="center">{row.carcapacity}</TableCell>
                                                <TableCell align="center">{row.bikecapacity}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardBody>
                            {/* <CardFooter stats>
                <div className={classes.stats}>
                  <ArrowUpwardIcon />
                  {this.customIsNaN(Math.ceil((this.state.expectedcount / (this.state.checkedincount + this.state.expectedcount + this.state.checkedout)) * 100)) === true ? 0 : Math.ceil((this.state.expectedcount / (this.state.checkedincount + this.state.expectedcount + this.state.checkedout)) * 100)} % Of expected visitors
                </div>
              </CardFooter> */}
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                        <Card  >
                            <CardHeader  >
                                <Pie options={pieOptions} data={bikepiedata} ></Pie>

                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    Bike Parking Allotment
                                </div>
                                <div>
                                    Total:{this.state.totatBikeAllotments}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Card  >
                            <CardHeader  >
                                <Pie options={pieOptions} data={carpiedata} ></Pie>

                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    Car Parking Allotment
                                </div><div>
                                    Total:{this.state.totatCarAllotments}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>


                    {/* <GridItem xs={12} sm={12} md={5}>

                        {this.state.showtable ? <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="primary">
                                        <h4 className={classes.cardTitleWhite}>  Vehicles Count  </h4>

                                    </CardHeader>
                                    <CardBody>
                                        <table>
                                            <th style={{width:100}}>S.No</th>
                                            <th style={{width:100}}>Tenant</th>
                                            <th style={{width:100}}>Count</th>
                                            {this.state.vehiclecount.map((tenantcount, index) => {
                                                return (
                                                    <tr>
                                                        <td style={{width:100,textAlign:'center'}}>{index + 1}</td>
                                                        <td style={{width:100,textAlign:'center'}}>{tenantcount.tenant}</td>
                                                        <td style={{width:100,textAlign:'center'}}>{tenantcount.count}</td>

                                                    </tr>
                                                );

                                            })}

                                        </table>
                                        <Bar options={options}
                                            data={data} />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                            </GridItem>
                        </GridContainer>
                            : ""}
                    </GridItem> */}
                </GridContainer> : ""}
                {/* 
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Vehicle Activity</h4>
                            <p className={classes.cardCategoryWhite}>
                                Vehicle movement from {moment(this.state.selectedFromDate).format("DD-MM-YYYY")} to {moment(this.state.selectedToDate).format("DD-MM-YYYY")}
                            </p>
                        </CardHeader>
                        <CardBody>
                            {this.state.netWorkError ? <SnackbarContent
                                icon={InfoIcon}
                                message={this.state.erroertext}
                                // close
                                color="danger"
                            /> : ""}
                            {this.state.loader ? <LoaderComponent /> : ""}

                            <Table className={classes.table}>

                                <TableHead color='info'>
                                    <TableRow className={classes.tableHeadRow}>
                                        <TableCell rowSpan={2} align="center"  >Tenant Name</TableCell>
                                        <TableCell colSpan={2} align="center" >Allotment</TableCell>

                                        <TableCell colSpan={2} align="center" >Usage</TableCell>
                                        <TableCell colSpan={2} align="center"  >Excess</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center"  >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>
                                        <TableCell align="center" >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>
                                        <TableCell align="center"  >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    <TableRow className={classes.tableBodyRow}>
                                        {this.state.tenantbaseddata.map((prop, key) => {

                                            return (
                                                <TableRow key={key} className={classes.tableBodyRow}>
                                                    {row(prop, key, this.state.tableHead)}
                                                </TableRow>
                                            );
                                        })}

                                    </TableRow>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                        </GridItem>
                                    </GridContainer>
                                </TableBody>


                            </Table>
                        </CardBody>
                    </Card>
                </GridItem> */}
                {this.state.datebasedtenant.length !== 0 ? <GridContainer>
                    <GridItem xs={12} sm={6} md={12}>
                        <Card >
                            <CardHeader color="info" >
                                {/* <CardIcon color="warning" style={{height:60,width:60}}>
                                    <Icon icon={humanQueue} />
                                </CardIcon> */}
                                <GridContainer >
                                    <GridItem xs={12} sm={6} md={6}>

                                        <h4 className={classes.cardTitleWhite}>Parking Usage report By Date</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={2}>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4} style={{ marginTop: -15 }}>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <KeyboardDatePicker
                                                margin="normal"
                                                // color="white"

                                                style={{ color: "white" }}
                                                id="date-picker-dialog"
                                                label="Select Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.selectedDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                maxDate={new Date()}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody style={{
                                overflowX: "scroll",
                                // height: "450px",
                                overflowY: "scroll",
                            }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={12}>

                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell rowSpan={2} align="center"  >Tenant Name</TableCell>
                                                    <TableCell colSpan={2} align="center" >Allotment</TableCell>

                                                    <TableCell colSpan={2} align="center" >Usage</TableCell>
                                                    <TableCell colSpan={2} align="center"  >Excess</TableCell>


                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"  >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>
                                                    <TableCell align="center" >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>
                                                    <TableCell align="center"  >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>

                                                </TableRow>

                                            </TableHead>
                                            <TableBody>
                                                {this.state.datebasedtenant.map((row) => (
                                                    <TableRow
                                                        key={row.tenant}
                                                    >
                                                        <TableCell component="th" scope="row" align="center" >
                                                            {row.tenant}
                                                        </TableCell>
                                                        <TableCell align="center">{row.carcapacity}</TableCell>
                                                        <TableCell align="center">{row.bikecapacity}</TableCell>
                                                        <TableCell align="center">{row.max_cars_parking_count}</TableCell>
                                                        <TableCell align="center">{row.max_bikes_parking_count}</TableCell>
                                                        <TableCell align="center">{row.carcapacity - row.max_cars_parking_count > 0 ? 0 : (row.carcapacity - row.max_cars_parking_count) * -1}</TableCell>
                                                        <TableCell align="center">{row.bikecapacity - row.max_bikes_parking_count > 0 ? 0 : (row.bikecapacity - row.max_bikes_parking_count) * -1}</TableCell>


                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>

                        </Card>
                    </GridItem>

                </GridContainer>

                    : ""}

                <GridContainer>
                    {this.state.carsTenantData.length !== 0 && this.state.carsExcessTenantData.length !== 0 ? <GridItem xs={12} sm={6} md={6}>
                        <Card  >
                            <CardHeader >
                                <div className={classes.stats}>
                                    <b>CARS PARKING </b> <p style={{ fontSize: "13px", marginTop: -3 }}> Allotment VS Usage on <b>{moment(this.state.selectedDate).format("DD-MM-YYYY")}</b></p>
                                </div>
                            </CardHeader>
                            <CardBody  >
                                <Bar data={cartenantData} options={optionstenant}
                                />

                            </CardBody>

                        </Card>
                    </GridItem> : ""}
                    {this.state.bikeTenantData.length !== 0 && this.state.bikeExcessTenantData.length !== 0 ? <GridItem xs={12} sm={6} md={6}>
                        <Card  >
                            <CardHeader >
                                <div className={classes.stats}>
                                    <b>BIKE PARKING </b> <p style={{ fontSize: "13px", marginTop: -3 }}> Allotment VS Usage on <b>{moment(this.state.selectedDate).format("DD-MM-YYYY")}</b></p>

                                </div>
                            </CardHeader>
                            <CardBody  >
                                <Bar data={biketenantData} options={optionstenant}
                                />

                            </CardBody>

                        </Card>
                    </GridItem> : ""}
                </GridContainer>
                <GridContainer>
                    {this.state.tenantData.length !== 0 ? <GridItem xs={12} sm={6} md={12}>
                        <Card >
                            <CardHeader color="rose" style={{ padding: 30 }} >
                                {/* <CardIcon color="warning" style={{height:60,width:60}}>
                                    <Icon icon={humanQueue} />
                                </CardIcon> */}

                                <GridContainer >
                                    <GridItem xs={12} sm={6} md={6}>

                                        <h4 className={classes.cardTitleWhite}>Parking Usage report By Tenant</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={2}>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4} style={{ marginTop: -15 }}>

                                        <FormControl color="primary" style={{ width: "100%", height: "60%" }}>
                                            <InputLabel id="demo-simple-select-helper-label" >Select Tenant</InputLabel>

                                            <Select
                                                className={classes.select}
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                // value={this.state.tenantid}
                                                onChange={this.handleChange}
                                                defaultValue={this.state.tenantData[0].tenantid}
                                                color='green'
                                                style={{ fontSize: 15 }}                            >
                                                {this.state.tenantData.map((entryCriteria, index) => (
                                                    <MenuItem value={entryCriteria.tenantid} name={entryCriteria.tenant}>{entryCriteria.tenant}</MenuItem>

                                                ))}
                                            </Select>
                                            {this.state.requirederror && !this.state.tenantid ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody style={{
                                overflowX: "scroll",
                                overflowY: "hidden",
                            }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={12}>

                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell rowSpan={2} align="center"  >Date</TableCell>
                                                    <TableCell colSpan={2} align="center" >Allotment</TableCell>

                                                    <TableCell colSpan={2} align="center" >Usage</TableCell>
                                                    <TableCell colSpan={2} align="center"  >Excess</TableCell>


                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center"  >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>
                                                    <TableCell align="center" >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>
                                                    <TableCell align="center"  >Cars</TableCell><TableCell align="center"  >Bikes</TableCell>

                                                </TableRow>

                                            </TableHead>
                                            {this.state.tenantbaseddata.length > 0 ? <TableBody>
                                                {this.state.tenantbaseddata.map((row) => (
                                                    <TableRow
                                                        key={row.eventdate}
                                                    >
                                                        <TableCell component="th" scope="row" align="center" >
                                                            {moment((row.eventdate)).format("DD-MM-YYYY")}
                                                        </TableCell>
                                                        <TableCell align="center">{row.carcapacity}</TableCell>
                                                        <TableCell align="center">{row.bikecapacity}</TableCell>
                                                        <TableCell align="center">{row.maxcars}</TableCell>
                                                        <TableCell align="center">{row.maxbikes}</TableCell>
                                                        <TableCell align="center">{row.carcapacity - row.maxcars > 0 ? 0 : (row.carcapacity - row.maxcars) * -1}</TableCell>
                                                        <TableCell align="center">{row.bikecapacity - row.maxbikes > 0 ? 0 : (row.bikecapacity - row.maxbikes) * -1}</TableCell>




                                                    </TableRow>
                                                ))}
                                            </TableBody> : <TableBody>
                                                <SnackbarContent
                                                icon={InfoIcon}
                                                message={"No records found"}
                                                // close
                                                color="danger"
                                            />
                                            </TableBody>}
                                        </Table>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>

                        </Card>
                    </GridItem> : ""}

                </GridContainer>
                <GridContainer>
                    {this.state.carsDateData.length !== 0 ? <GridItem xs={12} sm={6} md={6}>

                        <Card  >
                            <CardHeader >
                                <div className={classes.stats}>
                                    <b>CAR PARKING </b> <p style={{ fontSize: "13px", marginTop: -3 }}>Allotment VS Usage for <b>{this.state.tenant}</b></p>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Bar options={carsDateOptions}
                                    data={CardateData} />
                            </CardBody>

                        </Card>
                    </GridItem> : ""}
                    {this.state.bikeDateData.length !== 0 ? <GridItem xs={12} sm={6} md={6}>
                        <Card  >
                            <CardHeader >
                                <div className={classes.stats}>
                                    <b> BIKE PARKING </b> <p style={{ fontSize: "13px", marginTop: -3 }}>Allotment VS Usage for <b>{this.state.tenant}</b></p>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Bar options={bikesDateOptions}
                                    data={bikeDatedata} />
                            </CardBody>

                        </Card>
                    </GridItem> : ""}
                </GridContainer>

            </div >
        );
    }
}
TenantReports.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};

export default withRouter((withStyles(styles)(TenantReports)));
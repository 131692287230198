// module.exports = {
//     HOST : "http://localhost:2100/v1",
//     AUTH: "http://localhost:2100/auth",
// }
module.exports = {
    HOST : "https://gateapi.gaido.in/v1",
    AUTH: "https://gateapi.gaido.in/auth",
}




// import React, { Component } from 'react'
// // @material-ui/core components
// import { withStyles } from "@material-ui/core/styles";
// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
// import AddBoxIcon from '@material-ui/icons/AddBox';
// import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
// import { withRouter } from "react-router-dom";
// import InfoIcon from '@material-ui/icons/Info';
// import ThumbUpIcon from '@material-ui/icons/ThumbUp';
// import Settings from '../../lprfolders/GlobalConstant/Settings';
// import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import Snackbar from "components/Snackbar/Snackbar.js";
// import Alert from '../../lprfolders/GlobalConstant/Alert'
// import axios from 'axios';
// import { HOST } from '../../lprfolders/config';
// import moment from 'moment';
// import {
//     MuiPickersUtilsProvider,
//     // KeyboardDatePicker,
//     KeyboardTimePicker,
// } from '@material-ui/pickers';
// // import 'date-fns';
// // import FindReplaceIcon from '@material-ui/icons/FindReplace';
// import Grid from '@material-ui/core/Grid';
// import DateFnsUtils from '@date-io/date-fns';
// import Radio from '@material-ui/core/Radio';
// import { green } from '@material-ui/core/colors';
// // import { ModeComment } from '@material-ui/icons';
// const GreenRadio = withStyles({
//     root: {
//         color: green[400],
//         '&$checked': {
//             color: green[600],
//         },
//     },
//     checked: {},
// })((props) => <Radio color="default" {...props} />);
// const styles = {
//     cardCategoryWhite: {
//         color: "rgba(255,255,255,.62)",
//         margin: "0",
//         fontSize: "14px",
//         marginTop: "0",
//         marginBottom: "0"
//     },

//     cardTitleWhite: {
//         color: "#FFFFFF",
//         marginTop: "0px",
//         minHeight: "auto",
//         fontWeight: "300",
//         fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//         marginBottom: "3px",
//         textDecoration: "none"
//     }
// };
// class Add extends Component {
//     constructor(props) {
//         super(props);

//         this.token = localStorage.getItem('token');
//         this.state = {
//             flagError: false,
//             netWorkError: false,
//             erroertext: "",
//             inputname: "",
//             tc: false,
//             bc: false,
//             flatno: "",
//             fullname: "",
//             mobile: "",
//             vehicle: props.lpr,
//             lprId: props.lprId,
//             visitorname: "",
//             companyname: "",
//             hostname: "",
//             hostnameerror: false,
//             tomeet: "",
//             comments: "",
//             statusid: 2,
//             statuschecked: true,
//             statuschecked1: false,
//             date: props.entryDate,
//             selectedDate: "",
//             online: window.navigator.onLine,
//             visitorid: ""


//         }
//     }
//     componentDidMount() {
//         var date = this.state.date;
//         var newdate = date.split("-").reverse().join("-");
//         this.setState({
//             selectedDate: newdate + " " + this.props.entryTime
//         })

//         window.addEventListener('offline', this.handleNetworkChange);
//         window.addEventListener('online', this.handleNetworkChange);
//     }

//     componentWillUnmount() {
//         window.removeEventListener('offline', this.handleNetworkChange);
//         window.removeEventListener('online', this.handleNetworkChange);
//     }
//     handleNetworkChange = () => {
//         this.setState({ online: window.navigator.onLine });
//     }
//     search = () => {

//         this.setState({ loader: true })
//         let api = HOST + "/visitor/search/" + this.state.vehicle
//         axios.get(api, {
//             headers: {
//                 "authToken": this.token,
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json'
//             }
//         })
//             .then(response => {
//                 this.setState({
//                     mobile: response.data[0].mobile,
//                     vehicle: response.data[0].vehicle,
//                     visitorname: response.data[0].visitor,
//                     visitorid: response.data[0].visitorid,

//                     tomeet: response.data[0].tomeet,
//                 });
//                 this.setState({ loader: false })
//             })
//             .catch(err => {
//                 if (err.message === "Network Error") {
//                     this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
//                     setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
//                 }
//                 if (err.response) {
//                     if (err.response.status === 502) {
//                         this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
//                         setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
//                     }
//                 }
//             })
//     }
//     add = () => {
//         let count = 1
//         let api = HOST + "/visitor/add"

//         let body =
//         {
//             visitor: this.state.visitorname,
//             mobile: this.state.mobile,
//             visitorid: this.state.visitorid,
//             lprid: this.state.lprId,
//             vehicle: this.state.vehicle,
//             tomeet: this.state.tomeet,
//             statusid: this.state.statusid,
//             intime: this.state.selectedDate,
//         }
//         if (this.state.vehicle === "") {
//             count--
//         }
//         if (this.state.vehicle) {
//             if (Settings.Vehicle(this.state.vehicle).status) {
//                 count--
//             }
//             else {
//                 count++
//                 this.setState({ flagError: true, erroertext: Settings.Vehicle(this.state.vehicle).errmessgae, inputname: Settings.Vehicle(this.state.vehicle).inputname })
//                 setTimeout(() => { this.setState({ flagError: false }) }, 3000);
//             }
//         }
//         if (Settings.PersonName(this.state.visitorname).status) {
//             if (Settings.mobilenumber(this.state.mobile).status) {
//                 if (Settings.Comments(this.state.tomeet).status) {


//                 } else {
//                     count++
//                     this.setState({ flagError: true, erroertext: "Tomeet length must be minium 3 maximum 25", inputname: Settings.Comments(this.state.tomeet).inputname })
//                     setTimeout(() => { this.setState({ flagError: false }) }, 3000);
//                 }

//             }

//             else {
//                 count++
//                 this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.mobile).errmessgae, inputname: Settings.mobilenumber(this.state.mobile).inputname })
//                 setTimeout(() => { this.setState({ flagError: false }) }, 3000);
//             }
//         }
//         else {
//             count++
//             this.setState({ hostnameerror: false, flagError: true, erroertext: Settings.PersonName(this.state.visitorname).errmessgae, inputname: Settings.PersonName(this.state.visitorname).inputname })
//             setTimeout(() => { this.setState({ flagError: false }) }, 3000);
//         }
//         if (count === 0) {
//             if (this.state.online) {

//                 this.setState({ loader: true })
//                 axios.post(api, body, {
//                     headers: {
//                         "authToken": this.token,
//                         'Accept': 'application/json',
//                         // 'Content-Type': 'application/json'
//                     }
//                 }).then(res => {
//                     if (res.status === 201) {
//                         this.setState({ loader: false })
//                         this.showNotification("tc")

//                         setTimeout(() => { this.props.history.push('/dashboard') }, 2000);
//                         window.location.reload()

//                     }
//                 }).catch(err => {
//                     if (err.message === "Network Error") {
//                         this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
//                         setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
//                     }
//                     if (err.response) {
//                         if (err.response.data.statusid === 409) {
//                             this.setState({ flagError: true, loader: false, erroertext: "Visitor already Registered", inputname: "Vehicle" })
//                             setTimeout(() => { this.setState({ flagError: false }) }, 3000);
//                         }
//                         if (err.response.status === 502) {
//                             this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
//                             setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
//                         }
//                     }
//                 })
//             }
//             else {
//                 this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
//                 setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
//             }
//         }

//     }
//     showNotification = place => {
//         switch (place) {
//             case "tc":
//                 if (!this.state.tc) {
//                     this.setState({ tc: true, erroertext: Alert.Successadd });
//                     setTimeout(() => { this.setState({ tc: false }) }, 4000)
//                 }
//                 break;
//             case "bc":
//                 if (!this.state.bc) {
//                     this.setState({ bc: true });
//                     setTimeout(() => { this.setState({ bc: false }) }, 4000)
//                 }
//                 break;
//             default:
//                 break;
//         }
//     };
//     onChangestatus = (event) => {
//         this.setState({ statusid: event.target.value, statuschecked: !this.state.statuschecked, statuschecked1: !this.state.statuschecked1 })
//     }

//     render() {
//         const { classes } = this.props
//         return (
//             <div className={classes.root}>

//                 <GridContainer>
//                     {this.state.tc ? <Snackbar
//                         place="tc"
//                         color="success"
//                         icon={ThumbUpIcon}
//                         message={" Visitor " + this.state.erroertext}
//                         open={this.state.tc}
//                         closeNotification={() => this.setState({ tc: false })}
//                         close
//                     /> : ""}
//                     <GridItem xs={12} sm={12} md={2}>
//                     </GridItem>
//                     <GridItem xs={12} sm={12} md={12}>
//                         <Card>
//                             <CardHeader color="primary">
//                                 <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Add visitor")}</h4>
//                                 <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please fill in all required feilds")}</p>
//                             </CardHeader>
//                             <CardBody>
//                                 {this.state.netWorkError ? <SnackbarContent
//                                     icon={InfoIcon}
//                                     message={this.state.erroertext}
//                                     // close
//                                     color="danger"
//                                 /> : ""}
//                                 {this.state.loader ? <LoaderComponent /> : ""}
//                                 <GridContainer>
//                                     <GridItem xs={12} sm={12} md={6}>
//                                         <CustomInput
//                                             labelText=" *Vehicle Number(max. 10 characters)"
//                                             id="Vehicle Number"
//                                             formControlProps={{
//                                                 fullWidth: true
//                                             }}
//                                             inputProps={{
//                                                 onChange: (event) => {
//                                                     this.setState({ vehicle: event.target.value })
//                                                     if (Settings.Vehicle(event.target.value).status) {
//                                                         this.setState({ flagError: false, erroertext: "" })
//                                                     } else {
//                                                         this.setState({ flagError: true, erroertext: Settings.Vehicle(event.target.value).errmessgae, inputname: "Vehicle" })
//                                                     }
//                                                 },
//                                                 onInput: (e) => e.target.value = ("" + e.target.value).toUpperCase().toString().slice(0, 10),
//                                                 type: "text",
//                                                 value: this.props.lpr,
//                                                 disabled: true,
//                                                 error: (this.state.flagError && this.state.inputname === "Vehicle") ? true : false
//                                             }}
//                                         />
//                                         {this.state.flagError && this.state.inputname === "Vehicle" ? <SnackbarContent
//                                             icon={InfoIcon}
//                                             message={this.state.erroertext}
//                                             // close
//                                             color="danger"
//                                         /> : ""}
//                                     </GridItem>
//                                     <GridItem xs={12} sm={12} md={6}>
//                                         <CustomInput
//                                             labelText="* Visitor Name(max. 20 characters)"
//                                             id="Name"
//                                             formControlProps={{
//                                                 fullWidth: true
//                                             }}
//                                             inputProps={{
//                                                 onChange: (event) => {
//                                                     this.setState({ visitorname: event.target.value })
//                                                     if (Settings.PersonName(event.target.value).status) {
//                                                         this.setState({ flagError: false, erroertext: "" })
//                                                     } else {
//                                                         this.setState({ flagError: true, erroertext: Settings.PersonName(event.target.value).errmessgae, inputname: Settings.PersonName(event.target.value).inputname })
//                                                     }
//                                                 },
//                                                 value: this.state.visitorname,
//                                                 type: "text",
//                                                 error: (this.state.flagError && this.state.inputname === "PersonName") || (this.state.requirederror && this.state.visitorname === "") ? true : false
//                                             }}
//                                         />
//                                         {this.state.requirederror && !this.state.visitorname ? <SnackbarContent
//                                             icon={InfoIcon}
//                                             message={this.state.erroertext}
//                                             // close
//                                             color="danger"
//                                         /> : ""}
//                                         {this.state.flagError && this.state.inputname === "PersonName" ? <SnackbarContent
//                                             icon={InfoIcon}
//                                             message={this.state.erroertext}
//                                             // close
//                                             color="danger"
//                                         /> : ""}
//                                     </GridItem>

//                                 </GridContainer>

//                                 <GridContainer>
//                                     <GridItem xs={12} sm={12} md={6}>
//                                         <CustomInput
//                                             labelText="* Mobile Number(10 digits)"
//                                             id="Mobile"
//                                             formControlProps={{
//                                                 fullWidth: true
//                                             }}
//                                             inputProps={{
//                                                 onChange: (event) => {
//                                                     this.setState({ mobile: event.target.value })
//                                                     if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
//                                                         this.setState({ flagError: false, erroertext: "" })
//                                                     } else {
//                                                         this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: "Mobile" })
//                                                     }
//                                                 },
//                                                 onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
//                                                 type: "number",
//                                                 value: this.state.mobile,
//                                                 error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
//                                             }}
//                                         />
//                                         {this.state.requirederror && !this.state.mobile ? <SnackbarContent
//                                             icon={InfoIcon}
//                                             message={this.state.erroertext}
//                                             // close
//                                             color="danger"
//                                         /> : ""}
//                                         {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
//                                             icon={InfoIcon}
//                                             message={this.state.erroertext}
//                                             // close
//                                             color="danger"
//                                         /> : ""}
//                                     </GridItem>
//                                     <GridItem xs={12} sm={12} md={6}>
//                                         <CustomInput
//                                             labelText="* To meet(max. 25 characters)"
//                                             id="tomeet"
//                                             formControlProps={{
//                                                 fullWidth: true
//                                             }}
//                                             inputProps={{
//                                                 onChange: (event) => {
//                                                     this.setState({ tomeet: event.target.value })
//                                                     if (Settings.Comments(event.target.value).status) {
//                                                         this.setState({ flagError: false, erroertext: "" })
//                                                     } else {
//                                                         this.setState({ flagError: true, erroertext: Settings.Comments(event.target.value).errmessgae, inputname: "Comments" })
//                                                     }
//                                                 },
//                                                 type: "text",
//                                                 value: this.state.tomeet,
//                                                 error: (this.state.flagError && this.state.inputname === "Comments") || (this.state.requirederror && this.state.tomeet === "") ? true : false
//                                             }}
//                                         />
//                                         {this.state.requirederror && !this.state.tomeet ? <SnackbarContent
//                                             icon={InfoIcon}
//                                             message={this.state.erroertext}
//                                             // close
//                                             color="danger"
//                                         /> : ""}
//                                         {this.state.flagError && this.state.inputname === "Comments" ? <SnackbarContent
//                                             icon={InfoIcon}
//                                             message={this.state.erroertext}
//                                             // close
//                                             color="danger"
//                                         /> : ""}
//                                     </GridItem>

//                                 </GridContainer>


//                                 <GridContainer>


//                                 </GridContainer>

//                             </CardBody>
//                             <CardFooter>
//                                 <GridContainer>

//                                     <GridItem xs={12} sm={12} md={5}>
//                                         {this.state.visitorname !== "" && this.state.mobile !== "" && this.state.vehicle !== "" && this.state.tomeet !== "" ?
//                                             <Button color="primary" onClick={this.add}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
//                                             <Button onClick={() => {
//                                                 if (Settings.requireerrors([this.state.vehicle, this.state.flatno, this.state.mobile, this.state.fullname]).status === true) {
//                                                     this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.hostname, this.state.visitorname, this.state.companyname]).errmessgae })
//                                                     setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
//                                                 }
//                                             }}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button>
//                                         }
//                                     </GridItem>

//                                     <GridItem xs={12} sm={12} md={4}>
//                                     </GridItem>
//                                 </GridContainer>
//                             </CardFooter>
//                         </Card>
//                     </GridItem>

//                 </GridContainer>

//             </div>
//         );
//     }
// }
// export default withRouter((withStyles(styles)(Add)));

import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { withRouter } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Alert from '../../lprfolders/GlobalConstant/Alert'
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    // KeyboardDatePicker,
    KeyboardTimePicker,
} from '@material-ui/pickers';
// import 'date-fns';
// import FindReplaceIcon from '@material-ui/icons/FindReplace';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Radio from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, RadioGroup, Select } from '@material-ui/core';
// import { ModeComment } from '@material-ui/icons';
const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },

    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class Add extends Component {
    constructor(props) {
        super(props);

        this.token = localStorage.getItem('token');
        this.state = {
            flagError: false,
            netWorkError: false,
            erroertext: "",
            inputname: "",
            tc: false,
            bc: false,
            flatno: "",
            fullname: "",
            mobile: Math.floor(100000000 + Math.random() * 900000000).toString(),
            vehicle: props.lpr,
            lprId: props.lprId,
            companyname: "",
            hostname: "",
            hostnameerror: false,
            tomeet: "",
            comments: "",
            statusid: 2,
            statuschecked: true,
            statuschecked1: false,
            date: props.entryDate,
            selectedDate: "",
            online: window.navigator.onLine,
            visitorid: "",
            visitorName: "",
            gilad: true,
            jason: false,
            antoine: false,
            visitorCategory: "Service Counter",
            serviceTypeArray: [{
                serviceid: "1",
                serviceType: "Service"
            }, {
                serviceid: "2",
                serviceType: "Body Shop"
            }],
            serviceTypeName: [],
            advisorName: "",
            advisorNamesArray: [
                "Liam",
                "Olivia",
                "Noah",
                "Emma",
                "Oliver",
                "Ava",
                "Isabella",
                "Sophia",
                "Mia",
                "Charlotte",
                "Amelia",
                "Harper",
                "Evelyn",
                "Luna",
                "Abigail",
                "Emily",
                "Ella",
                "Elizabeth",
                "Camila",
                "Avery",
                "Sofia",
                "Scarlett",
                "Victoria",
                "Madison",
                "Chloe",
                "Grace",
                "Penelope",
                "Layla",
                "Riley",
                "Zoey",
                "Nora",
                "Lily",
                "Eleanor",
                "Hannah",
                "Lillian",
                "Addison",
                "Aubrey",
                "Ellie",
                "Stella",
                "Natalie",
                "Zoe"
            ]


        }
    }


    componentDidMount() {
        var date = this.state.date;
        var newdate = date.split("-").reverse().join("-");
        this.setState({
            selectedDate: newdate + " " + this.props.entryTime
        })

        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
    }

    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }
    search = () => {

        this.setState({ loader: true })
        let api = HOST + "/visitor/search/" + this.state.vehicle
        axios.get(api, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                this.setState({
                    mobile: response.data[0].mobile,
                    vehicle: response.data[0].vehicle,
                    visitorname: response.data[0].visitor,
                    visitorid: response.data[0].visitorid,

                    tomeet: response.data[0].tomeet,
                });
                this.setState({ loader: false })
            })
            .catch(err => {
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })
    }
    add = () => {
        let count = 1
        let api = HOST + "/visitor/add"

        let body =
        {
            visitor: this.state.visitorName,
            mobile: this.state.mobile,
            // visitorid: this.state.visitorid,
            lprid: this.state.lprId,
            vehicle: this.state.vehicle,
            tomeet: this.state.advisorName,
            statusid: this.state.statusid,
            intime: this.state.selectedDate,
        }
        if (this.state.vehicle === "") {
            count--
        }
        if (this.state.vehicle) {
            if (Settings.Vehicle(this.state.vehicle).status) {
                count--
            }
            else {
                count++
                this.setState({ flagError: true, erroertext: Settings.Vehicle(this.state.vehicle).errmessgae, inputname: Settings.Vehicle(this.state.vehicle).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (Settings.mobilenumber(this.state.mobile).status) {
            if (Settings.Comments(this.state.tomeet).status) {


            } else {
                count++
                this.setState({ flagError: true, erroertext: "Tomeet length must be minium 3 maximum 25", inputname: Settings.Comments(this.state.tomeet).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }

        }


        if (count === 0) {
            if (this.state.online) {

                this.setState({ loader: true })
                axios.post(api, body, {
                    headers: {
                        "authToken": this.token,
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json'
                    }
                }).then(res => {
                    if (res.status === 201) {
                        this.setState({ loader: false })
                        this.showNotification("tc")

                        setTimeout(() => { this.props.history.push('/dashboard') }, 2000);
                        window.location.reload()

                    }
                }).catch(err => {

                    if (err.message === "Network Error") {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                    if (err.response) {
                        if (err.response.data.statusid === 409) {
                            this.setState({ flagError: true, loader: false, erroertext: "Visitor already Registered", inputname: "Vehicle" })
                            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                        }
                        if (err.response.status === 502) {
                            this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                            setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                        }
                    }
                })
            }
            else {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
        }

    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    onChangestatus = (event) => {
        this.setState({ statusid: event.target.value, statuschecked: !this.state.statuschecked, statuschecked1: !this.state.statuschecked1 })
    }
    handleRadioChange2 = (event, value) => {
        this.setState({
            visitorCategory: event.target.value,
        })
        if (event.target.value !== "Service Counter") {
            document.getElementById("servicetype").style.display = "none";
            document.getElementById("advisortype").style.display = "none";

            this.setState({
                visitorName: event.target.value,
                advisorName: ""

            })
        } else {
            document.getElementById("servicetype").style.display = "block";
            document.getElementById("advisortype").style.display = "block";

            this.setState({
                visitorName: "",
                serviceTypeName: []

            })
        }

    };
    // handleChange = (event) => {
    //     this.setState({
    //         ...state, [event.target.name]: event.target.checked
    //     })
    // };
    CheckboxhandleChange = (event, value) => {


        if (value === true) {
            this.state.serviceTypeName.push(event.target.id)
        } else {
            this.state.serviceTypeName.find((cameraid, index) => {
                if (cameraid === event.target.id) {
                    this.state.serviceTypeName.splice(index, 1)
                }
            })
        }

        this.setState({ serviceTypeName: this.state.serviceTypeName })
        // if (this.state.serviceTypeName.length === 0) {
        //     this.setState({ visitorName: "Service Counter" })

        // }
        this.setState({ visitorName: this.state.serviceTypeName.toString() })
    };
    handleChange = (event, values) => {
        if (values) {
            this.setState({
                advisorName: values.props.value
            });
        }
    }
    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>

                <GridContainer>
                    {this.state.tc ? <Snackbar
                        place="tc"
                        color="success"
                        icon={ThumbUpIcon}
                        message={" Visitor " + this.state.erroertext}
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    /> : ""}
                    <GridItem xs={12} sm={12} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Vehicle Category")}</h4>
                                {/* <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please fill in all required feilds")}</p> */}
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText=" *Vehicle Number(max. 10 characters)"
                                            id="Vehicle Number"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ vehicle: event.target.value })
                                                    if (Settings.Vehicle(event.target.value).status) {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.Vehicle(event.target.value).errmessgae, inputname: "Vehicle" })
                                                    }
                                                },
                                                onInput: (e) => e.target.value = ("" + e.target.value).toUpperCase().toString().slice(0, 10),
                                                type: "text",
                                                value: this.props.lpr,
                                                disabled: true,
                                                error: (this.state.flagError && this.state.inputname === "Vehicle") ? true : false
                                            }}
                                        />
                                        {this.state.flagError && this.state.inputname === "Vehicle" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControl style={{ marginTop: 30 }}>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-error-radios"
                                                name="Reseller"
                                                defaultValue={this.state.visitorCategory}

                                                onChange={this.handleRadioChange2}
                                            >
                                                <FormControlLabel value="Service Counter" control={<Radio color="success" />}
                                                    label="Service Counter" />
                                                <FormControlLabel value="Private" control={<Radio color="success" />}
                                                    label="Private" />
                                                <FormControlLabel value="Used" control={<Radio color="success" />}
                                                    label="Used" />
                                                <FormControlLabel value="Management" control={<Radio color="success" />}
                                                    label="Management" />
                                                <FormControlLabel value="Demo" control={<Radio color="success" />}
                                                    label="Demo" />

                                            </RadioGroup>
                                        </FormControl>

                                    </GridItem>


                                </GridContainer>



                                <GridContainer id="servicetype" style={{ marginTop: 30 }}>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <InputLabel id="demo-simple-select-helper-label" style={{ marginTop: 30 }} >* Select Service Counter</InputLabel>
                                        {this.state.serviceTypeArray.map((entryCriteria, index) => (
                                            <FormControlLabel style={{ marginTop: 5 }} control={<Checkbox onChange={this.CheckboxhandleChange} id={entryCriteria.serviceType}
                                                checked={this.state.serviceTypeName.indexOf(entryCriteria.serviceType) > -1}
                                                style={{ color: "green" }} />} label={entryCriteria.serviceType} >
                                            </FormControlLabel>

                                        ))}
                                        {this.state.requirederror && this.state.visitorName === "" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>

                                </GridContainer>
                                <GridContainer id="advisortype" style={{ marginTop: 30 }}>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <FormControl color="primary" style={{ width: "100%", height: "60%", marginTop: 28 }}>
                                            <InputLabel id="demo-simple-select-helper-label">* Advisor Name</InputLabel>

                                            <Select

                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.advisorName}
                                                onChange={this.handleChange}
                                                color='green'
                                                style={{ fontSize: 15 }}                            >
                                                {this.state.advisorNamesArray.map((advisorName, index) => (
                                                    <MenuItem value={advisorName}>{advisorName}</MenuItem>

                                                ))}
                                            </Select>
                                            {this.state.requirederror && !this.state.advisorName ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </FormControl>
                                    </GridItem>

                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={5}>
                                        {this.state.visitorCategory !== "Service Counter" ? this.state.visitorName !== "" && this.state.mobile !== "" && this.state.vehicle !== "" ?
                                            <Button color="primary" onClick={this.add}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                            <Button onClick={() => {
                                                if (Settings.requireerrors([this.state.vehicle, this.state.mobile, this.state.visitorName]).status === true) {
                                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.hostname, this.state.visitorName, this.state.companyname]).errmessgae })
                                                    setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                }
                                            }}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                            :
                                            this.state.visitorName !== "" && this.state.mobile !== "" && this.state.vehicle !== "" && this.state.advisorName !== "" ?
                                                <Button color="primary" onClick={this.add}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                                <Button onClick={() => {
                                                    if (Settings.requireerrors([this.state.vehicle, this.state.mobile, this.state.visitorName, this.state.advisorName]).status === true) {
                                                        this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.hostname, this.state.visitorName, this.state.companyname]).errmessgae })
                                                        setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                    }
                                                }}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                        }
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>

            </div>
        );
    }
}
export default withRouter((withStyles(styles)(Add)));


import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { withRouter } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import Settings from '../../lprfolders/GlobalConstant/Settings';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Alert from '../../lprfolders/GlobalConstant/Alert'
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#4caf50',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#4caf50',
            //   borderBottomWidth:0
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#4caf50',
                // borderBottomWidth:0
            },
            '&:hover fieldset': {
                borderColor: '#4caf50',
                // borderBottomWidth:0
            },
            '&.Mui-focused fieldset': {
                borderColor: '#4caf50',
                // borderBottomWidth:0
            },
        },
    },
})(TextField);
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    root: {
        flexGrow: 1,
        display: "grid",
        // height: "100vh"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class Add extends Component {
    constructor(props) {
        super(props);
        this.companyname = localStorage.getItem('company')
        this.token = localStorage.getItem('token');
        this.roletype = window.sessionStorage.getItem('role')
        this.companyid = localStorage.getItem('companyid')
        this.state = {
            companyname: '',
            mobile: '',
            email: '',
            website: '',
            companyurl: '',
            ctype: '',
            results: [],
            flagError3: false,
            loader: false,
            addButton: true,
            facebookurl: "",
            password: "",
            firstname: "",
            lastname: "",
            instagramurl: "",
            imageURL: "",
            twitterurl: "",
            zomatourl: "",
            linkedinurl: "",
            google: "",
            sms: "",
            ratingurl: "",
            selectedFile: '',
            companytype: this.roletype === "IsAdmin" ? "" : "00",
            companytypesarray: [],
            roletypesarray: [],
            roletype: "",
            success: false,
            requirederror: false,
            flagError: false,
            erroertext: "",
            inputname: "",
            tc: false,
            bc: false,
            imageuploadbutton: false,
            netWorkError: false,
            online: window.navigator.onLine,
            tenantName: "",
            firstname: "",
            lastname: "",
            mobile: "",
            pwd: "",
            email: "",
            bikeCapacity: "0",
            carCapacity: "0",


        }
    }
    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }
    componentDidMount() {
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange)

    }

    add = () => {

        let api = HOST + "/tenant/add"
        let body = {
            tenant: this.state.tenantName,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            pwd: this.state.pwd,
            mobile: this.state.mobile,
            email: this.state.email,
            bikecapacity: this.state.bikeCapacity,
            carcapacity: this.state.carCapacity
        }
        // let count = 1
        // if (this.state.email === "") {
        //     count--
        // }
        // if (this.state.email) {
        //     if (Settings.Email(this.state.email).status) {
        //         count--
        //     } else {
        //         count++
        //         this.setState({ flagError: true, erroertext: Settings.Email(this.state.email).errmessgae, inputname: Settings.Email(this.state.email).inputname })
        //         setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        //     }
        // }
        // if (Settings.FirstName(this.state.firstname).status) {
        //     if (Settings.LastName(this.state.lastname).status) {
        //         if (Settings.mobilenumber(this.state.mobile).status) {
        //         } else {
        //             count++
        //             this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.mobile).errmessgae, inputname: Settings.mobilenumber(this.state.mobile).inputname })
        //             setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        //         }
        //     } else {
        //         count++
        //         this.setState({ flagError: true, erroertext: Settings.LastName(this.state.lastname).errmessgae, inputname: Settings.LastName(this.state.lastname).inputname })
        //         setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        //     }
        // }
        // else {
        //     count++
        //     this.setState({ flagError: true, erroertext: Settings.FirstName(this.state.firstname).errmessgae, inputname: Settings.FirstName(this.state.firstname).inputname })
        //     setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        // }
        // if (count === 0) {
        if (this.state.online) {

            this.setState({ loader: true })
            axios.post(api, body, {
                headers: {
                    "authToken": this.token,
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.status === 201) {
                    this.setState({ loader: false })
                    this.showNotification("tc")
                    setTimeout(() => { this.props.history.push('/tenant') }, 2000);
                }
            }).catch(err => {
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response.data.statusid === 406) {
                    this.setState({ netWorkError: true, loader: false, erroertext: err.response.data.message })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.data.statusid === 409) {
                        this.setState({ flagError: true, loader: false, erroertext: "Mobile Number already existed.", inputname: "Mobile" })
                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                    }
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })
        }
        else {
            this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
            setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
        }
        // }

    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    render() {
        const { classes } = this.props
        return (
            <div >
                {this.state.tc ? <Snackbar
                    place="tc"
                    color="success"
                    icon={ThumbUpIcon}
                    message={" Tenant " + this.state.erroertext}
                    open={this.state.tc}
                    closeNotification={() => this.setState({ tc: false })}
                    close
                /> : ""}
                <div className={classes.root}>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={2}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Add Tenant")}</h4>
                                    <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please fill in required fileds")}</p>
                                </CardHeader>
                                <CardBody>
                                    {this.state.netWorkError ? <SnackbarContent
                                        icon={InfoIcon}
                                        message={this.state.erroertext}
                                        // close
                                        color="danger"
                                    /> : ""}
                                    {this.state.loader ? <LoaderComponent /> : ""}
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="* Tenant Name"
                                                id="tenant"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ tenantName: event.target.value })

                                                        if (Settings.FirstName(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.CompnayName(event.target.value).errmessgae, inputname: Settings.CompnayName(event.target.value).inputname })
                                                        }
                                                    },
                                                    type: "text",
                                                    error: (this.state.tenantName === "" && this.state.requirederror) || (this.state.flagError && this.state.inputname) === "CompanyName" ? true : false
                                                }}
                                            />
                                            {(this.state.requirederror && !this.state.companyname) ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "CompanyName" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>

                                        
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="* First Name(min. 3 & max. 8 characters)"
                                                id="First Name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ firstname: event.target.value })

                                                        if (Settings.FirstName(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.FirstName(event.target.value).errmessgae, inputname: Settings.FirstName(event.target.value).inputname })
                                                        }
                                                    },
                                                    type: "text",
                                                    value: this.state.firstname,
                                                    error: (this.state.firstname === "" && this.state.requirederror) || (this.state.flagError && this.state.inputname) === "FirstName" ? true : false
                                                }}
                                            />
                                            {(this.state.requirederror && !this.state.firstname) ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "FirstName" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="* Last Name(min. 3 & max. 8 characters)"
                                                id="Last Name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ lastname: event.target.value })
                                                        if (Settings.LastName(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.LastName(event.target.value).errmessgae, inputname: Settings.LastName(event.target.value).inputname })
                                                        }
                                                    },
                                                    type: "text",
                                                    value: this.state.lastname,
                                                    error: (this.state.lastname === "" && this.state.requirederror) || (this.state.flagError && this.state.inputname) === "LastName" ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.lastname ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "LastName" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="* Mobile Number(10 digits)"
                                                id="first-name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ mobile: event.target.value })
                                                        if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: Settings.mobilenumber(event.target.value).inputname })
                                                        }
                                                    },
                                                    onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                    type: "number",
                                                    error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.mobile ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} style={{ marginBottom: 10 }} >
                                            <CustomInput
                                                labelText="* Password(min.3 & max. 20)"
                                                id="Password"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}

                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ pwd: event.target.value })
                                                        if (Settings.Password(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.Password(event.target.value).errmessgae, inputname: Settings.Password(event.target.value).inputname })
                                                        }
                                                    },
                                                    type: "password",

                                                    error: (this.state.pwd === "" && this.state.requirederror) ? true : false
                                                }}

                                            />
                                            {this.state.requirederror && !this.state.pwd ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Email(Ex. info@gaido.in)"
                                                id="Email"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ email: event.target.value })
                                                        if (Settings.Email(event.target.value).status || event.target.value === "") {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.Email(event.target.value).errmessgae, inputname: Settings.Email(event.target.value).inputname })
                                                        }


                                                    },
                                                    type: "text",
                                                    error: this.state.flagError && this.state.inputname === "Email" ? true : false
                                                }}
                                            />
                                            {this.state.flagError && this.state.inputname === "Email" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>

                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} >
                                            <label >Parking Allotment</label>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} style={{ marginTop: -20 }} >

                                            <CustomInput
                                                labelText="* Cars (Includes all 3W & 4W) "
                                                id="tenant4W-capacity"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ carCapacity: event.target.value })
                                                    },
                                                    type: "number",
                                                    value:this.state.carCapacity,
                                                    onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5),
                                                    error: this.state.flagError && this.state.inputname === "4WCapacity" ? true : false
                                                }}
                                            />
                                            {this.state.flagError && this.state.inputname === "4WCapacity" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.requirederror && !this.state.carCapacity ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} style={{ marginTop: -20 }}>
                                            <CustomInput
                                                labelText="* Bikes (includes all 2W)"
                                                id="tenant2W-capacity"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ bikeCapacity: event.target.value })
                                                    },
                                                    type: "number",
                                                    value:this.state.bikeCapacity,
                                                    onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5),
                                                    error: this.state.flagError && this.state.inputname === "2WCapacity" ? true : false
                                                }}
                                            />
                                            {this.state.flagError && this.state.inputname === "2WCapacity" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.requirederror && !this.state.bikeCapacity ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={5}>
                                            {this.state.tenantName !== "" && this.state.mobile !== "" && this.state.pwd !== "" && this.state.carCapacity !== "" && this.state.bikeCapacity !== "" ?
                                                <Button color="primary" onClick={this.add}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                                <Button onClick={() => {
                                                    if (Settings.requireerrors([this.state.roletype, this.state.companytype, this.state.mobile, this.state.companytype, this.state.carCapacity, this.state.bikeCapacity]).status === true) {
                                                        this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.companyname, this.state.mobile, this.state.companytype, this.state.carCapacity, this.state.bikeCapacity]).errmessgae })
                                                        setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                    }
                                                }}> <AddBoxIcon />Add<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                            }
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={1}>
                                            <Button color="white" onClick={() => {
                                                this.props.history.push('/tenant')
                                            }}><CancelPresentationIcon />Cancel</Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                        </GridItem>
                                    </GridContainer>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}
export default withRouter((withStyles(styles)(Add)));


import React from 'react'
import { Redirect, Route } from 'react-router-dom'
const PrivateRoute = ({ component: Component, isAuth, role, ...rest }) => {
  let isLoggedIn;
  let checkrole;
  if (window.sessionStorage.getItem('token')) {
   
    if ((role[0].role1 === "All")) {
      checkrole = true
    }
    else if ((role[0].role1 === window.sessionStorage.getItem('role')) || (role[0].role2 === window.sessionStorage.getItem('role')) || (role[0].role3 === window.sessionStorage.getItem('role'))) {
       checkrole = true
    }
    else {
      checkrole = false
    }
    isLoggedIn = true
  } else {
    isLoggedIn = false
  }


  return (

    <Route
      {...rest}
      
      render={props =>
        isLoggedIn && isAuth && checkrole ? (
          <Component {...props} key={localStorage.getItem('token') + 1} />
        ) : (
          <Redirect to={{ pathname: window.sessionStorage.getItem("role") !== "" ? "/auth" : '/', state: { from: props.location } }} />
        )
      }
      
    />
  )
}

export default PrivateRoute